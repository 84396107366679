import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import EditarForm from "./EditarForm";


class Perfil extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = ({verPass: false});
    }

    cambiarPass = () => {
        this.setState({verPass: true});
    };


    componentWillMount() {
        const { getMe } = this.props;
        getMe();
    }

    editar = (data) => {
        const { updateMe } = this.props;
        updateMe(data);
    };

    render() {
        const { loader} = this.props;
        const { verPass } = this.state;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <EditarForm onSubmit={this.editar} cambiarPass={this.cambiarPass} verPass={verPass} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Perfil;
