import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-responsive-modal";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import LoadMask from "Utils/LoadMask";
import CrearForm from "./CrearForm";
import { NotificationManager } from "react-notifications";
import CrearCliente from "../../Clientes/Crear/CrearForm";


class CrearPresupuesto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { open: false};
    }


    openModal = () => {
        this.setState({open: true});
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    crearCliente = (data) => {
        const { crearCliente } = this.props;
        crearCliente(data, "CrearPresupuestoForm");
        this.setState({ open: false });
    };

    render() {
        const { loader, crear } = this.props;
        const { open } = this.state;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <div className="header-1 azul mb-2">PRESUPUESTO</div>
                    <Tabs
                        activeKey={"1"}
                        onChange={() => {NotificationManager.error('Por favor complete la información del proyecto antes de continuar', 'ERROR', 3000);}}
                        renderTabBar={()=><ScrollableInkTabBar />}
                        renderTabContent={()=><TabContent />}
                    >
                        <TabPane tab="DATOS BASE" key="1" >
                            <LoadMask loading={loader} light>
                                <CrearForm borrador onSubmit={crear} titulo={"Datos Base"} crearCliente={this.openModal} />
                            </LoadMask>
                        </TabPane>
                        <TabPane tab="Renglones" key="2" />
                        <TabPane tab="Costos" key="3" />
                    </Tabs>
                    <Modal open={open} onClose={this.closeModal} center showCloseIcon={false} classNames={{modal: "rr-modal"}}>
                        <CrearCliente onSubmit={this.crearCliente} titulo="Crear Cliente" regreso={this.closeModal} />
                    </Modal>
                </div>
            </div>
        );
    }
}

export default CrearPresupuesto;
