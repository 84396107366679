import { handleActions } from 'redux-actions';
import moment from "moment";
import uuidv1 from "uuid/v1";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api, updateExpiracion } from "api";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";


// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'ALQUILER_LOADER';
const DATA = 'ALQUILER_DATA';
const ITEM = 'ALQUILER_ITEM';
const PAGE = 'ALQUILER_PAGE';
const ORDERING = 'ALQUILER_ORDERING';
const SEARCH = 'ALQUILER_SEARCH';
const UUID = 'ALQUILER_UUID';

export const constants = {
};

const endpoint = "alquileres";
export const crearForm = "CrearAlquilerForm";
export const editarForm = "EditarAlquilerForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { alquiler } = getStore();
    const params = { page };
    params.ordering = alquiler.ordering;
    params.search = alquiler.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().alquiler.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        const form = _.cloneDeep(response);
        form.detalle.forEach((item) => {
            item.maquinaria = { ...item.maquinaria, id: item.maquinaria_id, nombre: item.nombre_maquinaria };
        });
        form.cliente = {...form.cliente, value: form.cliente.id, label: form.cliente.nombre};
        dispatch(initializeForm(editarForm, form));
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));
    data.cliente_id = data.cliente.id;
    data.detalle.forEach((detalle) => {
        detalle.maquinaria_id = detalle.maquinaria.id;
    });
    api.post(endpoint, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/alquileres"));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    data.cliente_id = data.cliente.id;
    data.detalle.forEach((detalle) => {
        detalle.maquinaria_id = detalle.maquinaria.id;
    });
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/alquileres"));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    Swal.fire({
        title: '¿Finalizar?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, finalizar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/finalizar`).then(() => {
                dispatch(push("/alquileres"));
                NotificationManager.success('Alquiler finalizadoe', 'Éxito', 3000);
            }).catch(() => {
                NotificationManager.error('Error en la transacción', 'Éxito', 3000);
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    });
};

export const activarAlquiler = (id, data) => (dispatch, getStore) => {
    const { item } = getStore().alquiler;
    let pasa = true;
    item.detalle.forEach((detalle) => {
        if (!detalle.estado)
            pasa = false;
    });
    if (pasa) {
        dispatch(setLoader(true));

        api.put(`${endpoint}/${id}/activar`, {responsable_id: data.username.id}).then((response) => {
            dispatch(setItem(response));
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        }).catch((error) => {
            if (error.detail)
                NotificationManager.error(error.detail, 'ERROR', 0);
            else
                NotificationManager.error('Error en la transacción', 'ERROR', 0);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else
        Swal.fire({
            title: 'Maquinaria en uso',
            text: '¡Existe otro alquiler que tiene en uso una maquinaria de la cotización! ¿Continuar?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, continuar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                dispatch(setLoader(true));
                api.put(`${endpoint}/${id}/activar`, {responsable_id: data.username.id}).then((response) => {
                    dispatch(setItem(response));
                    NotificationManager.success('Registro actualizado', 'Éxito', 3000);
                }).catch((error) => {
                    if (error.detail)
                        NotificationManager.error(error.detail, 'ERROR', 0);
                    else
                        NotificationManager.error('Error en la transacción', 'ERROR', 0);
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        });

};

export const subirGastos = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}/gastos`, data).then((response) => {
        dispatch(setItem(response));
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(initializeForm('GastoAlquilerForm', {
            fecha: moment(new Date()).format("YYYY-MM-DD")
        }));
    }).catch((error) => {
        if (error.detail)
            NotificationManager.error(error.detail, 'ERROR', 0);
        else
            NotificationManager.error('Error en la transacción', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().alquiler.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    activarAlquiler,
    searchChange,
    sortChange,
    subirGastos,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {
        detalle: [],
        balance: 0,
    },
    page: 1,
    ordering: '',
    search: '',
    uuid: '',
};

export default handleActions(reducers, initialState);
