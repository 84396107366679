import React, { Component } from 'react';
import { Async } from "react-select";
import { RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";
import { api } from "api";
import NumberFormat from 'react-number-format';
import { proveedores } from "./Costos";


const getProveedores = (id) => {
    return (search) => {
        return api.get("costos", {search, material_id: id}).then((data) => {
            data.results.forEach((item) => {
                if (proveedores[id] === undefined) {
                    proveedores[id] = [{...item}];
                } else {
                    if (!_.find(proveedores[id], { id: item.id })) {
                        proveedores[id].push({...item});
                    }
                }
            });
            return {options: proveedores[id]};
        }).catch((e) => {
            console.log(e);
        });
    }
};


class Costos extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, index: null };
    }

    render() {
        const { materiales, setProveedor, item, total_mano_obra, cambioValor, url } = this.props;
        const disabled = url.includes("proyecto");
        let total_materiales = 0;
        return (
            <table className="d-none d-lg-table">
                <thead>
                <tr>
                    <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul" >MATERIAL</th>
                    <th style={{width: "10%"}} className="p-1 paragraph bg-celeste azul" >UNIDAD</th>
                    <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul" >PROVEEDOR</th>
                    <th style={{width: "15%"}} className="p-1 paragraph bg-celeste azul text-right" >PRECIO UNITARIO</th>
                    <th style={{width: "15%"}} className="p-1 paragraph bg-celeste azul text-right" >CANTIDAD TOTAL</th>
                    <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul text-right" >SUB TOTAL</th>
                </tr>
                </thead>
                <tbody>
                {materiales.map((material, index) => {
                    if (material.proveedor && material.cantidad)
                        total_materiales += (parseFloat(material.proveedor.costo) * material.cantidad);
                    return (
                        <tr key={material.id} className={`renglon-${index%2}`}>
                            <td className="p-1 paragraph gris">
                                {material.nombre}
                            </td>
                            <td className="p-1 paragraph gris">
                                {material.unidad}
                            </td>
                            <td className="p-1 paragraph gris">
                                <Async
                                    clearable={false}
                                    disabled={material.disabled}
                                    value={material.proveedor}
                                    className="react-select-container"
                                    onChange={(e) => { setProveedor(material, e, url.includes("proyecto")) }}
                                    cache={false}
                                    searchPromptText="Escribe para buscar"
                                    valueKey="id"
                                    labelKey="nombre"
                                    loadOptions={getProveedores(material.id)}
                                />
                            </td>
                            <td className="p-1 paragraph gris text-right">
                                <RenderCurrency value={material.proveedor ? material.proveedor.costo : undefined} />
                            </td>
                            <td className="p-1 paragraph gris text-right">
                                <RenderNumber value={material.cantidad ? material.cantidad : 0} decimalScale={4} />
                            </td>
                            <td className="p-1 paragraph gris text-right">
                                <RenderCurrency value={
                                    (material.proveedor && material.cantidad) ?
                                        parseFloat(material.proveedor.costo) * material.cantidad : 0
                                } />
                            </td>
                        </tr>
                    )
                })}
                <tr className={`renglon-${materiales.length%2}`}><td className="p-3" colSpan={6} /></tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">MANO DE OBRA POR RENGLONES</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency value={total_mano_obra} className={"azul pr-3"} />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">MANO DE OBRA GLOBAL</div></td>
                    <td className="p-1 paragraph gris">
                        <NumberFormat
                            disabled={disabled}
                            className="form-control text-right paragraph"
                            decimalScale={2}
                            fixedDecimalScale
                            value={item.mano_obra}
                            thousandSeparator
                            prefix="Q "
                            onValueChange={(values) => { cambioValor('mano_obra', values.value) }}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL DE MATERIALES</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency value={total_materiales} className={"azul pr-3"} />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">COSTO TOTAL</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency
                            value={parseFloat(item.mano_obra ? item.mano_obra : "0") + parseFloat(total_mano_obra) + total_materiales}
                            className={"azul pr-3"}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">PORCENTAJE DE GANANCIA</div></td>
                    <td className="p-1 paragraph gris">
                        <NumberFormat
                            disabled={disabled}
                            className="form-control text-right paragraph"
                            decimalScale={2}
                            fixedDecimalScale
                            value={item.ganancia}
                            thousandSeparator
                            prefix="%"
                            onValueChange={(values) => { cambioValor('ganancia', values.value) }}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL DEL PROYECTO</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency
                            value={
                                (parseFloat(item.ganancia ? item.ganancia : "0") / 100 + 1) * (parseFloat(item.mano_obra ? item.mano_obra : "0") + parseFloat(total_mano_obra) + total_materiales)
                            }
                            className={"azul pr-3"}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}><td className="p-3" colSpan={6} /></tr>
                <tr className={`renglon-${(materiales.length)%2}`}><td className="p-3" colSpan={6} /></tr>
                </tbody>
            </table>
        );
    }
}

export default Costos;
