import React from 'react';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderPorcentaje } from "../../Utils/renderField/renderTableField";


export const materiales = (row) => {
    const options = _.cloneDeep(tableOptions);
    return (
        <div className="inner-table">
            <BootstrapTable
                data={row.detalle}
                remote
                striped
                bordered={false}
                hover
                options={options}
            >
                <TableHeaderColumn
                    dataField="material"
                    dataSort
                    dataFormat={(cell) => {
                        return `${cell.nombre} ${cell.unidad}`;
                    }}
                >
                    Material
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                    dataSort
                    dataAlign="right"
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="porcentaje_imprevisto"
                    dataFormat={(cell) => <RenderPorcentaje value={cell} decimalScale={2} />}
                    dataSort
                    dataAlign="right"
                >
                    Porcentaje de imprevisto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    isKey
                    width={"0%"}
                />
            </BootstrapTable>
        </div>
    );
};
