import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask";
import { materiales } from "./DetalleBaja";
import { RenderDate } from "Utils/renderField/renderReadField";

class ListadoPlantillas extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    render() {
        const { loader, item } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <LoadMask loading={loader} light>
                    <div className="">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="header-1 azul">BAJAS</div>
                        </div>
                        <BootstrapTable
                            data={item.bajas}
                            striped
                            bordered={false}
                            expandableRow={() => true}
                            expandComponent={materiales}
                            hover
                            options={options}
                        >
                            <TableHeaderColumn
                                dataField="usuario"
                                dataSort
                            >
                                Responsable
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataFormat={(cell) => <RenderDate value={cell} />}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="motivo"
                                dataSort
                            >
                                Motivo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                width="0%"
                            />
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default ListadoPlantillas;
