import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderAsync, renderAsyncCreatable, renderNumber } from "Utils/renderField/renderField";
import { getMateriales, getResumenes } from "utility/variables";


const Form = (props) => {
    const { handleSubmit, regreso } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">Agregar Material</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" style={{minWidth: "275px"}}>
                            <label htmlFor="material">Material*</label>
                            <Field
                                name="material"
                                clearable={false}
                                component={renderAsync}
                                loadOptions={getMateriales}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" style={{minWidth: "275px"}}>
                            <label htmlFor="cantidad">Cantidad*</label>
                            <Field
                                name="cantidad"
                                decimalScale={4}
                                component={renderNumber}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="resumen">Destinado a*</label>
                            <Field
                                name="resumen"
                                clearable={false}
                                component={renderAsyncCreatable}
                                loadOptions={getResumenes}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center flex-row mt-3 ml-3 mr-3">
                <button type="button" onClick={regreso} className="btn btn-secondary m-1 align-self-center">Cancelar</button>
                <button type="submit" className="btn btn-primary m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'AgregarMaterialForm',
    validate: (data) => {
        return validate(data, {
            material: validators.exists()("Campo requerido"),
            cantidad: validators.exists()("Campo requerido"),
            resumen: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default CrearForm;
