import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/materiales/materiales';
import ListadoMateriales from './ListadoMateriales';


const ms2p = (state) => {
    return {
        ...state.materiales,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoMateriales);
