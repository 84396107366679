import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderField, renderNumber, renderCurrency, renderPercentege, renderAsync } from "Utils/renderField/renderField";
import { getMateriales } from "utility/variables";
import { Link } from "react-router-dom";
import { icons } from "fotos";


export const renderMateriales = ({ fields, meta, meta: {error, submitFailed}, toggleAgregar, vistaAgregar, crearMaterial }) => (
    <div className="d-flex flex-column justify-content-center form-content">
        <div className="d-block d-lg-none">
            {fields.map((material, index) => (
                <div className="d-flex" key={index}>
                    <div className="d-flex flex-column justify-content-around align-items-stretch">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${material}.material_id`}>Material*</label>
                            <Field
                                name={`${material}.material_id`}
                                clearable={false}
                                component={renderAsync}
                                loadOptions={getMateriales}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="unidad">Unidad*</label>
                            <Field name={`${material}.unidad`} disabled component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="unidad">Cantidad*</label>
                            <Field name={`${material}.cantidad`} component={renderNumber} decimalScale={4} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="unidad">Porcentaje imprevisto*</label>
                            <Field name={`${material}.porcentaje_imprevisto`} component={renderPercentege} decimalScale={2} type="text" />
                        </div>
                    </div>
                    <img
                        src={icons.eliminar2}
                        alt=""
                        className="eliminar"
                        onClick={() => fields.remove(index)}
                    />
                </div>
            ))}
        </div>
        <table className="d-none d-lg-block">
            <tbody>
            <tr>
                <th className="pr-4 pl-4" style={{ width: "24%" }}>Material*</th>
                <th className="pr-4 pl-4" style={{ width: "23%" }}>Unidad*</th>
                <th className="pr-4 pl-4" style={{ width: "22%" }}>Cantidad*</th>
                <th className="pr-4 pl-4" style={{ width: "23%" }}>Porcentaje imprevisto*</th>
                <th style={{ width: "8%" }} />
            </tr>
            {fields.map((material, index) => (
                <tr key={index}>
                    <td className="pr-4 pl-4 pt-2">
                        <Field
                            name={`${material}.material_id`}
                            clearable={false}
                            component={renderAsync}
                            loadOptions={getMateriales}
                            placeholder="Seleccionar"
                            valueKey={"id"}
                            labelKey={"nombre"}
                        />
                    </td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.unidad`} component={renderField} disabled type="text" className="form-control" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.cantidad`} component={renderNumber} decimalScale={4} type="text" className="form-control" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.porcentaje_imprevisto`} component={renderPercentege} decimalScale={2} type="text" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <img
                            src={icons.eliminar2}
                            alt=""
                            className="eliminar"
                            onClick={() => fields.remove(index)}
                        />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        {submitFailed && (
            <div className="invalid-feedback d-block pl-4">
                {error}
            </div>
        )}
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start flex-row mt-3 pl-4 pr-4">
            {vistaAgregar ? (
                <React.Fragment>
                    <button type="button" className="d-block d-md-none btn btn-default" onClick={() => {
                        toggleAgregar();
                        fields.push({porcentaje_imprevisto: 0})}
                    }>
                        Agregar material
                    </button>
                    <button style={{textTransform: "capitalize"}} type="button" onClick={crearMaterial} className="btn d-block d-md-none btn-secondary mb-1">Crear nuevo material</button>
                </React.Fragment>
            ) : (<img className="d-block d-md-none align-self-center " src={icons.agregar} alt="" onClick={toggleAgregar}/>)}
            <button type="button" className="d-none d-md-block btn btn-default" onClick={() => fields.push({porcentaje_imprevisto: 0})}>
                Agregar material
            </button>
        </div>
    </div>
);


export const Form = (props) => {
    const { handleSubmit, titulo, crearMaterial, toggleAgregar, vistaAgregar } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="unidad">Unidad de medida*</label>
                            <Field name="unidad" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="mano_obra">Mano de obra</label>
                            <Field name="mano_obra" component={renderCurrency} className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" />
                    </div>
                </div>
            </div>
            <div className="form-container">
                <FieldArray
                    name="detalle"
                    component={renderMateriales}
                    crearMaterial={crearMaterial}
                    toggleAgregar={toggleAgregar}
                    vistaAgregar={vistaAgregar}
                />
            </div>
            <div className="d-flex justify-content-end flex-row mt-3 pr-3 pl-3">
                <button type="button" onClick={crearMaterial} className="btn d-none d-md-block btn-sm btn-secondary mr-auto">Crear nuevo material</button>
                <Link to="/plantillas" className="btn btn-secondary ml-1 mr-1 align-self-center">Cancelar</Link>
                <button type="submit" className="btn btn-primary ml-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};
