import React from 'react';
import { reduxForm } from 'redux-form';
import { validate, validators } from "validate-redux-form";
import { Form } from "../Form";
import { editarForm } from "../../../../../redux/modules/maquinaria_vehiculos/alquiler";


const EditarForm = reduxForm({
    // a unique name for the form
    form: editarForm,
    validate: (data) => {
        return validate(data, {
            cliente: validators.exists()("Campo requerido"),
        });
    },
})(Form);


export default EditarForm;
