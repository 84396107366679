import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import { Link } from "react-router-dom";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import Fases from "./Fases";
import { Cobros } from "./Cobros";
import { Inventario } from "./Inventario";
import Versus from "./VersusProyecto";
import Presupuesto from "../Presupuesto"
import Bajas from "./Inventario/Baja/ListadoBajas";
import "./fases.css";
import EncabezadoProyecto from "../EncabezadoProyecto";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";


class Detalle extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { tab: "1" };
    }


    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    tabChange = (tab) => {
        this.setState({tab});
    };

    render() {
        const { loader, item } = this.props;
        const { tab } = this.state;

        return (
            <div id="Fases" className="p-2 p-md-4 d-flex flex-column">
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <EncabezadoProyecto item={item} />
                    <Link className="btn btn-secondary mr-0 ml-auto my-2 mt-md-0" to="/proyectos">REGRESAR</Link>
                </div>

                <div className="cuadro">
                    <div className="azul paragraph-2 mr-2 mb-3">
                        Seleccione para ver!
                    </div>
                    <div id="">
                        <Tabs
                            activeKey={tab}
                            onChange={this.tabChange}
                            renderTabBar={()=><ScrollableInkTabBar />}
                            renderTabContent={()=><TabContent />}
                        >
                            <TabPane tab="FASES" key="1" >
                                <LoadMask loading={loader} light>
                                    <Fases {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="PRESUPUESTO" key="2" >
                                <Presupuesto {...this.props} />
                            </TabPane>
                            <TabPane tab="BALANCE PROYECTO" key="3" >
                                <Versus {...this.props} />
                            </TabPane>
                            <TabPane tab="COBROS PROYECTO" key="4" >
                                <LoadMask loading={loader} light>
                                    <Cobros {...this.props} />
                                </LoadMask>
                            </TabPane>
                            <TabPane tab="BODEGA" key="5" >
                                <LoadMask loading={loader} light>
                                    <Inventario {...this.props} />
                                </LoadMask>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default Detalle;
