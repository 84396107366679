import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/proyectos/proyectos';
import Detalle from './Detalle';


const ms2p = (state) => {
    return {
        ...state.proyectos,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Detalle);

export { default as CrearFase } from "./CrearFase";
export { default as DetalleFase, EditarFase } from "./Fase";
export { OrdenCompra, DetalleOrden } from "./OrdenCompra";
export { default as VersusFase } from "./Versus";
export { BajaInventario } from "./Inventario";
