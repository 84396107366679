import React from 'react';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";
import {MATERIAL} from "utility/variables";


export const detalleOrdenCompra = (item) => {
    const options = _.cloneDeep(tableOptions);
    return (row) => {
        const renglon = _.find(item.ordenes, {id: row.id});
        renglon.detalle.forEach((detalle) => {
            detalle.cantidad = parseFloat(detalle.cantidad);
            detalle.costo = parseFloat(detalle.costo);
            detalle.total = parseFloat(detalle.total);
        });
        return(
            <div className="inner-table">
                <div className="paragraph gris">{renglon.descripcion}</div>
                <BootstrapTable
                    data={renglon.detalle}
                    striped
                    bordered={false}
                    hover
                    options={options}
                >
                    <TableHeaderColumn
                        dataField="tipo"
                        dataSort
                        dataFormat={(cell, row) => {
                            if (cell === MATERIAL)
                                return `${row.nombre_material} ${row.unidad_material}`;
                            return row.gasto;
                        }}
                    >
                        Material
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="unidad_material"
                        dataAlign="right"
                    >
                        Unidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                        dataSort
                        dataAlign="right"
                    >
                        Cantidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo"
                        dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                        dataSort
                        dataAlign="right"
                    >
                        Costo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                        dataSort
                        dataAlign="right"
                    >
                        Sub Total
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        width={"0%"}
                    />
                </BootstrapTable>
            </div>
        )
    };
};
