import { handleActions } from 'redux-actions';
import _ from "lodash";
import uuidv1 from "uuid/v1";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { api, updateExpiracion } from "api";
import { ROLES } from "utility/variables";


// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'USUARIOS_LOADER';
const DATA = 'USUARIOS_DATA';
const ITEM = 'USUARIOS_ITEM';
const PAGE = 'USUARIOS_PAGE';
const ORDERING = 'USUARIOS_ORDERING';
const SEARCH = 'USUARIOS_SEARCH';
const UUID = 'USUARIOS_UUID';

export const constants = {
};

const endpoint = "usuarios";
const crearForm = "CrearUsuarioForm";
const editarForm = "EditarUsuarioForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { usuarios } = getStore();
    const params = { page };
    params.ordering = usuarios.ordering;
    params.search = usuarios.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().usuarios.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(setItem(response));
        const body = _.cloneDeep(response);
        if (body.perfil) {
            body.rol = _.find(ROLES, { label: body.perfil.rol }).value;
            body.nombre = body.perfil.nombre;
            body.telefono = body.perfil.telefono;
        }
        dispatch(initializeForm(editarForm, body));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    const body = _.cloneDeep(data);
    body.perfil = { ...body };
    api.post(endpoint, body).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/usuarios"));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    const body = _.cloneDeep(data);
    body.perfil = { ...body };
    api.put(`${endpoint}/${id}`, body).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/usuarios"));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/me`).then(response => {
        const body = _.cloneDeep(response);
        if (body.perfil) {
            body.rol = _.find(ROLES, { label: body.perfil.rol }).value;
            body.nombre = body.perfil.nombre;
            body.telefono = body.perfil.telefono;
        }
        dispatch(initializeForm('PerfilForm', body));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const updateMe = data => (dispatch) => {
    dispatch(setLoader(true));
    const body = _.cloneDeep(data);
    body.perfil = { ...body };
    api.post(`${endpoint}/update_me`, body).then(response => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        const body = _.cloneDeep(response);
        if (body.perfil) {
            body.rol = _.find(ROLES, { label: body.perfil.rol }).value;
            body.nombre = body.perfil.nombre;
            body.telefono = body.perfil.telefono;
        }
        dispatch(initializeForm('PerfilForm', body));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().usuarios.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    getMe,
    updateMe,
    searchChange,
    sortChange,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
