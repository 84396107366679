import { connect } from 'react-redux';
import { actions, crearForm } from '../../../../redux/modules/plantillas/plantillas';
import CrearPlantilla from './CrearPlantilla';


const ms2p = (state) => {
    const {form} = state;
    try {
        const detalle = form[crearForm].values.detalle;
        detalle.forEach((item) => {
            try {
                item.unidad = item.material_id.unidad;
            } catch (e) {}
        });
    } catch (e) {}
    return {
        ...state.plantillas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearPlantilla);
