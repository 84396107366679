import React, { Component } from 'react';
import { icons } from "utility/fotos";

class Home extends Component {
    render() {
        return (
            <div className="container">
                <div className="header-1 azul">
                    <img src={icons.logo} alt="" className="w-100"/>
                </div>
            </div>
        );
    }
}

export default Home;
