import _ from "lodash";

const validate = values => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = 'Campo requerido';
    }
    if (!values.nit) {
        errors.nit = 'Campo requerido';
    }
    if (!values.telefono) {
        errors.telefono = 'Campo requerido';
    }
    if (!values.email) {
        errors.email = 'Campo requerido';
    }
    if (!values.correo) {
        errors.correo = 'Campo requerido';
    }
    if (!values.direccion) {
        errors.direccion = 'Campo requerido';
    }
    if (!values.costos_materiales || !values.costos_materiales.length) {
        errors.costos_materiales = { _error: 'Debe ingresar al menos un material' }
    } else {
        const costos_materialesArrayErrors = [];
        values.costos_materiales.forEach((material, materialIndex) => {
            const materialErrors = {};
            if (!material || !material.material_id) {
                materialErrors.material_id = 'Campo requerido';
                costos_materialesArrayErrors[materialIndex] = materialErrors;
            }
            if (!material || (!material.costo && material.costo !== 0)) {
                materialErrors.costo = 'Campo requerido';
                costos_materialesArrayErrors[materialIndex] = materialErrors;
            }
            if (material && _.filter(values.costos_materiales, {material_id: material.material_id}).length > 1) {
                materialErrors.material = 'Material duplicado';
                costos_materialesArrayErrors[materialIndex] = materialErrors;
            }
        });
        if (costos_materialesArrayErrors.length) {
            errors.costos_materiales = costos_materialesArrayErrors
        }
    }
    return errors
};

export default validate
