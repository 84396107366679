import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda } from "../../../Utils/renderField/renderTableField";
import { materiales } from "./Materiales";


class Renglones extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    render() {
        const { item, match } = this.props;
        const { fases } = item;
        const fase = _.find(fases, { id: parseInt(match.params.fase) });
        let detalle = [];
        if (!!fase)
            detalle = fase.detalle;
        const options = _.cloneDeep(tableOptions);

        return (

            <BootstrapTable
                data={detalle}
                remote
                striped
                bordered={false}
                hover
                expandableRow={() => true}
                expandComponent={materiales(item)}
                options={options}
            >
                <TableHeaderColumn
                    dataField="resumen"
                    dataSort
                    dataFormat={(cell) => {
                        const resumen = _.find(item.resumenes, {id: cell});
                        return resumen.nombre;
                    }}
                >
                    RESUMEN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="renglon"
                    dataFormat={(cell) => {
                        const renglon = _.find(item.renglones, {id: cell.id});
                        return renglon.nombre;
                    }}
                >
                    RENGLÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                    dataSort
                    dataAlign="right"
                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="renglon"
                    dataFormat={(cell) => {
                        const renglon = _.find(item.renglones, {id: cell.id});
                        return <RenderMoneda monto={renglon.costo} />
                    }}
                    dataSort
                    dataAlign="right"
                >
                    COSTO UNITARIO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="costo"
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataSort
                    dataAlign="right"
                >
                    SUBTOTAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    width="0"
                />
            </BootstrapTable>
        );
    }
}

export default Renglones;
