import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderNumber } from "Utils/renderField/renderReadField";
import { isArray, isObject } from "utility/validation";


class Anterior extends Component {
    static propTypes = {
        detalle: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
    }
    formatoLlave(llave) {
        try {
            return llave.replace("_", " ");
        } catch (e) {
            return llave;
        }
    }

    formatoDetalle(valor, llave){
        try {
            if (isObject(valor)) {
                return (<div key={llave} className="d-flex flex-column p-2 ml-0 ml-sm-3">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {Object.keys(valor).sort().map((llave2) => {
                        return this.formatoDetalle(valor[llave2], llave2);
                    })}
                </div>)
            }
            if (isArray(valor)) {
                return (<div key={llave} className="d-flex flex-column p-2 ml-0 ml-sm-3">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {valor.map((llave2, index) => {
                        return this.formatoDetalle(llave2, index);
                    })}
                </div>)
            }
            if (parseFloat(valor) === parseFloat(valor)) {
                return (<div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderNumber value={valor} /></div>
                </div>)
            }
            if (valor === true) {
                return (<div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>Sí</div>
                </div>)
            }
            if (valor === false) {
                return (<div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>No</div>
                </div>)
            }
            return (<div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end">
                <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}}>{valor.toString()}</div>
            </div>)
        } catch (e) {
            return (<div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3">
                <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}} />
            </div>)
        }
    }

    render() {
        const { detalle } = this.props;


        return (
            <div className="d-flex flex-1 flex-column">
                <div className="bg-celeste w-100 p-1">
                    <div className="align-self-center azul ml-0 ml-sm-3"><strong>Estado anterior</strong></div>
                </div>
                {Object.keys(detalle).sort().map((llave) => {
                    return this.formatoDetalle(detalle[llave], llave);
                })}
            </div>
        )
    }
}

export default Anterior;
