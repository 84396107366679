import React, { Component } from 'react';
import { Async } from "react-select";
import { RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";
import { api } from "api";
import NumberFormat from 'react-number-format';
import { proveedores } from "./Costos";


const getProveedores = (id) => {
    return (search) => {
        return api.get("costos", {search, material_id: id}).then((data) => {
            data.results.forEach((item) => {
                if (proveedores[id] === undefined) {
                    proveedores[id] = [{...item}];
                } else {
                    if (!_.find(proveedores[id], { id: item.id })) {
                        proveedores[id].push({...item});
                    }
                }
            });
            return {options: proveedores[id]};
        }).catch((e) => {
            console.log(e);
        });
    }
};


class Costos extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, index: null };
    }

    render() {
        const { materiales, setProveedor, item, total_mano_obra, cambioValor, url } = this.props;
        let total_materiales = 0;

        if (materiales)
            return (
                <div className="d-block d-lg-none">
                    {materiales.map((material, index) => {
                        if (material.proveedor && material.cantidad)
                            total_materiales += (parseFloat(material.proveedor.costo) * material.cantidad);
                        return (
                            <div key={material.id} className={`d-flex renglon-${index%2}`}>
                                <div className="d-flex flex-column justify-content-around align-items-stretch w-100">
                                    <div className="d-flex m-1 pl-2 pr-2">
                                        <div className="paragraph gris flex-1">MATERIAL</div>
                                        <div className="paragraph-bold azul flex-1">{material.nombre}</div>
                                    </div>
                                    <div className="d-flex m-1 pl-2 pr-2">
                                        <div className="paragraph gris flex-1">UNIDAD</div>
                                        <div className="paragraph azul flex-1">{material.unidad}</div>
                                    </div>
                                    <div className="d-flex flex-column m-1 pl-2 pr-2 ">
                                        <div className="paragraph gris">PROVEEDOR</div>
                                        <Async
                                            clearable={false}
                                            value={material.proveedor}
                                            disabled={material.disabled}
                                            className="react-select-container"
                                            onChange={(e) => { setProveedor(material, e, url.includes("proyecto")) }}
                                            cache={false}
                                            searchPromptText="Escribe para buscar"
                                            valueKey="id"
                                            labelKey="nombre"
                                            loadOptions={getProveedores(material.id)}
                                        />
                                    </div>
                                    <div className="d-flex m-1 pl-2 pr-2">
                                        <div className="paragraph flex-1 gris">PRECIO UNITARIO</div>
                                        <RenderCurrency className="azul flex-1 text-right paragraph-bold" value={material.proveedor ? material.proveedor.costo : undefined} />
                                    </div>
                                    <div className="d-flex m-1 pl-2 pr-2">
                                        <div className="paragraph flex-1 gris">CANTIDAD TOTAL</div>
                                        <RenderNumber className="azul flex-1 text-right paragraph-bold" value={material.cantidad ? material.cantidad : 0} decimalScale={4} />
                                    </div>
                                    <div className="d-flex m-1 pl-2 pr-2">
                                        <div className="paragraph flex-1 gris">SUB TOTAL</div>
                                        <RenderCurrency className="azul flex-1 text-right paragraph-bold" value={
                                            (material.proveedor && material.cantidad) ?
                                                parseFloat(material.proveedor.costo) * material.cantidad : 0
                                        } />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className={`renglon-${materiales.length%2} p-3`} />
                    <div className={`d-flex align-items-end renglon-${(materiales.length+1)%2}`}>
                        <div className="flex-1 paragraph-bold pl-1 gris">MANO DE OBRA POR RENGLONES</div>
                        <RenderCurrency value={total_mano_obra} className="azul paragraph-bold pr-3 text-right flex-1" />
                    </div>
                    <div className={`d-flex align-items-end renglon-${(materiales.length)%2}`}>
                        <div className="flex-1 paragraph-bold pl-1 gris">MANO DE OBRA GLOBAL</div>
                        <NumberFormat
                            className="form-control text-right paragraph text-right flex-1"
                            decimalScale={2}
                            fixedDecimalScale
                            value={item.mano_obra}
                            thousandSeparator
                            prefix="Q "
                            onValueChange={(values) => { cambioValor('mano_obra', values.value) }}
                        />
                    </div>
                    <div className={`d-flex align-items-end renglon-${(materiales.length+1)%2}`}>
                        <div className="flex-1 paragraph-bold pl-1 gris">TOTAL DE MATERIALES</div>
                        <RenderCurrency value={total_materiales} className="azul paragraph-bold pr-3 text-right flex-1" />
                    </div>
                    <div className={`d-flex align-items-end renglon-${(materiales.length)%2}`}>
                        <div className="flex-1 paragraph-bold pl-1 gris">COSTO TOTAL</div>
                        <RenderCurrency
                            value={parseFloat(item.mano_obra ? item.mano_obra : "0") + parseFloat(total_mano_obra) + total_materiales}
                            className="azul paragraph-bold pr-3 text-right flex-1"
                        />
                    </div>
                    <div className={`d-flex align-items-end renglon-${(materiales.length+1)%2}`}>
                        <div className="flex-1 paragraph-bold pl-1 gris">PORCENTAJE DE GANANCIA</div>
                        <NumberFormat
                            className="form-control text-right paragraph text-right flex-1"
                            decimalScale={2}
                            fixedDecimalScale
                            value={item.ganancia}
                            thousandSeparator
                            prefix="%"
                            onValueChange={(values) => { cambioValor('ganancia', values.value) }}
                        />
                    </div>
                    <div className={`d-flex align-items-end renglon-${(materiales.length)%2}`}>
                        <div className="flex-1 paragraph-bold pl-1 gris">TOTAL DEL PROYECTO</div>
                        <RenderCurrency
                            value={
                                (parseFloat(item.ganancia ? item.ganancia : "0") / 100 + 1) * (parseFloat(item.mano_obra ? item.mano_obra : "0") + parseFloat(total_mano_obra) + total_materiales)
                            }
                            className="azul paragraph-bold pr-3 text-right flex-1"
                        />
                    </div>
                </div>
            );

        return (
            <table className="d-none d-lg-table">
                <thead>
                <tr>
                    <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul" >MATERIAL</th>
                    <th style={{width: "10%"}} className="p-1 paragraph bg-celeste azul" >UNIDAD</th>
                    <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul" >PROVEEDOR</th>
                    <th style={{width: "15%"}} className="p-1 paragraph bg-celeste azul text-right" >PRECIO UNITARIO</th>
                    <th style={{width: "15%"}} className="p-1 paragraph bg-celeste azul text-right" >CANTIDAD TOTAL</th>
                    <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul text-right" >SUB TOTAL</th>
                </tr>
                </thead>
                <tbody>
                {materiales.map((material, index) => {
                    if (material.proveedor && material.cantidad)
                        total_materiales += (parseFloat(material.proveedor.costo) * material.cantidad);
                    return (
                        <tr key={material.id} className={`renglon-${index%2}`}>
                            <td className="p-1 paragraph gris">
                                {material.nombre}
                            </td>
                            <td className="p-1 paragraph gris">
                                {material.unidad}
                            </td>
                            <td className="p-1 paragraph gris">
                                <Async
                                    clearable={false}
                                    value={material.proveedor}
                                    className="react-select-container"
                                    onChange={(e) => { setProveedor(material, e) }}
                                    cache={false}
                                    searchPromptText="Escribe para buscar"
                                    valueKey="id"
                                    labelKey="nombre"
                                    loadOptions={getProveedores(material.id)}
                                />
                            </td>
                            <td className="p-1 paragraph gris text-right">
                                <RenderCurrency value={material.proveedor ? material.proveedor.costo : undefined} />
                            </td>
                            <td className="p-1 paragraph gris text-right">
                                <RenderNumber value={material.cantidad ? material.cantidad : 0} decimalScale={4} />
                            </td>
                            <td className="p-1 paragraph gris text-right">
                                <RenderCurrency value={
                                    (material.proveedor && material.cantidad) ?
                                        parseFloat(material.proveedor.costo) * material.cantidad : 0
                                } />
                            </td>
                        </tr>
                    )
                })}
                <tr className={`renglon-${materiales.length%2}`}><td className="p-3" colSpan={6} /></tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">MANO DE OBRA POR RENGLONES</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency value={total_mano_obra} className={"azul pr-3"} />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">MANO DE OBRA GLOBAL</div></td>
                    <td className="p-1 paragraph gris">
                        <NumberFormat
                            className="form-control text-right paragraph"
                            decimalScale={2}
                            fixedDecimalScale
                            value={item.mano_obra}
                            thousandSeparator
                            prefix="Q "
                            onValueChange={(values) => { cambioValor('mano_obra', values.value) }}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL DE MATERIALES</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency value={total_materiales} className={"azul pr-3"} />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">COSTO TOTAL</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency
                            value={parseFloat(item.mano_obra ? item.mano_obra : "0") + parseFloat(total_mano_obra) + total_materiales}
                            className={"azul pr-3"}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">PORCENTAJE DE GANANCIA</div></td>
                    <td className="p-1 paragraph gris">
                        <NumberFormat
                            className="form-control text-right paragraph"
                            decimalScale={2}
                            fixedDecimalScale
                            value={item.ganancia}
                            thousandSeparator
                            prefix="%"
                            onValueChange={(values) => { cambioValor('ganancia', values.value) }}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length)%2}`}>
                    <td className="p-1 paragraph gris" colSpan={4} />
                    <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL DEL PROYECTO</div></td>
                    <td className="p-1 pt-2 paragraph gris text-right">
                        <RenderCurrency
                            value={
                                (parseFloat(item.ganancia ? item.ganancia : "0") / 100 + 1) * (parseFloat(item.mano_obra ? item.mano_obra : "0") + parseFloat(total_mano_obra) + total_materiales)
                            }
                            className={"azul pr-3"}
                        />
                    </td>
                </tr>
                <tr className={`renglon-${(materiales.length+1)%2}`}><td className="p-3" colSpan={6} /></tr>
                <tr className={`renglon-${(materiales.length)%2}`}><td className="p-3" colSpan={6} /></tr>
                </tbody>
            </table>
        );
    }
}

export default Costos;
