import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/maquinaria_vehiculos/maquinaria';
import ListadoMaquinaria from './ListadoMaquinaria';


const ms2p = (state) => {
    return {
        ...state.maquinaria,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoMaquinaria);
