import { connect } from 'react-redux';
import _ from "lodash";
import { actions } from '../../../../../redux/modules/presupuestos/presupuestos';
import { UNIDAD, MEDIA } from "utility/variables";
import Costos from './Costos';


const ms2p = (state) => {
    const materiales = [];
    const { item } = state.presupuestos;
    let total_mano_obra = 0;
    item.renglones.forEach((renglon) => {
        try {
            if (renglon.mano_obra && renglon.cantidad)
            total_mano_obra += (parseFloat(renglon.mano_obra) * parseFloat(renglon.cantidad));
        } catch (e) { }
        renglon.detalle.forEach((detalle) => {
            const material = _.find(materiales, {id: detalle.material.id});
            let cantidad = parseFloat(detalle.cantidad) * parseFloat(renglon.cantidad);
            if (detalle.es_herramienta) {
                cantidad = detalle.cantidad;
            }
            cantidad = cantidad + (cantidad * (parseFloat(detalle.porcentaje_imprevisto)/100));
            if (detalle.material.unidad_minima === UNIDAD) {
                cantidad = Math.ceil(cantidad);
            } else if (detalle.material.unidad_minima === MEDIA) {
                cantidad = Math.round(cantidad*2)/2;
            }
            if (!material) {
                materiales.push({
                    ...detalle.material,
                    cantidad,
                });
            } else {
                material.cantidad += cantidad;
            }
        });
    });

    const materialesProyecto = [];
    const { item: itemProyecto } = state.proyectos;
    let total_mano_obra_proyecto = 0;
    itemProyecto.renglones.forEach((renglon) => {
        try {
            if (renglon.mano_obra && renglon.cantidad)
            total_mano_obra_proyecto += (parseFloat(renglon.mano_obra) * parseFloat(renglon.cantidad));
        } catch (e) { }
        renglon.detalle.forEach((detalle) => {
            const material = _.find(materialesProyecto, {id: detalle.material.id});
            let cantidad = parseFloat(detalle.cantidad) * parseFloat(renglon.cantidad);
            if (detalle.es_herramienta) {
                cantidad = detalle.cantidad;
            }
            cantidad = cantidad + (cantidad * (parseFloat(detalle.porcentaje_imprevisto)/100));
            if (detalle.material.unidad_minima === UNIDAD) {
                cantidad = Math.ceil(cantidad);
            } else if (detalle.material.unidad_minima === MEDIA) {
                cantidad = Math.round(cantidad*2)/2;
            }
            if (!material) {
                materialesProyecto.push({
                    ...detalle.material,
                    disabled: renglon.disabled,
                    cantidad,
                });
            } else {
                material.cantidad += cantidad;
            }
        });
    });

    return {
        materiales,
        total_mano_obra,
        materialesProyecto,
        total_mano_obra_proyecto,
        url: window.location.href,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Costos);
