import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/presupuestos/presupuestos';
import CrearPresupuesto from './CrearPresupuesto';


const ms2p = (state) => {
    return {
        ...state.presupuestos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearPresupuesto);
