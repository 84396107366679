import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSelectField, renderTextArea } from "Utils/renderField/renderField";
import { UNIDAD_MINIMA } from "utility/variables";
import { Form } from "../Form";


const EditarForm = reduxForm({
    // a unique name for the form
    form: 'EditarMaterialForm',
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
            unidad: validators.exists()("Campo requerido"),
            unidad_minima: validators.number()("Campo requerido"),
        });
    },
})(Form);

export default EditarForm;
