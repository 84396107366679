import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { icons } from "fotos";
import './acciones.css';
import Swal from 'sweetalert2';


class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = {redirect: false, url: ''};
        this.redirect = this.redirect.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }
    redirect(url) {
        return () => {
            this.setState({url});
            this.setState({redirect: true});
        };
    }
    eliminar(id) {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    }

    editar(id) {
        return () => {
            this.props.editar(id);
        }
    }

    render() {
        const { id, ver, editar, eliminar } = this.props;

        return (
            <div className="row acciones">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {(ver !== undefined) && (
                        <Link to={`${ver}/${id}/`} ><img className="action-img" title="Detalle" src={icons.detalle} alt="Detalle"/></Link>
                    )}
                    {(editar !== undefined) && (
                        <Link to={`${editar}/${id}/editar`} ><img className="action-img" title="Editar" src={icons.editar} alt="Editar"/></Link>
                    )}
                    {(eliminar !== undefined) && (
                        <img style={{cursor: "pointer"}} onClick={this.eliminar(id)} className="action-img" title="Eliminar" src={icons.eliminar} alt="Eliminar"/>
                    )}
                </div>
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function activeFormatter(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones}/> )
    };
}
