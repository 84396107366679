import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import Modal from "react-responsive-modal";
import Tabs, { TabPane } from 'rc-tabs';
import EditarForm from "./EditarForm";
import GastoAlquilerForm from "./GastoAlquilerForm";
import TabContent from 'rc-tabs/lib/TabContent';
import { TIPO_MOVIMIENTO, UNIDAD_MAQUINA } from "utility/variables";
import { tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderCurrency, RenderDate } from "../../../Utils/renderField/renderReadField";
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
import _ from "lodash";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Responsable from "./Responsable";


class EditarAlquiler extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { tab: "1", openResponsable: false };
    }

    openModalResponsable = () => {
        this.setState({openResponsable: true});
    };

    closeModalResponsable = () => {
        this.setState({ openResponsable: false });
    };

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    editar = (data) => {
        const { editar, match } = this.props;
        editar(match.params.id, data);
    };

    subirGastos = (data) => {
        const { subirGastos, match } = this.props;
        subirGastos(match.params.id, data);
        this.setState({ tab: "1" });
    };

    activar = (data) => {
        const { match, activarAlquiler } = this.props;
        activarAlquiler(match.params.id, data);
        this.setState({openResponsable: false});
    };

    tabChange = (tab) => {
        this.setState({tab});
    };

    render() {
        const { loader, match, item, eliminar } = this.props;
        const { tab, openResponsable } = this.state;
        const maquinaria = [];
        item.detalle.forEach((maquina) => {
            maquinaria.push({...maquina, value: maquina.maquinaria_id, label: maquina.nombre_maquinaria});
        });
        const options = _.cloneDeep(tableOptions);

        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <LoadMask loading={loader} light>
                    {(item.estado === null) && (<div className="cuadro">
                        <button className="btn btn-default ml-auto paragraph mb-3" onClick={this.openModalResponsable}>Pasar a Contratado</button>
                        <EditarForm onSubmit={this.editar} titulo={"Editar Material"} />
                    </div>)}
                    <Modal open={openResponsable} onClose={this.closeModalResponsable} center showCloseIcon={false} classNames={{modal: "rr-modal"}}>
                        <Responsable cancelar={this.closeModalResponsable} onSubmit={this.activar} />
                    </Modal>
                    {(item.estado === false) && (
                        <div className="cuadro">
                            <div className="d-flex flex-column flex-md-row">
                                <div className="d-flex ">
                                    <div className="paragraph gris mr-1">Cliente</div>
                                    <div className="paragraph-bold azul">{item.cliente.nombre}</div>
                                </div>
                                <div className="d-flex ml-0 ml-md-4">
                                    <div className="paragraph gris mr-1">Fecha</div>
                                    <div className="paragraph-bold azul"><RenderDate value={item.fecha} /></div>
                                </div>
                                <button className="btn btn-default ml-auto paragraph mb-3" onClick={() => {eliminar(match.params.id)}}>Marcar Finalización de Alquiler</button>
                            </div>
                            <div className="py-4">
                                <BootstrapTable
                                    data={item.detalle}
                                    striped
                                    bordered={false}
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="nombre_maquinaria"
                                        dataSort
                                    >
                                        Maquinaria
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="unidad"
                                        dataSort
                                        dataFormat={(cell) => {
                                            return _.find(UNIDAD_MAQUINA, {value: cell}).label;
                                        }}
                                    >
                                        Unidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="precio"
                                        dataFormat={(cell) => <RenderMoneda monto={cell} decimalScale={2} />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Precio Unitario
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="minimo"
                                        dataFormat={(cell) => <RenderNumero value={cell} decimalScale={2} />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Mínimo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataSort
                                        isKey
                                        width={"0%"}
                                    />
                                </BootstrapTable>
                            </div>
                            <Tabs
                                activeKey={tab}
                                onChange={this.tabChange}
                                renderTabBar={()=><ScrollableInkTabBar />}
                                renderTabContent={()=><TabContent />}
                            >
                                <TabPane tab="Historial de movimientos" key="1">
                                    <BootstrapTable
                                        data={item.movimientos}
                                        striped
                                        bordered={false}
                                        hover
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="alquiler"
                                            dataSort
                                        >
                                            Maquinaria
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="fecha"
                                            dataSort
                                            dataFormat={(cell) => <RenderDate value={cell} />}
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="tipo"
                                            dataSort
                                            dataFormat={(cell) => {
                                                return _.find(TIPO_MOVIMIENTO, {value: cell}).label;
                                            }}
                                        >
                                            Tipo
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="monto"
                                            dataFormat={(cell) => <RenderMoneda monto={cell} decimalScale={2} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            Monto
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="descripcion"
                                            dataSort
                                            dataAlign="right"
                                        >
                                            Descripción
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataSort
                                            isKey
                                            width={"0%"}
                                        />
                                    </BootstrapTable>
                                    <div className="d-flex text-right flex-column flex-sm-row">
                                        <span className="header-2 gris py-1 px-3 ml-auto">
                                            BALANCE DEL ALQUILER&nbsp;&nbsp;
                                            <RenderCurrency className="header-1 azul" value={item.balance} />
                                        </span>
                                    </div>
                                </TabPane>
                                <TabPane tab="Movimiento de efectivo" key="2">
                                    <GastoAlquilerForm onSubmit={this.subirGastos} maquinarias={maquinaria} />
                                </TabPane>
                            </Tabs>
                        </div>
                    )}
                    {(item.estado === true) && (
                        <div className="cuadro">
                            <div className="d-flex flex-column flex-md-row">
                                <div className="d-flex ">
                                    <div className="paragraph gris mr-1">Cliente</div>
                                    <div className="paragraph-bold azul">{item.cliente.nombre}</div>
                                </div>
                                <div className="d-flex ml-0 ml-md-4">
                                    <div className="paragraph gris mr-1">Fecha</div>
                                    <div className="paragraph-bold azul"><RenderDate value={item.fecha} /></div>
                                </div>
                            </div>
                            <div className="py-4">
                                <BootstrapTable
                                    data={item.detalle}
                                    striped
                                    bordered={false}
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="nombre_maquinaria"
                                        dataSort
                                    >
                                        Maquinaria
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="unidad"
                                        dataSort
                                        dataFormat={(cell) => {
                                            return _.find(UNIDAD_MAQUINA, {value: cell}).label;
                                        }}
                                    >
                                        Unidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="precio"
                                        dataFormat={(cell) => <RenderMoneda monto={cell} decimalScale={2} />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Precio Unitario
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="minimo"
                                        dataFormat={(cell) => <RenderNumero value={cell} decimalScale={2} />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Mínimo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataSort
                                        isKey
                                        width={"0%"}
                                    />
                                </BootstrapTable>
                            </div>
                            <BootstrapTable
                                data={item.movimientos}
                                striped
                                bordered={false}
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="alquiler"
                                    dataSort
                                >
                                    Maquinaria
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataSort
                                    dataFormat={(cell) => <RenderDate value={cell} />}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return _.find(TIPO_MOVIMIENTO, {value: cell}).label;
                                    }}
                                >
                                    Tipo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="monto"
                                    dataFormat={(cell) => <RenderMoneda monto={cell} decimalScale={2} />}
                                    dataSort
                                    dataAlign="right"
                                >
                                    Monto
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="descripcion"
                                    dataSort
                                    dataAlign="right"
                                >
                                    Descripción
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    dataSort
                                    isKey
                                    width={"0%"}
                                />
                            </BootstrapTable>
                            <div className="d-flex text-right flex-column flex-sm-row">
                                <span className="header-2 gris py-1 px-3 ml-auto">
                                    BALANCE DEL ALQUILER&nbsp;&nbsp;
                                    <RenderCurrency className="header-1 azul" value={item.balance} />
                                </span>
                            </div>
                        </div>
                    )}
                </LoadMask>
            </div>
        );
    }
}

export default EditarAlquiler;
