import React from 'react';
import { icons } from 'fotos';
import { RenderDateTime as DateTime, RenderCurrency, RenderNumber, RenderPercentage } from './renderReadField';

export const RenderImage = ({ src, alt }) => {
  if (src !== null) {
    return (
      <img style={{ maxWidth: '50px' }} src={src} alt={alt} />
    );
  }
  return (
    <img style={{ maxWidth: '50px' }} src={icons.logo} alt={alt} />
  );
};

export const RenderDateTime = ({ fecha, className }) => {
  if (fecha) {
    return (
      <DateTime value={fecha} className={className} />
    );
  }
  return <span className={className}>Sin registro</span>;
};

export const RenderMoneda = ({ monto, className }) => {
  if (monto || monto === 0) {
    return (
      <RenderCurrency value={monto} className={className} />
    );
  }
  return <span className={className}>Sin registro</span>;
};

export const RenderNumero = ({ value, className, decimalScale }) => {
    if (value || value === 0) {
        return (
            <RenderNumber className={className} value={value} decimalScale={decimalScale} />
        )
    }
    return <span className={className}>Sin registro</span>;
};

export const RenderPorcentaje = ({ value, className, decimalScale }) => {
    if (value || value === 0) {
        return (
            <RenderPercentage className={className} value={value} decimalScale={decimalScale} />
        )
    }
    return <span className={className}>Sin registro</span>;
};
