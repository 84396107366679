import React, { Component } from 'react';
import { icons } from "fotos";
import { Link } from 'react-router-dom';
import "./menu.css";
import "./accordion.css";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import { SA, RP, SE } from "utility/variables";


class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'material-acordeon': false,
            'plantilla-acordeon': false,
            'maquinaria-acordeon': false,
            'administracion-acordeon': false,
            'no_option': true,
            'last': 'no_option',
        };
    }

    activePage = (item) => {

        const {
            url
        } = this.props;

        if (url.includes(item)) {
            return "active sidebar-item menu-item align-items-center justify-content-between d-flex";
        }
        return "sidebar-item menu-item align-items-center justify-content-between d-flex";
    };

    onClick = (id) => {
        return () => {

            if(this.state[id] === undefined){
                this.setState({
                    [this.state.last]: false,
                    no_option: true,
                    last: 'no_option'
                })
            } else if(this.state.last === id){
                this.setState({
                    [id]: false,
                    no_option: true,
                    last: 'no_option'
                })
            } else {
                this.setState({
                    [id]: true,
                    [this.state.last]: false,
                    last: id
                });
            }
        }
    };

    activeMenuOptions = (options, self) => {

        const { url } = this.props;
        for (let index = 0; index < options.length; index++) {
            if (url.includes(options[index])) {
                return `${this.state[self] === true ? 'expanded' : 'active'} sidebar-item menu-item align-items-center justify-content-between d-flex`;
            }
        }

        return `${this.state[self] === true ? 'expanded' : ''} sidebar-item menu-item align-items-center justify-content-between d-flex`
    };

    activeSubMenuPage = (item) => {

        const { url } = this.props;

        if (url.includes(item)) {
            return "active sidebar-item menu-subitem align-items-center justify-content-between d-flex";
        }

        return "sidebar-item menu-subitem align-items-center justify-content-between d-flex";
    };

    render() {
        const { me } = this.props;
        // state
        const itemsMenu = [
            'presupuestos',
            'proyectos',
            'materiales',
            'plantillas',  // oculto a SE
            'maquinaria',
            'alquileres',
            'vehiculos',
            'administracion',
            'clientes',
            'proveedores',
            'usuarios',  // oculto a SE y RP
            'bitacora'  // oculto a SE y RP
        ];

        return (
            <div style={{ position: "relative" }} id="menu">
                <div className="v-menu v-menu-front">
                    <div>
                        <Link to="/" className="d-flex justify-content-center mt-4 mb-4">
                            <img className="img-responsive" src={icons.logoMenu} alt="Logo" />
                        </Link>
                    </div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemTitle>
                                <Link to="/dashboard" onClick={this.onClick('home')} className={this.activePage("dashboard")}>
                                    <div>Inicio</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </Link>
                            </AccordionItemTitle>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemTitle>
                                <Link to="/presupuestos" onClick={this.onClick('home')} className={this.activePage("presupuestos")}>
                                    <div>Presupuestos</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </Link>
                            </AccordionItemTitle>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemTitle>
                                <Link to="/proyectos" onClick={this.onClick('home')} className={this.activePage("proyectos")}>
                                    <div>Proyectos</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </Link>
                            </AccordionItemTitle>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a onClick={this.onClick('material-acordeon')} className={this.activeMenuOptions(["materiales", "barras"], "material-acordeon")}>
                                    <div>Materiales</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </a>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link to="/materiales" className={this.activeSubMenuPage("materiales")}>
                                    Materiales
                                </Link>
                                <Link to="/barras" className={this.activeSubMenuPage("barras")}>
                                    Barras
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                        {(me.perfil.rol === SA || me.perfil.rol === RP) && (<AccordionItem>
                            <AccordionItemTitle>
                                <a onClick={this.onClick('plantilla-acordeon')} className={this.activeMenuOptions(["plantillas", "concretos"], "plantilla-acordeon")}>
                                    <div>Plantillas</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </a>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link to="/plantillas" className={this.activeSubMenuPage("plantillas")}>
                                    Plantillas
                                </Link>
                                <Link to="/concretos" className={this.activeSubMenuPage("concretos")}>
                                    Concretos
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>)}
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a onClick={this.onClick('maquinaria-acordeon')} className={this.activeMenuOptions(["maquinaria", "alquileres", "vehiculo"], "maquinaria-acordeon")}>
                                    <div>Maquinaria</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </a>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                {(me.perfil.rol === SA || me.perfil.rol === RP) && (<Link to="/maquinaria" className={this.activeSubMenuPage("maquinaria")}>
                                    Maquinaria
                                </Link>)}
                                <Link to="/alquileres" className={this.activeSubMenuPage("alquileres")}>
                                    Alquileres
                                </Link>
                                {(me.perfil.rol === SA || me.perfil.rol === RP) && (<Link to="/vehiculo" className={this.activeSubMenuPage("vehiculo")}>
                                    Vehículos
                                </Link>)}
                            </AccordionItemBody>
                        </AccordionItem>
                        {(me.perfil.rol === SA || me.perfil.rol === SE) && (<AccordionItem>
                            <AccordionItemTitle>
                                <a onClick={this.onClick('administracion-acordeon')} className={this.activeMenuOptions(["clientes", "proveedores", "usuarios"], "administracion-acordeon")}>
                                    <div>Administración</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </a>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                {(me.perfil.rol === SA || me.perfil.rol === SE) && (<Link to="/clientes" className={this.activeSubMenuPage("clientes")}>
                                    Clientes
                                </Link>)}
                                {(me.perfil.rol === SA || me.perfil.rol === SE) && (<Link to="/proveedores" className={this.activeSubMenuPage("proveedores")}>
                                    Proveedores
                                </Link>)}
                                {(me.perfil.rol === SA) && (<Link to="/usuarios" className={this.activeSubMenuPage("usuarios")}>
                                    Usuarios
                                </Link>)}
                            </AccordionItemBody>
                        </AccordionItem>)}
                        { me.perfil.rol === SA && (<AccordionItem>
                            <AccordionItemTitle>
                                <Link to="/bitacora" onClick={this.onClick('bitacora')} className={this.activePage("bitacora")}>
                                    <div>Bitácora</div>
                                    <img className="img-inactivo" src={icons.menuInactivo} alt="" />
                                    <img className="img-activo" src={icons.menuActivo} alt="" />
                                    <img className="img-expanded" src={icons.menuExpanded} alt="" />
                                </Link>
                            </AccordionItemTitle>
                        </AccordionItem>)}
                    </Accordion>

                </div>
            </div>
        );
    }
}

export default Menu;
