import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderNumber, RenderDateTime } from "Utils/renderField/renderReadField";
import { isArray, isObject } from "utility/validation";
import moment from "moment";

class Actual extends Component {
    static propTypes = {
        detalle: PropTypes.object.isRequired,
        anterior: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }
    formatoLlave(llave) {
        try {
            return llave.replace("_", " ");
        } catch (e) {
            return llave;
        }
    }

    formatoDetalle(valor, llave, anterior, resaltar){
        try {
            if (isObject(valor)) {
                return (<div key={llave} className="d-flex flex-column p-2 ml-0 ml-sm-3">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {Object.keys(valor).sort().map((llave2) => {
                        let comparacion = {};
                        try {
                            comparacion = anterior[llave2];
                        }
                        catch (e) { }
                        return this.formatoDetalle(valor[llave2], llave2, comparacion, resaltar);
                    })}
                </div>)
            }
            if (isArray(valor)) {
                return (<div key={llave} className="d-flex flex-column p-2 ml-0 ml-sm-3">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {valor.map((llave2, index) => {
                        let comparacion = {};
                        try {
                            comparacion = anterior[llave2];
                        }
                        catch (e) { }
                        return this.formatoDetalle(llave2, index, comparacion, resaltar);
                    })}
                </div>)
            }
            if (!isNaN(valor) && !(valor === true) && !(valor === false)) {
                // console.log("nan", valor);
                // console.log("NaN", !isNaN(valor));
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderNumber value={valor} /></div>
                </div>)
            }
            if (valor === true) {
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>Sí</div>
                </div>)
            }
            if (valor === false) {
                const diferentes = valor !== anterior;
                return (<div key={llave} className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>No</div>
                </div>)
            }
            let diferentes = false;
            try {
                diferentes = valor.toString() !== anterior.toString();
            } catch (e) { }
            const fecha = moment(valor.toString());
            if (fecha.isValid()) {
                return (<div key={llave} className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderDateTime value={valor} /></div>
                </div>)
            }
            return (<div key={llave} className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${(diferentes && resaltar) ? "text-danger" : ""}`}>
                <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}}>{valor.toString()}</div>
            </div>)
        } catch (e) {
            return (<div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3">
                <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}} />
            </div>)
        }
    }

    render() {
        const { detalle, anterior } = this.props;
        const resaltar = !!anterior;


        return (
            <div className="d-flex flex-1 flex-column">
                <div className="bg-celeste w-100 p-1">
                    <div className="azul align-self-center ml-0 ml-sm-3"><strong>Estado actual</strong></div>
                </div>
                {Object.keys(detalle).sort().map((llave) => {
                    let comparacion = {};
                    // try en dado caso el anterior no tiene la llave
                    try {
                        comparacion = anterior[llave]
                    } catch (e) {
                    }
                    return this.formatoDetalle(detalle[llave], llave, comparacion, resaltar);
                })}
            </div>
        )
    }
}

export default Actual;
