import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderDate } from "Utils/renderField/renderReadField";
import NuevaEntregaForm from "./NuevaEntregaForm";
import { detalleEntrega } from "./DetalleEntrega";
import { SE } from "utility/variables";


class Entregas extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        orden: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {nuevaEntrega: false};
    }

    cancelar = () => {
        this.setState({nuevaEntrega: false});
    };

    agregar = () => {
        const { orden, iniciarEntrega } = this.props;
        iniciarEntrega(orden.detalle);
        this.setState({nuevaEntrega: true});
    };

    registrarEntrega = (data) => {
        const { registrarEntrega } = this.props;
        registrarEntrega(data);
        this.setState({nuevaEntrega: false});
    };

    render() {
        const { orden, me } = this.props;

        const { nuevaEntrega } = this.state;

        const options = _.cloneDeep(tableOptions);

        return (
            <React.Fragment>
                {nuevaEntrega ? (
                    <NuevaEntregaForm cancelar={this.cancelar} onSubmit={this.registrarEntrega} />
                ) : (
                    me.perfil.rol !== SE && (<button onClick={this.agregar} className="btn btn-default paragraph ml-1 m-md-3">Agregar Entrega</button>)
                )}
                <div className="mb-4">
                    <BootstrapTable
                        data={orden.entregas}
                        expandableRow={() => true}
                        expandComponent={detalleEntrega}
                        striped
                        bordered={false}
                        hover
                        options={options}
                    >
                        <TableHeaderColumn
                            dataField="usuario"
                            dataSort
                        >
                            Usuario
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataFormat={(cell) => <RenderDate value={cell} />}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            width={"0%"}
                        />
                    </BootstrapTable>
                </div>
            </React.Fragment>
        );
    }
}

export default Entregas;
