import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/materiales/barras';
import EditarBarra from './EditarBarra';


const ms2p = (state) => {
    return {
        ...state.barras,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EditarBarra);
