import { handleActions } from 'redux-actions';
import _ from "lodash";
import uuidv1 from "uuid/v1";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api, updateExpiracion } from "api";
import { materiales } from "utility/variables";
import { NotificationManager } from "react-notifications";
import { UNIDAD_MINIMA } from "../../../utility/variables";


// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'CONCRETOS_LOADER';
const DATA = 'CONCRETOS_DATA';
const ITEM = 'CONCRETOS_ITEM';
const PAGE = 'CONCRETOS_PAGE';
const ORDERING = 'CONCRETOS_ORDERING';
const SEARCH = 'CONCRETOS_SEARCH';
const UUID = 'CONCRETOS_UUID';

export const constants = {
};

const endpoint = "concretos";
export const crearForm = "CrearConcretosForm";
export const editarForm = "EditarConcretosForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { concretos } = getStore();
    const params = { page };
    params.ordering = concretos.ordering;
    params.search = concretos.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().concretos.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(setItem(response));
        const form = _.cloneDeep(response);
        form.detalle.forEach((item) => {
            item.material_id = { ...item.material, value: item.material.id, label: item.material.nombre };
        });
        dispatch(initializeForm(editarForm, form));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    form.detalle.forEach((item) => {
        try {
            if (item.material_id.id)
                item.material_id = item.material_id.id;
        } catch (e) {
        }
    });
    api.post(endpoint, form).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/concretos"));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const inicializarCreate = () => (dispatch) => {
    dispatch(initializeForm(crearForm, {unidad: "m3"}));
};

export const crearMaterial = (data, formulario) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post("materiales", data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        const form = getStore().form[formulario].values;
        if (form) {
            const plantilla = _.cloneDeep(form);
            if (plantilla.detalle) {
                plantilla.detalle.push({material_id: response, unidad: response.unidad});
            } else {
                plantilla.detalle = [{material_id: response, unidad: response.unidad}];
            }
            dispatch(initializeForm(formulario, plantilla));
        } else {
            const plantilla = {detalle: [{material_id: response, unidad: response.unidad}]};
            dispatch(initializeForm(formulario, plantilla));
        }
    }).catch((e) => {
        console.log(e);
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const materialChange = (data, nombre) => (dispatch, getStore) => {
    const form = _.cloneDeep(getStore().form[nombre].values);
    const material = _.find(materiales, { id: data.value });
    if (material) {
        const _materiales = form.materiales;
        const item = _.find(_materiales, { nombre: data });
        item.unidad = material.unidad;
        item.unidad_minima = _.find(UNIDAD_MINIMA, { label: material.unidad_minima });
        dispatch(initializeForm(nombre, form));
    }
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    form.detalle.forEach((item) => {
        try {
            if (item.material_id.id)
                item.material_id = item.material_id.id;
        } catch (e) {
        }
    });
    api.put(`${endpoint}/${id}`, form).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/concretos"));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().concretos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    inicializarCreate,
    searchChange,
    sortChange,
    materialChange,
    crearMaterial,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    uuid: '',
};

export default handleActions(reducers, initialState);
