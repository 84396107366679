import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import LoadMask from "Utils/LoadMask";
import Buscador from "Utils/Buscador";
import { RenderDate } from "../../../Utils/renderField/renderReadField";
import { maquinaria } from "./Maquinaria";
import { icons } from "../../../../../utility/fotos";
import { SE } from "utility/variables";


class ListadoAlquileres extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { loader, data, page, listar, sortChange, search, searchChange, me } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                {me.perfil.rol !== SE && (<div className="d-flex align-self-end align-items-center mr-2 mr-md-5 mb-3">
                    <div className="azul paragraph-2 mr-4">
                        Seleccione para agregar!
                    </div>
                    <Link className="btn btn-primary" to="alquileres/crear">Nuevo Alquiler</Link>
                </div>)}
                <LoadMask loading={loader} light>
                    <div className="cuadro">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="header-1 azul">ALQUILERES</div>
                            <Buscador search={search} searchChange={searchChange} />
                        </div>
                        <BootstrapTable
                            data={data.results}
                            remote
                            striped
                            expandableRow={() => true}
                            expandComponent={maquinaria}
                            bordered={false}
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            pagination
                        >
                            <TableHeaderColumn
                                dataField="cliente"
                                dataSort
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataFormat={(cell) => <RenderDate value={cell} />}
                                dataSort
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="responsable"
                                dataSort
                            >
                                Responsable
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="usuario"
                                dataSort
                            >
                                Usuario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estado"
                                dataFormat={(cell) => {
                                    if (cell === false) {
                                        return "En Curso"
                                    } else if (cell === true) {
                                        return "Finalizado"
                                    }
                                    return "Cotización"
                                }}
                                dataSort
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                dataAlign="center"
                                // dataFormat={StandardActions({ editar: '/alquileres', eliminar })}
                                dataFormat={(cell, row) => {
                                    if (row.estado !== true)
                                        return (
                                            <div className="row acciones">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <Link to={`alquileres/${cell}/editar`} ><img className="action-img" title="Editar" src={icons.editar} alt="Editar"/></Link>
                                                </div>
                                            </div>
                                        );
                                    return (
                                        <div className="row acciones">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <Link to={`alquileres/${cell}/editar`} ><img className="action-img" title="Editar" src={icons.detalle} alt="Editar"/></Link>
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default ListadoAlquileres;
