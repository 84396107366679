import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderImagePicker, renderTextArea } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";
import { editarForm } from "../../../../redux/modules/maquinaria_vehiculos/maquinaria";


const Form = (props) => {
    const { handleSubmit, titulo, item } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field name="nombre" disabled component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="placa">Placa*</label>
                            <Field name="placa" disabled component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="descripcion">Descripción</label>
                            <Field
                                disabled
                                name="descripcion"
                                component={renderTextArea}
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="foto">Foto*</label>
                            <img src={item.foto} alt="" className="mw-100"/>
                        </div>
                    </div>
                    <div className="header-2 azul my-2">Dar de baja</div>
                    <div className="d-flex flex-column form-group m-1 px-2 px-md-2">
                        <label htmlFor="motivo">Motivo</label>
                        <Field
                            name="motivo"
                            component={renderTextArea}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end flex-row pr-3">
                <Link to={"/maquinaria"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
            </div>
        </form>
    );
};


const EditarForm = reduxForm({
    // a unique name for the form
    form: editarForm,
    validate: (data) => {
        return validate(data, {
            motivo: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default EditarForm;
