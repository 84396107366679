import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { StandardActions } from "Utils/TableActions";
import LoadMask from "Utils/LoadMask";
import Buscador from "Utils/Buscador";


class ListadoUsuarios extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { loader, data, page, listar, sortChange, search, searchChange, eliminar } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="d-flex align-self-end align-items-center mr-2 mr-md-5 mb-3">
                    <div className="azul paragraph-2 mr-4">
                        Seleccione para agregar!
                    </div>
                    <Link className="btn btn-primary" to="usuarios/crear">Nuevo Usuario</Link>
                </div>
                <LoadMask loading={loader} light>
                    <div className="cuadro">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="header-1 azul">USUARIOS</div>
                            <Buscador search={search} searchChange={searchChange} />
                        </div>
                        <BootstrapTable
                            data={data.results}
                            remote
                            striped
                            bordered={false}
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            pagination
                        >
                            <TableHeaderColumn
                                dataField="perfil"
                                dataSort
                                dataFormat={(cell) => {
                                    if (cell) {
                                        return cell.nombre;
                                    }
                                    return "SUPERUSER";
                                }}
                            >
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="username"
                                dataSort
                            >
                                Usuario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="email"
                                dataSort
                            >
                                Correo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="perfil"
                                dataSort
                                dataFormat={(cell) => {
                                    if (cell) {
                                        return cell.telefono;
                                    }
                                    return "---";
                                }}
                            >
                                Teléfono
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="perfil"
                                dataSort
                                dataFormat={(cell) => {
                                    if (cell) {
                                        return cell.rol;
                                    }
                                    return "SUPERUSER";
                                }}
                            >
                                Puesto
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                dataAlign="center"
                                dataFormat={StandardActions({ editar: '/usuarios', eliminar })}
                            />
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default ListadoUsuarios;
