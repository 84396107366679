import { handleActions } from 'redux-actions';
import uuidv1 from "uuid/v1";
import moment from "moment";
import _ from "lodash";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api, updateExpiracion } from "api";
import { NotificationManager } from "react-notifications";
import { resumenes, materiales, getMateriales } from "utility/variables";
import { proveedores } from "../../../common/components/Presupuestos/Editar/Costos/Costos";
import Swal from 'sweetalert2';

// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'PROYECTOS_LOADER';
const DATA = 'PROYECTOS_DATA';
const ITEM = 'PROYECTOS_ITEM';
const FASE = 'PROYECTOS_FASE';
const PAGE = 'PROYECTOS_PAGE';
const ORDERING = 'PROYECTOS_ORDERING';
const SEARCH = 'PROYECTOS_SEARCH';
const UUID = 'PROYECTOS_UUID';
const TAB = 'PROYECTOS_TAB';

export const constants = {
};

const endpoint = "proyectos";
const crearForm = "CrearPresupuestoForm";
const editarForm = "EditarPresupuestoForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const tabChange = tab => ({
    type: TAB,
    tab,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setFase = fase => ({
    type: FASE,
    fase,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { proyectos } = getStore();
    const params = { page };
    params.ordering = proyectos.ordering;
    params.search = proyectos.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().proyectos.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const parseItem = (response) => (dispatch) => {
    const renglones = [];
        response.resumenes.forEach((resumen) => {
            if (!_.find(resumenes, {id: resumen.nombre})) {
                resumenes.push({id: resumen.nombre, nombre: resumen.nombre});
            }
            resumen.renglones.forEach((renglon) => {
                renglon.detalle.forEach((material) => {
                    if (material.costo) {
                        const proveedor = {
                            nombre: `${material.nombre_proveedor}/${material.direccion_proveedor}/${material.costo_material}`,
                            id: material.costo_material,
                            costo: material.costo,
                            proveedor: {id: material.proveedor, nombre: material.nombre_proveedor, direccion: material.direccion_proveedor}
                        };
                        material.material.proveedor = proveedor;
                        proveedores[material.material.id] = [
                            {...proveedor}
                        ];
                    }
                    if (!_.find(materiales, { id: material.id })) {
                        getMateriales(material.nombre_material);
                        // materiales.push({...material, nombre: material.nombre_material});
                    }
                });
                renglones.push({
                    ...renglon,
                    disabled: true,
                    resumen,
                    index: renglones.length,
                })
            });
        });
        dispatch(setItem({...response, renglones}));
        dispatch(initializeForm(editarForm, response));
};

export const leer = (id, fase) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(parseItem(response));
        if (fase) {
            dispatch(setFase(_.find(response.fases, {id: parseInt(fase)})));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const agregarRenglon = renglon => (dispatch, getStore) => {
    const item = _.cloneDeep(getStore().proyectos.item);
    if (!_.find(resumenes, {id: renglon.resumen.id})) {
        resumenes.push(renglon.resumen);
    }
    renglon.renglon.detalle.forEach((detail) => {
        let proveedor;
        item.renglones.forEach((objeto) => {
            objeto.detalle.forEach((detalle) => {
                if(detalle.material.id === detail.material.id)
                    if (!!detalle.material.proveedor)
                        proveedor = detalle.material.proveedor;
            });
        });
        if (!!proveedor)
            detail.material.proveedor = proveedor;
    });
    item.renglones.push({...renglon.renglon, resumen: renglon.resumen, index: item.renglones.length, cantidad: renglon.cantidad});
    dispatch(setItem(item));
};

export const cambioRenglon = (index, campo, valor) => (dispatch, getStore) => {
    const { proyectos } = getStore();
    const item = _.cloneDeep(proyectos.item);
    const renglones = item.renglones;
    renglones[index][campo] = valor;
    dispatch(setItem(item));
};

export const editar = (id, actualizar) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const { proyectos: { item }, form: {[editarForm]: data} } = getStore();
    const { nombre, cliente, ubicacion } = data.values;
    api.put(`${endpoint}/${id}`, { ...item, nombre, cliente, ubicacion }, {actualizar}).then((response) => {
        dispatch(parseItem(response));
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const descargar = id => (dispatch) => {
    window.location.replace(`/api/${endpoint}/${id}/descargar?auth_token=${sessionStorage.getItem("token")}`);
};

export const finalizar = (id) => (dispatch) => {
    Swal.fire({
        title: '¿Dar por finalizado el proyecto?',
        text: '¡Esta acción no se puede revertir!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Sí, Finalizar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/finalizar`).then(() => {
                NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            }).catch(() => {
                NotificationManager.error('Error en la edición', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            })
        }
    });
};

export const crearFase = (data, fecha_inicio, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}/crear_fase`, {data, fecha_inicio}).then((result) => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push(`/proyectos/${id}`));
    }).catch((error) => {
        if (error.detail)
            NotificationManager.error(error.detail, 'ERROR', 0);
        else
            NotificationManager.error('Error en la transacción', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editarFase = (data, fecha_inicio, id, fase) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}/editar_fase`, {data, fecha_inicio}, {fase}).then((result) => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push(`/proyectos/${id}/fases/${fase}`));
    }).catch((error) => {
        if (error.detail)
            NotificationManager.error(error.detail, 'ERROR', 0);
        else
            NotificationManager.error('Error en la transacción', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crearOrdenCompra = (data, fase, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('ordenes', {...data, fase, proveedor: data.proveedor.id}).then((result) => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push(`/proyectos/${id}/fases/${fase}`));
    }).catch((error) => {

    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registrarEntrega = (data, orden, id) => (dispatch) => {
    Swal.fire({
        title: '¿Registrar Entrega?',
        text: '¡Ya no podrás cambiar revertir el cambio!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Sí, registrar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            let pase = false;
            data.detalle.forEach((detalle) => {
                if (parseFloat(detalle.cantidad))
                    pase = true;
            });
            if (pase) {
                dispatch(setLoader(true));
                api.post('entregas', {
                    ...data,
                    orden
                })
                    .then((result) => {
                        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
                        dispatch(leer(id));
                    })
                    .catch((error) => {
                        if (error.detail)
                            NotificationManager.error(error.detail, 'ERROR', 0);
                        else
                            NotificationManager.error('Error en la transacción', 'ERROR', 0);
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
            }
            else
                NotificationManager.error('Asigne al menos una cantidad', 'ERROR', 0);
        }
    });

};

export const registrarPago = (data, orden, id) => (dispatch) => {
    Swal.fire({
        title: '¿Registrar Pago?',
        text: '¡Ya no podrás cambiar revertir el cambio!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Sí, registrar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.post('pagos', {...data, orden}).then(() => {
                NotificationManager.success('Pago registrado', 'Éxito', 3000);
                dispatch(leer(id));
            }).catch((error) => {
                if (error.detail)
                    NotificationManager.error(error.detail, 'ERROR', 0);
                else
                    NotificationManager.error('Error en la transacción', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            })
        }
    });
};

export const registrarCobro = (data, proyecto) => (dispatch) => {
    Swal.fire({
        title: '¿Registrar Cobro?',
        text: '¡Ya no podrás cambiar revertir el cambio!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Sí, registrar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.post('cobros', {...data, proyecto}).then(() => {
                NotificationManager.success('Pago registrado', 'Éxito', 3000);
                dispatch(leer(proyecto));
            }).catch((error) => {
                if (error.detail)
                    NotificationManager.error(error.detail, 'ERROR', 0);
                else
                    NotificationManager.error('Error en la transacción', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            })
        }
    });
};

export const iniciarEntrega = (detalle) => (dispatch) => {
    dispatch(initializeForm('NuevaEntregaForm', {detalle, fecha: moment(new Date()).format("YYYY-MM-DD")}));
};

export const finalizarFase = (proyecto, fase) => (dispatch) => {
    Swal.fire({
        title: '¿Finalizar fase?',
        text: '¡Ya no podrás cambiar revertir el cambio!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Sí, finalizar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${proyecto}/finalizar_fase`, {id: fase}).then(() => {
                NotificationManager.success('Fase finalizada', 'Éxito', 3000);
                dispatch(push(`/proyectos/${proyecto}`));
            }).catch(() => {
                NotificationManager.error('Error en la transacción', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            })
        }
    });
};

export const leerInventario = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(setItem(response));
        const form = _.cloneDeep(response);
        form.inventario = _.filter(form.inventario, (o) => (parseFloat(o.cantidad) > 0));
        dispatch(initializeForm('BajaInventarioForm', {...form, fecha: moment(new Date()).format("YYYY-MM-DD")}));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const baja = (proyecto, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('baja_inventario', {...data, proyecto}).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push(`/proyectos/${proyecto}`));
    }).catch((error) => {
        if (error.detail)
            NotificationManager.error(error.detail, 'ERROR', 0);
        else
            NotificationManager.error('Error en la transacción', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().proyectos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    leer,
    editar,
    setItem,
    agregarRenglon,
    crearFase,
    editarFase,
    crearOrdenCompra,
    registrarPago,
    searchChange,
    tabChange,
    sortChange,
    descargar,
    finalizar,
    finalizarFase,
    iniciarEntrega,
    registrarEntrega,
    registrarCobro,
    leerInventario,
    baja,
    cambioRenglon,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [FASE]: (state, { fase }) => {
        updateExpiracion();
        return {
            ...state,
            fase,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
    [TAB]: (state, { tab }) => {
        updateExpiracion();
        return {
            ...state,
            tab,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {
        nombre: "",
        responsable: {},
        renglones: [
            {detalle: [], id: 0},
        ],
        cliente: {
            nombre: ""
        },
        mano_obra: "",
        fases: [
            {finalizado: false, detalle: [], id: 0, ordenes: [],},
        ],
        bajas: [

        ],
    },
    fase: {},
    page: 1,
    tab: "1",
    ordering: '',
    search: '',
    uuid: '',
};

export default handleActions(reducers, initialState);
