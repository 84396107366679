import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/maquinaria_vehiculos/maquinaria';
import CrearMaquinaria from './CrearMaquinaria';
import { crearForm } from "../../../../redux/modules/maquinaria_vehiculos/maquinaria";


const ms2p = (state) => {
    let foto;
    try {
        foto = state.form[crearForm].values.foto;
    } catch (e) {
    }
    return {
        ...state.maquinaria,
        foto
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearMaquinaria);
