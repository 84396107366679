import React, { Component } from 'react';
import { Field } from 'redux-form';
import {
    renderField,
    renderNumber,
    renderCurrency,
    renderPercentege,
    renderAsync,
    renderMultiAsync,
    renderMultiSelectField,
} from "Utils/renderField/renderField";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { getMateriales, materiales, TIPOS_PLANTILLA, getBarras, LONGITUDES } from "utility/variables";
import { icons } from "../../../../utility/fotos";
import { getLongitudesEstribos } from "./funciones";


class Zapata extends Component {
    render() {
        const {
            ancho,
            largo,
            barras_a,
            barras_b,
            recubrimiento,
            separacion_a,
            separacion_b,
            longitudes,
            camas,
        } = this.props;
        const _recubrimiento = !!parseFloat(recubrimiento) ? parseFloat(recubrimiento) : 0;
        let lado_a = !!parseFloat(ancho) ? (ancho - (_recubrimiento * 2)) / separacion_a : 0;
        let lado_b = !!parseFloat(largo) ? (largo - (_recubrimiento * 2)) / separacion_b : 0;
        lado_a = Math.ceil(lado_a);
        lado_b = Math.ceil(lado_b);
        const barras_lado_a = getLongitudesEstribos(longitudes.map((item) => item.value), (ancho - (_recubrimiento * 2)), lado_a);
        const barras_lado_b = getLongitudesEstribos(longitudes.map((item) => item.value), (largo - (_recubrimiento * 2)), lado_b);
        return (
            <div className="d-flex flex-column">
                {/*CAMAS*/}
                <div className="form-container" style={{
                    marginTop: "0",
                    marginBottom: "0",
                }}>
                    <div className="d-flex flex-column justify-content-center form-content">
                        <div className="d-flex flex-column flex-2">
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="recubrimiento">Recubrimiento*</label>
                                    <Field name="recubrimiento" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4">
                                    <label htmlFor="camas">No. Camas*</label>
                                    <Field name="camas" component={renderNumber} decimalScale={0}
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="longitudes">Longitudes Disponibles*</label>
                                    <Field
                                        name="longitudes"
                                        options={LONGITUDES}
                                        component={renderMultiSelectField}
                                        labelKey={"label"}
                                        valueKey={"value"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container" style={{
                    marginTop: "0",
                    marginBottom: "0",
                }}>
                    <div className="d-flex flex-column flex-lg-row-reverse justify-content-center form-content">
                        <div className="d-flex flex-column flex-1">
                            <img src={icons.zapata} alt="" className="w-100"/>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="separacion_a">Separación lado A*</label>
                                    <Field name="separacion_a" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4">
                                    <label htmlFor="separacion_b">Separación lado B*</label>
                                    <Field name="separacion_b" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="barras_a">No. Barras A*</label>
                                    <Field
                                        name="barras_a"
                                        loadOptions={getBarras}
                                        component={renderAsync}
                                        labelKey={"nombre"}
                                        valueKey={"id"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4">
                                    <label htmlFor="barras_b">No. Barras B*</label>
                                    <Field
                                        name="barras_b"
                                        loadOptions={getBarras}
                                        component={renderAsync}
                                        labelKey={"nombre"}
                                        valueKey={"id"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-2">
                                <div className="small-font azul pl-2 mt-2 pr-2 pl-md-4 pr-md-4">BARRAS PARA LADO A</div>
                                <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                    {Object.keys(barras_lado_a).map((key) => {
                                        return <div key={key} className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                            <label>Barras de {key}m</label>
                                            <RenderNumber value={barras_lado_a[key] * camas} suffix={` barras ${barras_a ? barras_a.nombre : ""}`} className="gris paragraph"/>
                                        </div>
                                    })}
                                </div>
                                <div className="small-font azul pl-2 mt-2 pr-2 pl-md-4 pr-md-4">BARRAS PARA LADO B</div>
                                <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                    {Object.keys(barras_lado_b).map((key) => {
                                        return <div key={key} className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                            <label>Barras de {key}m</label>
                                            <RenderNumber value={barras_lado_b[key] * camas} suffix={` barras ${barras_b ? barras_b.nombre : ""}`} className="gris paragraph"/>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Zapata;
