import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import CrearForm from "./Form";


class CrearVehiculo extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {foto: null};
    }


    crear = (data) => {
        if(data){
            this.props.crear(data, {name: "foto", file: this.state.foto});
        }
    };

    setFile = (foto) => {
        this.setState({foto});
    };

    render() {
        const { loader } = this.props;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <CrearForm setFile={this.setFile} onSubmit={this.crear} titulo={"Crear Vehículo"} foto={this.props.foto} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CrearVehiculo;
