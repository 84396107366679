import React from 'react';
import moment from "moment/moment";
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from "validate-redux-form";
import { renderDatePicker, renderField, renderSelectField, renderCurrency } from "Utils/renderField/renderField";
import { FORMAS_PAGO } from "utility/variables";


const Form = (props) => {
    const { handleSubmit, cancelar } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="border">
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="fecha">Fecha*</label>
                            <Field
                                name="fecha"
                                component={renderDatePicker}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="total">Monto*</label>
                            <Field
                                name="total"
                                component={renderCurrency}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="forma_pago">Forma de pago*</label>
                            <Field
                                name="forma_pago"
                                component={renderSelectField}
                                options={FORMAS_PAGO}
                                labelKey="label"
                                valueKey="value"
                                clearable={false}
                                placeholder="Seleccionar"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" />
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="documento">No. Documento*</label>
                            <Field
                                name="documento"
                                component={renderField}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="transaccion">No. Transacción*</label>
                            <Field
                                name="transaccion"
                                component={renderField}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="observaciones">Observaciones</label>
                            <Field
                                name="observaciones"
                                component={renderField}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-start flex-row-reverse mt-3 mb-3 pr-3">
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
                <button type="button" onClick={cancelar} className="btn btn-secondary ml-1 mr-1">Cancelar</button>
            </div>
        </form>
    );
};

const NuevoCobroForm = reduxForm({
    // a unique name for the form
    form: 'NuevoCobroForm',
    initialValues: {
        fecha: moment(new Date()).format("YYYY-MM-DD")
    },
    validate: (data) => {
        return validate(data, {
            monto: validators.exists()("Campo requerido"),
            forma_pago: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default NuevoCobroForm;
