import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/proyectos/proyectos';
import EditarFase from './EditarFase';


const ms2p = (state) => {
    return {
        ...state.proyectos,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EditarFase);
