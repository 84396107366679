import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Integracion from "./Integracion";
import Ganancia from "./Ganancia";
import { SE } from "utility/variables";


class DetalleProyecto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {tab: "1"};
    }

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    editar = () => {
        const { editar } = this.props;
        const { match } = this.props;
        editar(match.params.id);
    };

    tabChange = (tab) => {
        this.setState({ tab });
    };

    render() {
        const { loader, item, finalizar, match, descargar, me } = this.props;
        const { tab } = this.state;
        const esSe = me.perfil.rol === SE;
        return (
            <div id="PresupuestoProyecto" className="p-2 p-md-4 d-flex flex-column">
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                    <button onClick={() => descargar(match.params.id)} className="btn btn-default paragraph mx-0 mx-md-2">Descargar Excel</button>
                    {(item.estado !== "Finalizado" && !esSe) && (<button onClick={() => finalizar(match.params.id)} className="btn btn-default paragraph">Marcar finalización del proyecto</button>)}
                </div>
                <div className="">
                    <Tabs
                        activeKey={tab}
                        onChange={this.tabChange}
                        renderTabBar={()=><ScrollableInkTabBar />}
                        renderTabContent={()=><TabContent />}
                    >
                        <TabPane tab="DETALLADO" key="1" >
                            <LoadMask loading={loader} light>
                                {esSe ? (<Ganancia {...this.props} />) : (<Integracion {...this.props} />)}
                            </LoadMask>
                        </TabPane>
                        {!esSe && (<TabPane tab="INTEGRACIÓN" key="2">
                            <LoadMask loading={loader} light>
                                <Ganancia {...this.props} />
                            </LoadMask>
                        </TabPane>)}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DetalleProyecto;
