import React, { Component } from 'react';
import { RenderNumber, RenderCurrency } from "Utils/renderField/renderReadField";


class Ganancia extends Component {

    render() {
        const { item } = this.props;

        return (
            <div id="integracion" className="d-flex flex-column">
                <div className="form-head small-font">INTEGRACIÓN DE COSTOS</div>
                <div className="react-bs-table-container" style={{overflowX: "auto"}}>
                    <table className="table mt-1">
                        <thead>
                        <tr>
                            <th style={{width: "30%"}} className="p-1 paragraph bg-celeste azul" >RENGLON</th>
                            <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul" >UNIDAD</th>
                            <th style={{width: "15%"}} className="p-1 paragraph bg-celeste azul text-right" >CANTIDAD</th>
                            <th style={{width: "25%"}} className="p-1 paragraph bg-celeste azul text-right" >PRECIO UNITARIO</th>
                            <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul text-right" >SUB TOTAL</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item.renglones.map((renglon, index) => {
                            return (<tr key={index} className={`renglon-${index%2}`}>
                                <td className="p-1 paragraph gris">
                                    {renglon.nombre}
                                </td>
                                <td className="p-1 paragraph gris">
                                    {renglon.unidad}
                                </td>
                                <td className="p-1 paragraph gris text-right">
                                    <RenderNumber value={renglon.cantidad} decimalScale={4} />
                                </td>
                                <td className="p-1 paragraph gris text-right">
                                    <RenderCurrency value={renglon.costo_ganancia} />
                                </td>
                                <td className="p-1 paragraph gris text-right">
                                    <RenderCurrency value={renglon.costo_ganancia * renglon.cantidad} />
                                </td>
                            </tr>)
                        })}
                        <tr className={`renglon-${item.renglones.length%2}`}><td className="p-3" colSpan={5} /></tr>
                        <tr className={`renglon-${(item.renglones.length+1)%2}`}>
                            <td className="p-1 paragraph gris" colSpan={3} />
                            <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL COSTOS DEL PROYECTO</div></td>
                            <td className="p-1 pt-2 paragraph gris text-right">
                                <RenderCurrency value={item.total_ganancia} className={"azul"} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Ganancia;
