import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { StandardActions } from "Utils/TableActions";
import { SE } from "utility/variables";
import LoadMask from "Utils/LoadMask";
import Buscador from "Utils/Buscador";


class ListadoPresupuestos extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { loader, data, page, listar, sortChange, search, searchChange, eliminar, me } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                {me.perfil.rol !== SE && (<div className="d-flex align-self-end align-items-center mr-2 mr-md-5 mb-3">
                    <div className="azul paragraph-2 mr-4">
                        Seleccione para agregar!
                    </div>
                    <Link className="btn btn-primary" to="presupuestos/crear">Nuevo Presupuesto</Link>
                </div>)}
                <LoadMask loading={loader} light>
                    <div className="cuadro">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="header-1 azul">PRESUPUESTOS</div>
                            <Buscador search={search} searchChange={searchChange} />
                        </div>
                        <BootstrapTable
                            data={data.results}
                            remote
                            striped
                            bordered={false}
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            pagination
                        >
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                            >
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cliente"
                                dataSort
                                dataFormat={(cell) => cell.nombre}
                            >
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ubicacion"
                                dataSort
                            >
                                Ubicación
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estado"
                                dataSort
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                dataAlign="center"
                                dataFormat={StandardActions({ ver: '/presupuestos', editar: me.perfil.rol !== SE ? '/presupuestos' : undefined, eliminar: me.perfil.rol !== SE ? eliminar : undefined })}
                            />
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default ListadoPresupuestos;
