import React from 'react';
import { Link } from "react-router-dom";
import { Field, FieldArray } from 'redux-form';
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import { renderDatePicker, renderField, renderNumber, renderCurrency, renderAsync } from "Utils/renderField/renderField";
import { getProveedores, getMateriales } from "utility/variables";
import { icons } from "fotos";


const renderDetalle = ({ fields, meta, meta: {error, submitFailed} }) => (
    <div className="d-flex flex-column justify-content-center form-content">
        <div className="d-block d-lg-none">
            {fields.map((material, index) => (
                <div key={index} className={`d-flex renglon-${index%2}`}>
                    <div className="d-flex flex-column justify-content-around align-items-stretch">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${material}.material_id`}>Gasto/Material*</label>
                            {!!(fields.get(index).material) ? (
                                <Field
                                    name={`${material}.material_id`}
                                    clearable={false}
                                    component={renderAsync}
                                    loadOptions={getMateriales}
                                    placeholder="Seleccionar"
                                    valueKey={"id"}
                                    labelKey={"nombre"}
                                />
                                ) : (
                                <Field name={`${material}.gasto`} component={renderField} type="text" className="form-control" />
                            )}
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="cantidad">Unidad*</label>
                            <Field name={`${material}.unidad`} component={renderField} disabled type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="cantidad">Cantidad*</label>
                            <Field name={`${material}.cantidad`} component={renderNumber} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="costo">Costo*</label>
                            <Field name={`${material}.costo`} component={renderCurrency} decimalScale={4} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="subtotal">Sub Total*</label>
                            <Field name={`${material}.subtotal`} disabled component={renderCurrency} decimalScale={4} type="text" className="form-control" />
                        </div>
                    </div>
                    <img
                        src={icons.eliminar2}
                        alt=""
                        className="eliminar"
                        onClick={() => fields.remove(index)}
                    />
                </div>
            ))}
        </div>
        <div className="inner-table">
        <table className="d-none d-lg-block">
            <thead>
            <tr>
                <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "22%" }}>Gasto/Material*</th>
                <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "22%" }}>Unidad*</th>
                <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "15%" }}>Cantidad*</th>
                <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "22%" }}>Precio Unitario*</th>
                <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "22%" }}>Sub Total*</th>
                <th style={{ width: "8%" }} />
            </tr>
            </thead>
            <tbody>
            {fields.map((material, index) => (
                <tr key={index} className={`renglon-${index%2}`}>
                    <td className="p-2">
                        {!!(fields.get(index).material) ? (
                            <Field
                                name={`${material}.material_id`}
                                clearable={false}
                                component={renderAsync}
                                loadOptions={getMateriales}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                            ) : (
                            <Field name={`${material}.gasto`} component={renderField} type="text" className="form-control" />
                        )}
                    </td>
                    <td className="p-2">
                        <Field name={`${material}.unidad`} component={renderField} disabled type="text" className="form-control" /></td>
                    <td className="p-2">
                        <Field name={`${material}.cantidad`} component={renderNumber} type="text" className="form-control" /></td>
                    <td className="p-2">
                        <Field name={`${material}.costo`} component={renderCurrency} decimalScale={4} type="text" className="form-control" /></td>
                    <td className="p-2">
                        <Field name={`${material}.subtotal`} disabled component={renderCurrency} decimalScale={4} type="text" className="form-control" />
                    </td>
                    <td className="p-2">
                        <img
                            src={icons.eliminar2}
                            alt=""
                            className="eliminar"
                            onClick={() => fields.remove(index)}
                        />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>
        {submitFailed && (
            <div className="invalid-feedback d-block pl-4">
                {error}
            </div>
        )}
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start flex-row mt-3 pl-4 pr-4">
            <button type="button" className="btn btn-default paragraph mr-0 mr-md-3 mb-1 mb-md-0" onClick={() => fields.push({material: true})}>
                Agregar material
            </button>
            <button type="button" className="btn btn-default paragraph" onClick={() => fields.push({material: false, cantidad: 1})}>
                Agregar gasto
            </button>
        </div>
    </div>
);


export const Form = (props) => {
    const { handleSubmit, titulo, regreso } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="proveedor">Proveedor*</label>
                            <Field
                                name="proveedor"
                                clearable={false}
                                component={renderAsync}
                                loadOptions={getProveedores}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="fecha">Fecha*</label>
                            <Field
                                name="fecha"
                                component={renderDatePicker}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="descripcion">Descripción*</label>
                            <Field
                                name="descripcion"
                                component={renderField}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-container">
                    <FieldArray
                        name="detalle"
                        component={renderDetalle}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-end flex-row mt-3 pr-3">
               <Link to={regreso} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
            </div>
        </form>
    );
};
