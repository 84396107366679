import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/presupuestos/presupuestos';
import ListadoPresupuestos from './ListadoPresupuestos';


const ms2p = (state) => {
    return {
        ...state.presupuestos,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoPresupuestos);
