import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/proyectos/proyectos';
import OrdenCompra from './OrdenCompra';


const ms2p = (state) => {
    try {
        const crearMaterialForm = state.form["CrearMaterialForm"].values;
        crearMaterialForm.detalle.forEach((detalle) => {
            detalle.unidad = detalle.material_id ? detalle.material_id.unidad : "Unidad";
            try{
                detalle.subtotal = detalle.cantidad * detalle.costo;
            } catch (e) {
            }
        });
    } catch (e) { }
    return {
        ...state.proyectos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(OrdenCompra);
