import React from 'react';
import { Field } from 'redux-form';
import { renderField, renderSelectField, renderAsync, renderTextArea } from "Utils/renderField/renderField";
import { UNIDAD_MINIMA } from "utility/variables";
import { Link } from "react-router-dom";
import { getClientes } from "utility/variables";


export const Form = (props) => {
    const { handleSubmit, titulo, crearCliente, borrador } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field disabled={!borrador} name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="cliente">Cliente*</label>
                            <Field
                                disabled={!borrador}
                                name="cliente"
                                clearable={false}
                                component={renderAsync}
                                loadOptions={getClientes}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="ubicacion">Ubicación*</label>
                            <Field disabled={!borrador} name="ubicacion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end flex-row mt-3 pr-3 pl-3 mb-3">
                {borrador && (<button type="button" onClick={crearCliente} className="btn d-none d-md-block btn-sm btn-secondary mr-auto">Crear nuevo cliente</button>)}
                <Link to={window.location.href.includes("presupuesto") ? "/presupuestos" : "/proyectos"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
            </div>
        </form>
    );
};
