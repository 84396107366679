import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from "Utils/LoadMask";
import Resumen from "../../../Proyectos/EncabezadoProyecto";
import {MATERIAL} from "utility/variables";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";


class DetalleOrden extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    render() {
        const { loader, item, match } = this.props;
        const { fases } = item;
        const fase = _.find(fases, { id: parseInt(match.params.fase) }) ? _.find(fases, { id: parseInt(match.params.fase) }) : {ordenes: [], detalle: []};
        const materiales = [];
        fase.ordenes.forEach((orden) => {
            orden.detalle.forEach((detalle) => {
                let pagado = 0;
                if (orden.pagado)
                    pagado = parseFloat(detalle.total);
                if (detalle.tipo === "MATERIAL") {
                    const material =_.find(materiales, {id: detalle.material});
                    if (!material) {
                        materiales.push({...detalle, comprado: parseFloat(detalle.cantidad), pagado, cantidad: 0, id: detalle.material });
                    } else {
                        material.comprado = material.comprado + parseFloat(detalle.cantidad);
                        material.pagado = material.pagado += parseFloat(detalle.total);
                    }
                } else {
                    const material = _.find(materiales, { nombre: detalle.nombre_material});
                    if (!material) {
                        materiales.push({...detalle, comprado: parseFloat(detalle.cantidad), pagado, cantidad: 0, id: detalle.material });
                    } else {
                        material.comprado = material.comprado + parseFloat(detalle.cantidad);
                        material.pagado = material.pagado += parseFloat(detalle.total);
                    }
                }
            });
        });
        fase.detalle.forEach((detallado) => {
            detallado.renglon.detalle.forEach((detalle) => {
                const material = _.find(materiales, {id: detalle.material});
                const cantidad = (parseFloat(detalle.cantidad) * (1 + detalle.porcentaje_imprevisto/100)) * detallado.cantidad;
                if (material) {
                    material.cantidad = material.cantidad + cantidad;
                    material.costo = detalle.costo;
                } else {
                    materiales.push({...detalle, cantidad, tipo: MATERIAL, id: detalle.material});
                }
            });
        });
        materiales.forEach((material) => {
            material.presupuesto = material.costo * material.cantidad;
        });
        const options = _.cloneDeep(tableOptions);

        return (
            <div id="DetalleOrden" className="d-flex flex-column">
                <LoadMask loading={loader} light>
                    <div className="form-head small-font">Versus</div>
                    <BootstrapTable
                        data={materiales}
                        striped
                        bordered={false}
                        hover
                        options={options}
                    >
                        <TableHeaderColumn
                            dataField="nombre_material"
                            dataSort
                            dataFormat={(cell, row) => {
                                if (!!row.gasto)
                                    return row.gasto;
                                return `${row.nombre_material} ${row.unidad_material}`;
                            }}
                        >
                            Material
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                            dataSort
                            dataAlign="right"
                        >
                            Cantidad Presupuestada
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="comprado"
                            dataFormat={(cell, row) => <RenderNumero value={cell} className={((row.cantidad > cell) || cell === undefined) ? "azul" : "rojo"} decimalScale={4} />}
                            dataSort
                            dataAlign="right"
                        >
                            Cantidad Comprada
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="presupuesto"
                            dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                            dataSort
                            dataAlign="right"
                        >
                            Monto Presupuestado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pagado"
                            dataFormat={(cell, row) => <RenderMoneda monto={cell} className={((row.presupuesto > cell) || cell === undefined) ? "azul" : "rojo"} />}
                            dataSort
                            dataAlign="right"
                        >
                            Monto Pagado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            width={"0%"}
                        />
                    </BootstrapTable>

                </LoadMask>
            </div>
        );
    }
}

export default DetalleOrden;
