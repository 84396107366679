import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { RenderDate } from "Utils/renderField/renderReadField";
import NuevoPagoForm from "./NuevoPagoForm";
import { SE } from "utility/variables";


class Pagos extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        orden: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {nuevoPago: false};
    }

    cancelar = () => {
        this.setState({nuevoPago: false});
    };

    registrarPago = (data) => {
        const { registrarPago } = this.props;
        registrarPago(data);
        this.setState({nuevoPago: false});
    };

    render() {
        const { orden, me } = this.props;

        const { nuevoPago } = this.state;

        const options = _.cloneDeep(tableOptions);

        return (
            <React.Fragment>
                {nuevoPago ? (
                    <NuevoPagoForm cancelar={this.cancelar} onSubmit={this.registrarPago} />
                ) : (
                    me.perfil.rol !== SE && (<button onClick={() => {this.setState({nuevoPago: true})}} className="btn btn-default paragraph ml-1 m-md-3">Agregar Pago</button>)
                )}
                <div className="mb-4">
                    <BootstrapTable
                        data={orden.pagos}
                        striped
                        expandableRow={() => true}
                        expandComponent={(row) => row.observaciones}
                        bordered={false}
                        hover
                        options={options}
                    >
                        <TableHeaderColumn
                            dataField="usuario"
                            dataSort
                        >
                            Responsable
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataFormat={(cell) => <RenderDate value={cell} />}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="forma_pago"
                            dataSort
                        >
                            Forma de Pago
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="documento"
                            dataSort
                        >
                            Documento
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="transaccion"
                            dataSort
                        >
                            Transacción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                            dataSort
                            dataAlign="right"
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            width={"0%"}
                        />
                    </BootstrapTable>
                </div>
            </React.Fragment>
        );
    }
}

export default Pagos;
