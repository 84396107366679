import React from 'react';
import { reduxForm } from 'redux-form';
import { renderField, renderNumber, renderPercentege, renderAsync } from "Utils/renderField/renderField";
import { getMateriales, materiales } from "utility/variables";
import { api } from "api";
import { icons } from "fotos";
import validate from "../validacion";
import { Form } from "../Form";

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'CrearConcretosForm',
    validate,
})(Form);

export default CrearForm;
