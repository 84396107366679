import {handleActions} from 'redux-actions';
import _ from "lodash";
import uuidv1 from "uuid/v1";
import {push} from "react-router-redux";
import {initialize as initializeForm} from 'redux-form';
import {api, updateExpiracion} from "api";
import {materiales, UNIDAD, UNIDAD_MINIMA} from "utility/variables";
import {NotificationManager} from "react-notifications";
import {
    getLongitudesBarras,
    getLongitudesEstribos,
} from "../../../common/components/Plantillas/CrearEspecial/funciones";


// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'PLANTILLAS_LOADER';
const DATA = 'PLANTILLAS_DATA';
const ITEM = 'PLANTILLAS_ITEM';
const PAGE = 'PLANTILLAS_PAGE';
const ORDERING = 'PLANTILLAS_ORDERING';
const SEARCH = 'PLANTILLAS_SEARCH';
const UUID = 'PLANTILLAS_UUID';

export const constants = {
};

const endpoint = "plantillas";
const endpoint_especiales = "plantillas_especiales";
export const crearForm = "CrearPlantillasForm";
export const crearEspecialForm = "CrearPlantillaEspecialForm";
export const editarEspecialForm = "EditarPlantillaEspecialForm";
export const editarForm = "EditarPlantillasForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { plantillas } = getStore();
    const params = { page };
    params.ordering = plantillas.ordering;
    params.search = plantillas.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().plantillas.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(setItem(response));
        const form = _.cloneDeep(response);
        form.detalle.forEach((item) => {
            item.material_id = { ...item.material, value: item.material.id, label: item.material.nombre };
        });
        dispatch(initializeForm(editarForm, form));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerEspecial = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint_especiales}/${id}`).then((response) => {
        dispatch(setItem(response));
        const form = JSON.parse(response.edicion);
        // form.longitudes = JSON.parse(form.longitudes);
        dispatch(initializeForm(editarEspecialForm, form));
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    form.detalle.forEach((item) => {
        try {
            if (item.material_id.id)
                item.material_id = item.material_id.id;
        } catch (e) {
        }
    });
    api.post(endpoint, form).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/plantillas"));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crearEspecial = data => (dispatch) => {
    // dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    const {
        sep_conf,
        sep_nconf,
        alto,
        ancho,
        largo,
        long_gancho,
        recubrimiento,
        longitudes,
        bastones_sup,
        long_baston_sup,
        bastones_inf,
        long_baston_inf,
        cant_barras,
        separacion_a,
        separacion_b,
        espacio_conf,
    } = form;
    form.concreto = form.concreto.id;

    if (form.no_barras)
        form.no_barras.forEach((item) => {
            item.unidad_minima = _.find(UNIDAD_MINIMA, { value: UNIDAD }).value;
        });
    form.longitudes = JSON.stringify(form.longitudes);

    const empalmes = [];
    if (cant_barras)
    cant_barras.forEach((_cant_barras) => {
        empalmes.push(_cant_barras.empalme);
    });

    // COLUMNA
    const confinado = parseFloat(espacio_conf);
    let no_confinado = 0;
    if (alto > largo)
        no_confinado = (!!alto && !!espacio_conf) ? alto - confinado : 0;
    else
        no_confinado = (!!largo && !!espacio_conf) ? largo - confinado : 0;
    let est_conf = !!parseFloat(sep_conf) ? confinado / sep_conf : 0;
    let est_nconf = !!parseFloat(sep_nconf) ? no_confinado / sep_nconf : 0;
    est_conf = Math.ceil(est_conf);
    est_nconf = Math.ceil(est_nconf);
    const estribos = est_conf + est_nconf;
    let long_estribos = (largo - recubrimiento) * 2 + (largo - recubrimiento) * 2 + long_gancho * 2;
    if (alto < largo)
        long_estribos = (alto - recubrimiento) * 2 + (largo - recubrimiento) * 2 + long_gancho * 2;
    const barras_estribos = getLongitudesEstribos(longitudes.map((item) => item.value), long_estribos, estribos);  // cantidad de barras necesarias y su longitud para los estribos
    const barras = getLongitudesBarras(longitudes.map((item) => item.value), alto, empalmes);  // cantidad de barras necesarias y su longitud para las barras de columnas vigas y cimientos corridos
    // VIGA

    const bast_sup = getLongitudesEstribos(longitudes.map((item) => item.value), long_baston_sup, bastones_sup);
    const bast_inf = getLongitudesEstribos(longitudes.map((item) => item.value), long_baston_inf, bastones_inf);

    // ZAPATA

    const _recubrimiento = !!parseFloat(recubrimiento) ? parseFloat(recubrimiento) : 0;
    let lado_a = !!parseFloat(ancho) ? (ancho - (_recubrimiento * 2)) / separacion_a : 0;
    let lado_b = !!parseFloat(largo) ? (largo - (_recubrimiento * 2)) / separacion_b : 0;
    lado_a = Math.ceil(lado_a);
    lado_b = Math.ceil(lado_b);
    const barras_lado_a = getLongitudesEstribos(longitudes.map((item) => item.value), (ancho - (_recubrimiento * 2)), lado_a);  // cantidad de barras necesarias y su longitud para una cama lado a
    const barras_lado_b = getLongitudesEstribos(longitudes.map((item) => item.value), (largo - (_recubrimiento * 2)), lado_b);  // cantidad de barras necesarias y su longitud para una cama lado b

    form.barras_estribos = barras_estribos;
    form.barras = barras;
    form.barras_lado_a = barras_lado_a;
    form.barras_lado_b = barras_lado_b;
    form.bast_sup = bast_sup;
    form.bast_inf = bast_inf;

    // PARA LOS MATERIALES ADICIONALES
    if (!form.detalle)
        form.detalle = [];
    form.detalle.forEach((item) => {
        try {
            if (item.material_id.id)
                item.material_id = item.material_id.id;
        } catch (e) {
        }
    });

    dispatch(setLoader(true));
    form.edicion = JSON.stringify(data);
    api.post(endpoint_especiales, form).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/plantillas"));
    }).catch((error) => {
        if (error.detail)
            NotificationManager.error(error.detail, 'ERROR', 0);
        else
            NotificationManager.error('Error en la creación', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editarEspecial = (data, id) => (dispatch) => {
    // dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    const {
        sep_conf,
        sep_nconf,
        alto,
        ancho,
        largo,
        long_gancho,
        recubrimiento,
        longitudes,
        bastones_sup,
        long_baston_sup,
        bastones_inf,
        long_baston_inf,
        cant_barras,
        separacion_a,
        separacion_b,
        espacio_conf,
    } = form;
    form.concreto = form.concreto.id;

    if (form.no_barras)
        form.no_barras.forEach((item) => {
            item.unidad_minima = _.find(UNIDAD_MINIMA, { value: UNIDAD }).value;
        });
    form.longitudes = JSON.stringify(form.longitudes);

    const empalmes = [];
    if (cant_barras)
    cant_barras.forEach((_cant_barras) => {
        empalmes.push(_cant_barras.empalme);
    });

    // COLUMNA
    const confinado = parseFloat(espacio_conf);
    let no_confinado = 0;
    if (alto > largo)
        no_confinado = (!!alto && !!espacio_conf) ? alto - confinado : 0;
    else
        no_confinado = (!!largo && !!espacio_conf) ? largo - confinado : 0;
    let est_conf = !!parseFloat(sep_conf) ? confinado / sep_conf : 0;
    let est_nconf = !!parseFloat(sep_nconf) ? no_confinado / sep_nconf : 0;
    est_conf = Math.ceil(est_conf);
    est_nconf = Math.ceil(est_nconf);
    const estribos = est_conf + est_nconf;
    let long_estribos = (largo - recubrimiento) * 2 + (largo - recubrimiento) * 2 + long_gancho * 2;
    if (alto < largo)
        long_estribos = (alto - recubrimiento) * 2 + (largo - recubrimiento) * 2 + long_gancho * 2;
    const barras_estribos = getLongitudesEstribos(longitudes.map((item) => item.value), long_estribos, estribos);  // cantidad de barras necesarias y su longitud para los estribos
    const barras = getLongitudesBarras(longitudes.map((item) => item.value), alto, empalmes);  // cantidad de barras necesarias y su longitud para las barras de columnas vigas y cimientos corridos
    // VIGA

    const bast_sup = getLongitudesEstribos(longitudes.map((item) => item.value), long_baston_sup, bastones_sup);
    const bast_inf = getLongitudesEstribos(longitudes.map((item) => item.value), long_baston_inf, bastones_inf);

    // ZAPATA

    const _recubrimiento = !!parseFloat(recubrimiento) ? parseFloat(recubrimiento) : 0;
    let lado_a = !!parseFloat(ancho) ? (ancho - (_recubrimiento * 2)) / separacion_a : 0;
    let lado_b = !!parseFloat(largo) ? (largo - (_recubrimiento * 2)) / separacion_b : 0;
    lado_a = Math.ceil(lado_a);
    lado_b = Math.ceil(lado_b);
    const barras_lado_a = getLongitudesEstribos(longitudes.map((item) => item.value), (ancho - (_recubrimiento * 2)), lado_a);  // cantidad de barras necesarias y su longitud para una cama lado a
    const barras_lado_b = getLongitudesEstribos(longitudes.map((item) => item.value), (largo - (_recubrimiento * 2)), lado_b);  // cantidad de barras necesarias y su longitud para una cama lado b

    form.barras_estribos = barras_estribos;
    form.barras = barras;
    form.barras_lado_a = barras_lado_a;
    form.barras_lado_b = barras_lado_b;
    form.bast_sup = bast_sup;
    form.bast_inf = bast_inf;

    // PARA LOS MATERIALES ADICIONALES
    if (!form.detalle)
        form.detalle = [];
    form.detalle.forEach((item) => {
        try {
            if (item.material_id.id)
                item.material_id = item.material_id.id;
        } catch (e) {
        }
    });

    dispatch(setLoader(true));
    form.edicion = JSON.stringify(data);
    api.put(`${endpoint_especiales}/${id}`, form).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/plantillas"));
    }).catch((error) => {
        if (error.detail)
            NotificationManager.error(error.detail, 'ERROR', 0);
        else
            NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crearMaterial = (data, formulario) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post("materiales", data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        const form = getStore().form[formulario].values;
        if (form) {
            const plantilla = _.cloneDeep(form);
            if (plantilla.detalle) {
                plantilla.detalle.push({material_id: response, unidad: response.unidad});
            } else {
                plantilla.detalle = [{material_id: response, unidad: response.unidad}];
            }
            dispatch(initializeForm(formulario, plantilla));
        } else {
            const plantilla = {detalle: [{material_id: response, unidad: response.unidad}]};
            dispatch(initializeForm(formulario, plantilla));
        }
    }).catch((e) => {
        console.log(e);
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const materialChange = (data, nombre) => (dispatch, getStore) => {
    const form = _.cloneDeep(getStore().form[nombre].values);
    const material = _.find(materiales, { id: data.value });
    if (material) {
        const _materiales = form.materiales;
        const item = _.find(_materiales, { nombre: data });
        item.unidad = material.unidad;
        item.unidad_minima = _.find(UNIDAD_MINIMA, { label: material.unidad_minima });
        dispatch(initializeForm(nombre, form));
    }
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    form.detalle.forEach((item) => {
        try {
            if (item.material_id.id)
                item.material_id = item.material_id.id;
        } catch (e) {
        }
    });
    api.put(`${endpoint}/${id}`, form).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/plantillas"));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().plantillas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    leer,
    leerEspecial,
    crear,
    crearEspecial,
    editarEspecial,
    editar,
    eliminar,
    searchChange,
    sortChange,
    materialChange,
    crearMaterial,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    uuid: '',
};

export default handleActions(reducers, initialState);
