import React from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { Link } from "react-router-dom";
import { TIPO_MOVIMIENTO } from "utility/variables";
import { editarForm } from "../../../../redux/modules/maquinaria_vehiculos/vehiculo";
import { icons } from "fotos";
import {
    renderField,
    renderTextArea,
    renderCheckBox,
    renderDatePicker,
    renderSelectField,
    renderCurrency,
} from "Utils/renderField/renderField";


export const renderGastos = ({ fields, meta: {error, submitFailed} }) => (
    <div className="d-flex flex-column justify-content-center form-content">
        <div className="d-block d-lg-none">
            {fields.map((vehiculo, index) => (
                <div className="d-flex" key={index}>
                    <div className="d-flex flex-column justify-content-around align-items-stretch">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="tipo">Tipo*</label>
                            <Field
                                name={`${vehiculo}.tipo`}
                                component={renderSelectField}
                                options={TIPO_MOVIMIENTO}
                                labelKey="label"
                                valueKey="value"
                                clearable={false}
                                placeholder="Seleccionar"
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${vehiculo}.descripcion`}>Descripción*</label>
                            <Field name={`${vehiculo}.descripcion`} component={renderField} type="text" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${vehiculo}.monto`}>Monto*</label>
                            <Field name={`${vehiculo}.monto`} component={renderCurrency} decimalScale={2} type="text" className="form-control" />
                        </div>
                    </div>
                    <img
                        src={icons.eliminar2}
                        alt=""
                        className="eliminar"
                        onClick={() => fields.remove(index)}
                    />
                </div>
            ))}
        </div>
        <table className="d-none d-lg-block">
            <tbody>
            <tr>
                <th className="pr-4 pl-4" style={{ width: "23%" }}>Tipo*</th>
                <th className="pr-4 pl-4" style={{ width: "22%" }}>Descripción*</th>
                <th className="pr-4 pl-4" style={{ width: "23%" }}>Monto*</th>
                <th style={{ width: "8%" }} />
            </tr>
            {fields.map((vehiculo, index) => (
                <tr key={index}>
                    <td className="pr-4 pl-4 pt-2">
                        <Field
                            name={`${vehiculo}.tipo`}
                            component={renderSelectField}
                            options={TIPO_MOVIMIENTO}
                            labelKey="label"
                            valueKey="value"
                            clearable={false}
                            placeholder="Seleccionar"
                        />
                    </td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${vehiculo}.descripcion`} component={renderField} type="text" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${vehiculo}.monto`} component={renderCurrency} decimalScale={2} type="text" className="form-control" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <img
                            src={icons.eliminar2}
                            alt=""
                            className="eliminar"
                            onClick={() => fields.remove(index)}
                        />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        {submitFailed && (
            <div className="invalid-feedback d-block pl-4">
                {error}
            </div>
        )}
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start flex-row mt-3 pl-4 pr-4">
            <button type="button" className="btn btn-default" onClick={() => fields.push()}>
                Agregar detalle
            </button>
        </div>
    </div>
);


const Form = (props) => {
    const { handleSubmit, titulo, item } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field name="nombre" disabled component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="placa">Placa*</label>
                            <Field name="placa" disabled component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="descripcion">Descripción</label>
                            <Field
                                disabled
                                name="descripcion"
                                component={renderTextArea}
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="foto">Foto*</label>
                            <img src={item.foto} alt="" className="mw-100"/>
                        </div>
                    </div>
                    {/*<div className="">
                        <div className="header-2 azul my-2">Reportar Gastos</div>
                        <div className="d-flex flex-column flex-md-row justify-content-around">
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="fecha">Fecha*</label>
                                <Field
                                    name="fecha"
                                    component={renderDatePicker}
                                />
                            </div>
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" />
                        </div>
                        <FieldArray
                            name="detalle"
                            component={renderGastos}
                        />
                    </div>*/}
                    {/*<div className="d-flex form-group m-1 px-2 px-md-2 flex-1 align-items-center">
                        <div className="header-2 azul my-2">Dar de baja&nbsp;</div>
                        <Field name="dar_baja" component={renderCheckBox} />
                    </div>*/}
                    <div className="d-flex flex-column form-group m-1 px-2 px-md-2 flex-1">
                        <label htmlFor="motivo">Motivo</label>
                        <Field
                            name="motivo"
                            component={renderTextArea}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end flex-row pr-3">
                <Link to={"/vehiculo"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
            </div>
        </form>
    );
};


const EditarForm = reduxForm({
    // a unique name for the form
    form: editarForm,
    validate: (data) => {
        let motivo;
        if (data.dar_baja) {
            motivo = validators.exists()("Campo requerido");
        }
        return validate(data, {
            motivo,
        });
    },
})(Form);

export default EditarForm;
