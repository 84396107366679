import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment/moment";
import {NotificationManager} from "react-notifications";
import _ from "lodash";
import LoadMask from "Utils/LoadMask";
import NumberFormat from 'react-number-format';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { Link } from "react-router-dom";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import Resumen from "../../../../Presupuestos/ResumenProyecto";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";


class EditarFase extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {fase: undefined, elegidos: [], tab: "1", fecha_inicio: moment(new Date()).format("YYYY-MM-DD")};
    }


    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id, match.params.fase);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.fase.id && this.state.fase === undefined) {
            const { fase } = nextProps;
            fase.detalle.forEach(detalle => {
                detalle.value = _.clone(detalle.cantidad);
                detalle.nombre = _.clone(detalle.renglon.nombre);
                detalle.detalle_id = _.clone(detalle.id);
                detalle.id = _.clone(detalle.renglon_id);
            });
            this.setState({
                fase,
                elegidos: fase.detalle,
            })
        }
    }

    toggleSeleccionado(item) {
        const temp = _.find(this.state.elegidos, {id: item.id});
        if (temp) {
            const index = this.state.elegidos.indexOf(temp);
            const elegidos = this.state.elegidos;
            elegidos.splice(index, 1);
            this.setState({elegidos});
        } else {
            this.setState({elegidos: [...this.state.elegidos, item]});
        }
    }

    cambioCantidad = (value, id) => {
        const item = _.find(this.state.elegidos, {id});
        item.value = value;
        const state = _.cloneDeep(this.state);
        this.setState({...state});
    };

    /**
     * Funcion para sumar la cantidad de renglones ya ingresados para las fases anteriores
     * @param id: int: id del renglon que se está viendo
     * @return: int: cantidad de renglones ya ingresados anteriormente
     * **/
    cantidadAnterior = (id) => {
        const { item: { fases } } = this.props;
        let cantidad = 0;
        fases.forEach((fase) => {
            _.filter(fase.detalle, {renglon_id: id}).forEach((result) => {
                cantidad += parseFloat(result.cantidad);
            });
        });
        return cantidad;
    };

    elegido(id) {
        return !!_.find(this.state.elegidos, {id});
    }

    render() {
        const { loader, item, match, editarFase } = this.props;
        const { tab, elegidos, fecha_inicio } = this.state;

        const { renglones } = item;

        const _renglones = [];
        renglones.forEach((renglon) => {
            if (this.cantidadAnterior(renglon.id) < parseFloat(renglon.cantidad)) {
                _renglones.push(renglon);
            }
        });
        const options = _.cloneDeep(tableOptions);

        return (
            <div id="CrearFase" className="p-2 p-md-4 d-flex flex-column">
                <LoadMask loading={loader} light>
                    <div className="cuadro">
                        <div className="d-flex flex-column">
                            <div className="form-head small-font">EDITAR FASE</div>
                            <div className="border">
                                <Resumen item={item} />
                            </div>
                        </div>
                        <Tabs
                            activeKey={tab}
                            onChange={this.tabChange}
                            renderTabBar={()=><ScrollableInkTabBar />}
                            renderTabContent={()=><TabContent />}
                        >
                            <TabPane tab="" key="1" >
                                <div className="inner-table">
                                    <BootstrapTable
                                        data={_renglones}
                                        remote
                                        striped
                                        bordered={false}
                                        hover
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="nombre"
                                            dataSort
                                        >
                                            RENGLÓN
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="cantidad"
                                            dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            CANTIDAD TOTAL
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataFormat={(cell) => <RenderNumero value={this.cantidadAnterior(cell)} decimalScale={4} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            FASES ANTERIORES
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="costo"
                                            dataFormat={(cell) => <RenderMoneda monto={cell} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            COSTO UNITARIO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            dataAlign="center"
                                            dataFormat={
                                                (cell, row) => {
                                                    return (
                                                        <div key={cell} className="checkbox c-checkbox">
                                                            <label className="needsclick">
                                                                <input type="checkbox" checked={this.elegido(row.id)} onChange={() => this.toggleSeleccionado(row)} />
                                                                <span className="fa fa-check" />
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                            }
                                        >
                                            ¿AGREGAR?
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </div>
                                <div className="d-flex justify-content-end flex-row mt-3 pr-3 pl-3 mb-3">
                                    <Link to={`/proyectos/${match.params.id}/fases/${match.params.fase}`} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    <button onClick={() => {
                                        if (elegidos.length > 0)
                                            this.setState({tab: "2"});
                                        else
                                            NotificationManager.error('Seleccione al menos un renglón', 'ERROR');
                                    }} className="btn btn-primary ml-1">Siguiente</button>
                                </div>
                            </TabPane>
                            <TabPane tab="" key="2">
                                <div className="inner-table">
                                    <BootstrapTable
                                        data={elegidos}
                                        remote
                                        striped
                                        bordered={false}
                                        hover
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="nombre"
                                            dataSort
                                        >
                                            RENGLÓN
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="cantidad"
                                            dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            CANTIDAD TOTAL
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataFormat={(cell) => <RenderNumero value={this.cantidadAnterior(cell)} decimalScale={4} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            FASES ANTERIORES
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="costo"
                                            dataFormat={(cell) => <RenderMoneda monto={cell} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            COSTO UNITARIO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            dataAlign="right"
                                            dataFormat={
                                                (cell, row) => {
                                                    return (
                                                        <div className="pl-4">
                                                            <NumberFormat
                                                                className={'form-control paragraph gris'}
                                                                decimalScale={4}
                                                                value={row.value}
                                                                thousandSeparator
                                                                prefix=""
                                                                onValueChange={(values) => {
                                                                    this.cambioCantidad(values.value, cell);
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            }
                                        >
                                            CANTIDAD
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="costo"
                                            dataFormat={(cell, row) => {
                                                return <RenderMoneda monto={cell * row.value} />}}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            SUB TOTAL
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </div>
                                <div className="d-flex justify-content-end flex-row mt-3 pr-3 pl-3 mb-3">
                                    <button onClick={() => {this.setState({tab: "1"})}} className="btn btn-secondary ml-1 mr-1">Regresar</button>
                                    <button onClick={() => editarFase(elegidos, fecha_inicio, match.params.id, match.params.fase)} className="btn btn-primary ml-1">Guardar</button>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default EditarFase;
