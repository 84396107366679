import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/maquinaria_vehiculos/vehiculo';
import CrearVehiculo from './CrearVehiculo';
import { crearForm } from "../../../../redux/modules/maquinaria_vehiculos/vehiculo";


const ms2p = (state) => {
    let foto;
    try {
        foto = state.form[crearForm].values.foto;
    } catch (e) {
    }
    return {
        ...state.vehiculo,
        foto
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearVehiculo);
