import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import EditarForm from "./EditarForm";
import Renglones from "./Renglones/Renglones";
import Responsable from "./Responsable";
import Costos from "./Costos";
import CrearCliente from "../../Clientes/Crear/CrearForm";


class EditarPresupuesto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { openResponsable: false, open: false};
    }

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    openModalResponsable = () => {
        const { inicialResponsable } = this.props;
        inicialResponsable();
        this.setState({openResponsable: true});
    };

    closeModalResponsable = () => {
        this.setState({ openResponsable: false });
    };

    openModal = () => {
        this.setState({open: true});
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    crearCliente = (data) => {
        const { crearCliente } = this.props;
        crearCliente(data, "EditarPresupuestoForm");
        this.setState({ open: false });
    };

    activar = (data) => {
        const { activar, match } = this.props;
        activar(match.params.id, data);
    };

    editar = (actualizar) => {
        const { editar } = this.props;
        const { match } = this.props;
        editar(match.params.id, actualizar);
    };

    render() {
        const { loader, tab, tabChange, item, match, descargar } = this.props;
        const { open, openResponsable } = this.state;

        const borrador = item.estado === "Borrador";

        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <button onClick={() => descargar(match.params.id)} className="btn btn-default paragraph ml-0 ml-md-auto mb-2">Descargar Excel</button>
                <div className="cuadro">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                        <div className="header-1 azul mb-2 mr-2">{borrador ? 'PRESUPUESTO' : 'PROYECTO'}</div>
                        <div className="paragraph gris mb-2">{item.nombre}</div>
                        {borrador && (<button onClick={this.openModalResponsable} className="btn btn-default paragraph ml-0 ml-md-auto mb-2 mb-md-0">Pasar a proyecto activo</button>)}
                    </div>
                    <Tabs
                        activeKey={tab}
                        onChange={tabChange}
                        renderTabBar={()=><ScrollableInkTabBar />}
                        renderTabContent={()=><TabContent />}
                    >
                        <TabPane tab="Datos Base" key="1" >
                            <LoadMask loading={loader} light>
                                <EditarForm onSubmit={this.editar} titulo={"Datos Base"} crearCliente={this.openModal} borrador={borrador} />
                            </LoadMask>
                        </TabPane>
                        <TabPane tab="Renglones" key="2">
                            <LoadMask loading={loader} light>
                                <Renglones {...this.props} guardar={this.editar} />
                            </LoadMask>
                        </TabPane>
                        <TabPane tab="Costos" key="3">
                            <LoadMask loading={loader} light>
                                <Costos {...this.props} guardar={this.editar} />
                            </LoadMask>
                        </TabPane>
                    </Tabs>
                    <Modal open={openResponsable} onClose={this.closeModalResponsable} center showCloseIcon={false} classNames={{modal: "rr-modal"}}>
                        <Responsable cancelar={this.closeModalResponsable} onSubmit={this.activar} />
                    </Modal>
                    <Modal open={open} onClose={this.closeModal} center showCloseIcon={false} classNames={{modal: "rr-modal"}}>
                        <CrearCliente onSubmit={this.crearCliente} titulo="Crear Cliente" regreso={this.closeModal} />
                    </Modal>
                </div>
            </div>
        );
    }
}

export default EditarPresupuesto;
