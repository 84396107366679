import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import EditarForm from "./EditarForm";


class EditarBarra extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    editar = (data) => {
        const { editar } = this.props;
        const { match } = this.props;
        editar(match.params.id, data);
    };

    render() {
        const { loader } = this.props;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <EditarForm onSubmit={this.editar} titulo={"Editar Barra"} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default EditarBarra;
