import { handleActions } from 'redux-actions';
import uuidv1 from "uuid/v1";
import moment from "moment";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api, updateExpiracion } from "api";
import { NotificationManager } from "react-notifications";


// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'MAQUINARIA_LOADER';
const LOADER_GASTOS = 'MAQUINARIA_LOADER_GASTOS';
const DATA = 'MAQUINARIA_DATA';
const GASTOS = 'MAQUINARIA_GASTOS';
const ITEM = 'MAQUINARIA_ITEM';
const PAGE = 'MAQUINARIA_PAGE';
const ORDERING = 'MAQUINARIA_ORDERING';
const SEARCH = 'MAQUINARIA_SEARCH';
const UUID = 'MAQUINARIA_UUID';

const SALDO = 'MAQUINARIA_SALDO';
const PAGE_GASTOS = 'MAQUINARIA_PAGE_GASTOS';
const ORDERING_GASTOS = 'MAQUINARIA_ORDERING_GASTOS';
const SEARCH_GASTOS = 'MAQUINARIA_SEARCH_GASTOS';
const UUID_GASTOS = 'MAQUINARIA_UUID_GASTOS';
const FECHA_INICIAL = 'MAQUINARIA_FECHA_INICIAL';
const FECHA_FINAL = 'MAQUINARIA_FECHA_FINAL';

export const constants = {
};

const endpoint = "maquinaria";
export const crearForm = "CrearMaquinariaForm";
export const editarForm = "EditarMaquinariaForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// GASTOS
export const setLoaderGastos = loaderGastos => ({
    type: LOADER_GASTOS,
    loaderGastos,
});

export const setDataGastos = gastos => ({
    type: GASTOS,
    gastos,
});

export const setSaldo = saldo => ({
    type: SALDO,
    saldo,
});

export const setPageGastos = pageGastos => ({
    type: PAGE_GASTOS,
    pageGastos,
});

export const setOrderingGastos = orderingGastos => ({
    type: ORDERING_GASTOS,
    orderingGastos,
});

export const setSearchGastos = searchGastos => ({
    type: SEARCH_GASTOS,
    searchGastos,
});

export const setUuidGastos = uuidGastos => ({
    type: UUID_GASTOS,
    uuidGastos,
});

export const setFechaInicial = fechaInicial => ({
    type: FECHA_INICIAL,
    fechaInicial,
});

export const setFechaFinal = fechaFinal => ({
    type: FECHA_FINAL,
    fechaFinal,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { maquinaria } = getStore();
    const params = { page };
    params.ordering = maquinaria.ordering;
    params.search = maquinaria.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().maquinaria.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarGastos = (page = 1, id) => (dispatch, getStore) => {
    const { maquinaria } = getStore();
    const params = { page };
    params.ordering = maquinaria.orderingGastos;
    params.search = maquinaria.searchGastos;
    params.fecha_inicial = maquinaria.fechaInicial;
    params.fecha_final = maquinaria.fechaFinal;
    params.alquiler__maquinaria_id = id ? id : maquinaria.item.id;
    dispatch(setLoaderGastos(true));
    const uuid = uuidv1();
    dispatch(setUuidGastos(uuid));
    api.get("gastos_maquinaria", params).then((response) => {
        const realUuid = getStore().maquinaria.uuidGastos;
        if (uuid === realUuid) {
            api.get("gastos_maquinaria/saldo", params).then(saldo => {
                dispatch(setSaldo(saldo));
            }).catch(() => {});
            dispatch(setDataGastos(response));
            dispatch(setPageGastos(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderGastos(false));
    });
};

export const setDate = (value, key) => (dispatch) => {
    if (key === "inicial")
        dispatch(setFechaInicial(value));
    else
        dispatch(setFechaFinal(value));
    dispatch(listarGastos());
};

export const searchChangeGastos = search => (dispatch) => {
    dispatch(setSearchGastos(search));
    dispatch(listarGastos());
};

export const sortChangeGastos = ordering => (dispatch, getStore) => {
    const sort = getStore().vehiculo.orderingGastos;
    if (ordering === sort) {
        dispatch(setOrderingGastos(`-${ordering}`));
    } else {
        dispatch(setOrderingGastos(ordering));
    }
    dispatch(listarGastos());
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(initializeForm(editarForm, response));
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = (data, foto) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments(endpoint, data, [foto]).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/maquinaria"));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/maquinaria"));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().maquinaria.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    listarGastos,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    sortChange,
    setDate,
    searchChangeGastos,
    sortChangeGastos,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [LOADER_GASTOS]: (state, { loaderGastos }) => {
        updateExpiracion();
        return {
            ...state,
            loaderGastos,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
    [GASTOS]: (state, { gastos }) => {
        updateExpiracion();
        return {
            ...state,
            gastos,
        };
    },
    [SALDO]: (state, { saldo }) => {
        updateExpiracion();
        return {
            ...state,
            saldo,
        };
    },
    [PAGE_GASTOS]: (state, { pageGastos }) => {
        updateExpiracion();
        return {
            ...state,
            pageGastos,
        };
    },
    [ORDERING_GASTOS]: (state, { orderingGastos }) => {
        updateExpiracion();
        return {
            ...state,
            orderingGastos,
        };
    },
    [SEARCH_GASTOS]: (state, { searchGastos }) => {
        updateExpiracion();
        return {
            ...state,
            searchGastos,
        };
    },
    [UUID_GASTOS]: (state, { uuidGastos }) => {
        updateExpiracion();
        return {
            ...state,
            uuidGastos,
        };
    },
    [FECHA_INICIAL]: (state, { fechaInicial }) => {
        updateExpiracion();
        return {
            ...state,
            fechaInicial,
        };
    },
    [FECHA_FINAL]: (state, { fechaFinal }) => {
        updateExpiracion();
        return {
            ...state,
            fechaFinal,
        };
    },
};

const hoy = new Date();

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    uuid: '',
    fechaInicial: moment(`${hoy.getFullYear()}-${hoy.getMonth()+1}-1`).format('YYYY-MM-DD'),
    fechaFinal: moment().format('YYYY-MM-DD'),

    saldo: {
        ingresos: 0,
        egresos: 0,
    },
    gastos: {
        results: [],
        count: 0,
    },
    pageGastos: 1,
    orderingGastos: '',
    searchGastos: '',
    uuidGastos: '',
};

export default handleActions(reducers, initialState);
