import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import CrearForm from "./CrearForm";


class CrearMaterial extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    render() {
        const { loader, crear } = this.props;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <CrearForm onSubmit={crear} titulo={"Crear Material"} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CrearMaterial;
