import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from "fotos";


class Buscador extends Component {
    static propTypes = {
        search: PropTypes.string.isRequired,
        searchChange: PropTypes.func.isRequired,
    };

    render() {
        const { search, searchChange } = this.props;

        return (
            <div className="toolbar-search">
                <img src={icons.buscar} alt="" />
                <input
                    className="form-control"
                    type="text"
                    placeholder="Buscar"
                    ref={(node) => {
                        this.buscar = node;
                        if (this.buscar) {
                            this.buscar.value = search;
                        }
                    }}
                    onChange={() => {
                        if (this.buscar) {
                            searchChange(this.buscar.value);
                        }
                    }}
                    autoComplete="off"
                />
            </div>
        );
    }
}

export default Buscador;
