import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { getResumenes, getMateriales } from "utility/variables";
import { Async } from "react-select";
import { icons } from "fotos";
import "./renglones.css";


class Renglones extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { index, renglon, cambioDetalle, disabled, agregarDetalle, precioUnitario } = this.props;
        let total = precioUnitario;

        return (
            <div className="d-block d-lg-none">
                {renglon.detalle.map((detalle, sub_index) => {
                    return (<div key={detalle.id} className={`d-flex renglon-${sub_index%2}`}>
                        <div className="d-flex flex-column justify-content-around align-items-stretch">
                            <div className="d-flex flex-column m-1 pl-2 pr-2 ">
                                <div className="paragraph gris">MATERIAL</div>
                                <Async
                                    disabled={disabled}
                                    clearable={false}
                                    value={detalle.material}
                                    className="react-select-container"
                                    onChange={(e) => { cambioDetalle(index, sub_index, 'material', e) }}
                                    cache={false}
                                    searchPromptText="Escribe para buscar"
                                    valueKey="id"
                                    labelKey="nombre"
                                    loadOptions={getMateriales}
                                />
                            </div>
                            <div className="d-flex flex-column m-1 pl-2 pr-2 ">
                                <div className="paragraph gris">CANTIDAD</div>
                                <NumberFormat
                                    disabled={disabled}
                                    className="form-control"
                                    decimalScale={4}
                                    fixedDecimalScale
                                    value={detalle.cantidad}
                                    thousandSeparator
                                    prefix=""
                                    onValueChange={(values) => { cambioDetalle(index, sub_index, 'cantidad', values.value) }}
                                />
                            </div>
                            <div className="d-flex flex-column m-1 pl-2 pr-2 ">
                                <div className="paragraph gris">IMPREVISTO</div>
                                <NumberFormat
                                    disabled={disabled}
                                    className="form-control"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={detalle.porcentaje_imprevisto}
                                    thousandSeparator
                                    prefix="%"
                                    onValueChange={(values) => { cambioDetalle(index, sub_index, 'porcentaje_imprevisto', values.value) }}
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-between m-1 pl-2 pr-2 ">
                                <div className="paragraph gris">UNIDAD</div>
                                <div className="paragraph-bold azul">{detalle.material.unidad}</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between m-1 pl-2 pr-2 ">
                                <div className="paragraph gris">PRECIO UNITARIO</div>
                                <RenderCurrency value={detalle.costo} className="paragraph-bold azul" />
                            </div>
                        </div>
                    </div>)
                })}
            </div>
        );
    }
}

export default Renglones;
