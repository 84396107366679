import React, { Component } from 'react';
import { RenderDate } from "../Utils/renderField/renderReadField";


class Resumen extends Component {

    render() {
        const { item } = this.props;

        return (
            <div className="d-none d-md-flex flex-column border-bottom justify-content-around pl-2 pr-2 pt-3 pb-3">
                <div className="d-none d-md-flex flex-column flex-md-row justify-content-around ">
                    <div className="d-flex align-items-center">
                        <div className="paragraph gris">Cliente&nbsp;</div>
                        <div className="azul">{item.cliente.nombre}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="paragraph gris">Responsable&nbsp;</div>
                        <div className="azul">{item.responsable.perfil ? item.responsable.perfil.nombre : item.responsable.username}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="paragraph gris">Fecha de Inicio&nbsp;</div>
                        <div className="azul"><RenderDate value={item.fecha_inicio} /></div>
                    </div>
                </div>
                <div className="d-none d-md-flex flex-column flex-md-row justify-content-around ">
                    <div className="d-flex align-items-center">
                        <div className="paragraph gris">Proyecto&nbsp;</div>
                        <div className="azul">{item.nombre}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="paragraph gris">Ubicación&nbsp;</div>
                        <div className="azul">{item.ubicacion}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Resumen;
