import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask";
import EditarForm from "./EditarForm";
import CrearMaterial from "../../Materiales/Crear/CrearForm";
import CrearForm from "../Crear/CrearConcreto";


class EditarConcreto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { open: false, vistaAgregar: false };
    }

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    openModal = () => {
        this.setState({open: true});
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    crearMaterial = (data) => {
        const { crearMaterial } = this.props;
        crearMaterial(data, "EditarConcretosForm");
        this.setState({ open: false });
        this.toggleAgregar();
    };

    toggleAgregar = () => {
        const { vistaAgregar } = this.state;
        this.setState({vistaAgregar: !vistaAgregar});
    };

    editar = (data) => {
        const { editar } = this.props;
        const { match } = this.props;
        editar(match.params.id, data);
    };

    render() {
        const { loader } = this.props;
        const { open, vistaAgregar } = this.state;

        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <EditarForm
                            onSubmit={this.editar}
                            crearMaterial={this.openModal}
                            titulo="Editar Concreto"
                            toggleAgregar={this.toggleAgregar}
                            vistaAgregar={vistaAgregar}
                        />
                        <Modal open={open} onClose={this.closeModal} center  showCloseIcon={false} classNames={{modal: "rr-modal"}}>
                            <CrearMaterial onSubmit={this.crearMaterial} titulo="Crear Material" regreso={this.closeModal} />
                        </Modal>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default EditarConcreto;
