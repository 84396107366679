import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import CrearForm from "./CrearForm";
import _ from "lodash";


class CrearMaterial extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    crear = (data) => {
        const { match, crearOrdenCompra } = this.props;
        crearOrdenCompra(data, match.params.fase, match.params.id);
    };

    render() {
        const { loader, match, item } = this.props;
        const { fases } = item;
        const fase = _.find(fases, { id: parseInt(match.params.fase) }) ? _.find(fases, { id: parseInt(match.params.fase) }) : {};
        let cantRenglones = 0;
        if (!!fase.detalle)
            fase.detalle.forEach((detalle) => cantRenglones += parseFloat(detalle.cantidad));
        const numero = fases.indexOf(fase);
        const { crear } = this;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <div className="header-1 azul">Fase {numero + 1} <span className="paragraph gris">DETALLE</span></div>
                        <CrearForm
                            onSubmit={crear}
                            titulo={"Crear Orden de Compra"}
                            regreso={`/proyectos/${match.params.id}/fases/${match.params.fase}`}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CrearMaterial;
