import React from 'react';
import { reduxForm } from 'redux-form';
import { renderField, renderSelectField, renderTextArea } from "Utils/renderField/renderField";
import { Form } from "../Form";
import validate from '../validacion';


const EditarForm = reduxForm({
    // a unique name for the form
    form: 'EditarProveedoresForm',
    validate
})(Form);

export default EditarForm;
