import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

// maquetado base
import Navbar from "./common/components/Navegacion/Navbar/NavbarContainer";
import Sidebar from "./common/components/Navegacion/Sidebar";

// funciona para determinar si puede acceder a la vista
function isAuthenticated() {
    return sessionStorage.getItem("token");
}

class PrivateRouteBase extends Component {
    componentWillMount() {
        const { props } = this;
        // props.getMe();
    }

    render() {
        const { component: MyComponent, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (isAuthenticated()) {
                        return (
                            <div>
                                <Navbar />
                                <Sidebar />
                                <div className="content-wrapper">
                                    <div className="container">
                                        <MyComponent {...props} />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    );
                }}
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(PrivateRouteBase);

export default ProtectedRoute;
