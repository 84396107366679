import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/materiales/materiales';
import CrearMaterial from './CrearMaterial';


const ms2p = (state) => {
    return {
        ...state.materiales,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearMaterial);
