import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { RenderDate } from "Utils/renderField/renderReadField";
import CobrosForm from "./CobrosForm";
import { SE } from "utility/variables";


class Cobros extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        item: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {nuevoCobro: false};
    }

    cancelar = () => {
        this.setState({nuevoCobro: false});
    };

    registrarCobro = (data) => {
        const { registrarCobro, match } = this.props;
        registrarCobro(data, match.params.id);
        this.setState({nuevoCobro: false});
    };

    render() {
        const { item, me } = this.props;
        const { cobros } = item;
        let total = 0;
        cobros.forEach((cobro) => {
            total += parseFloat(cobro.total);
        });

        const { nuevoCobro } = this.state;

        const options = _.cloneDeep(tableOptions);

        return (
            <div className="cuadro">
                <div className="d-flex flex-column">
                    <div className="form-head small-font">COBROS DEL PROYECTO</div>
                    <div className="d-flex flex-column flex-lg-row p-3 border">
                        <div className="d-flex flex-column flex-md-row flex-1">
                            <div className="flex-1 d-flex justify-content-end align-items-end">
                                <div className="paragraph">Fecha inicio</div>
                                <div className="azul ml-3"><RenderDate value={item.fecha_inicio} /></div>
                            </div>
                            <div className="flex-1 d-flex justify-content-end align-items-end">
                                <div className="paragraph">Total</div>
                                <div className="azul ml-3"><RenderMoneda monto={item.total_ganancia} /></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-1">
                            <div className="flex-1 d-flex justify-content-end align-items-end">
                                <div className="paragraph">Cobrado</div>
                                <div className="azul ml-3"><RenderMoneda monto={total} /></div>
                            </div>
                            <div className="flex-1 d-flex justify-content-end align-items-end">
                                <div className="paragraph">Saldo</div>
                                <div className="verde ml-3">
                                    <RenderMoneda
                                        monto={(parseFloat(item.total_ganancia) - total) > 0 ? (parseFloat(item.total_ganancia) - total) : 0 }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-3">
                    <BootstrapTable
                        data={item.cobros}
                        striped
                        expandableRow={() => true}
                        expandComponent={(row) => row.observaciones}
                        bordered={false}
                        hover
                        options={options}
                    >
                        <TableHeaderColumn
                            dataField="usuario"
                            dataSort
                        >
                            Responsable
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataFormat={(cell) => <RenderDate value={cell} />}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="forma_pago"
                            dataSort
                        >
                            Forma de Cobro
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="documento"
                            dataSort
                        >
                            Documento
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="transaccion"
                            dataSort
                        >
                            Transacción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                            dataSort
                            dataAlign="right"
                        >
                            Total
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            width={"0%"}
                        />
                    </BootstrapTable>
                </div>
                {nuevoCobro ? (
                    <CobrosForm cancelar={this.cancelar} onSubmit={this.registrarCobro} />
                ) : (
                    me.perfil.rol !== SE && <button onClick={() => {this.setState({nuevoCobro: true})}} className="btn btn-default paragraph ml-1 m-md-3">Agregar Cobro</button>
                )}
            </div>
        );
    }
}

export default Cobros;
