import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import { slide as Slide } from 'react-burger-menu';
import './navbar.css';
import './burger-sidebar.css';
import Menu from "../Menu";
import { Link } from "react-router-dom";


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {slideOpen: false, menuOpen: false};
    }

    componentWillMount() {
        const { getMe } = this.props;
        getMe();
    }

    toggleOpen = (slideOpen = false) => {
        this.setState({slideOpen});
    };

    toggleMenu = () => {
        this.setState({menuOpen: !this.state.menuOpen});
    };

    render() {
        const { logOut, me } = this.props;
        const { menuOpen } = this.state;
        const menuOptions = {
            isOpen: menuOpen,
            close: this.toggleMenu,
            closeOnInsideClick: false,
            toggle: <button
                className="btn btn-light m-3 d-flex align-items-center azul text-capitalize"
                style={{minWidth: "165px"}}
                onClick={this.toggleMenu}>
                <span>{me.perfil ? me.perfil.nombre : me.username}</span>
                <em className="fa fa-caret-down ml-auto" />
            </button>,
            align: 'left',
        };

        const { slideOpen } = this.state;
        return (
            <React.Fragment>
                <header className="topnavbar-wrapper">
                    <div className="d-flex justify-content-end align-items-end">
                        <DropdownMenu {...menuOptions}>
                            <div style={{minWidth: "165px"}}>
                                <Link
                                    className="px-3 py-1 mr-3 d-flex justify-content-between align-items-center paragraph gris"
                                    to="/perfil"
                                    onClick={this.toggleMenu}
                                >
                                    Mi Perfil&nbsp;<em className="fa fa-user" />
                                </Link>
                            </div>
                            <div style={{minWidth: "165px"}}>
                                <Link
                                    className="px-3 py-1 mr-3 d-flex justify-content-between align-items-center paragraph gris"
                                    to="/"
                                    onClick={() => {
                                        logOut();
                                        this.toggleMenu();
                                    }}
                                >
                                    Cerrar Sesión&nbsp;<em className="fa fa-lock" />
                                </Link>
                            </div>
                        </DropdownMenu>
                    </div>
                </header>
                <div className="d-block d-md-none">
                    <Slide isOpen={slideOpen} onStateChange={ (state) => this.toggleOpen(state.isOpen) }>
                        <Menu toggleOpen={this.toggleOpen} />
                    </Slide>
                </div>
            </React.Fragment>
        );
    }
}
Navbar.propTypes = {
};

export default Navbar;
