import React from 'react';
import { Field } from 'redux-form';
import { renderField, renderSelectField, renderTextArea, renderNumber } from "Utils/renderField/renderField";
import { UNIDAD_MINIMA } from "utility/variables";
import { Link } from "react-router-dom";


export const Form = (props) => {
    const { handleSubmit, titulo, regreso } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">No*</label>
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="diametro">Diámetro*</label>
                            <Field name="diametro" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="unidad_minima">Observaciones</label>
                            <Field
                                name="comentarios"
                                component={renderTextArea}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end flex-row mt-3 pr-3">
                {
                    regreso ? (
                        <button type="button" className="btn btn-secondary ml-1 mr-1" onClick={regreso}>Cancelar</button>
                    ) : (
                        <Link to={regreso ? regreso : "/barras"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                    )}
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
            </div>
        </form>
    );
};
