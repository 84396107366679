import React, { Component } from 'react';
import Resumen from "../ResumenProyecto";
import { UNIDAD, MEDIA } from "utility/variables";
import { RenderNumber, RenderCurrency, RenderPercentage } from "Utils/renderField/renderReadField";


class Integracion extends Component {

    render() {
        const { item } = this.props;
        let subTotal = 0;

        return (
            <div id="integracion" className="d-flex flex-column">
                <div className="form-head small-font">INTEGRACIÓN DE COSTOS</div>
                <Resumen {...this.props} />
                <div className="react-bs-table-container" style={{overflowX: "auto"}}>
                    <table className="table mt-1">
                        <thead>
                        <tr>
                            <th style={{width: "30%"}} className="p-1 paragraph bg-celeste azul" >RENGLON</th>
                            <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul" >UNIDAD</th>
                            <th style={{width: "15%"}} className="p-1 paragraph bg-celeste azul text-right" >CANTIDAD</th>
                            <th style={{width: "25%"}} className="p-1 paragraph bg-celeste azul text-right" >PRECIO UNITARIO</th>
                            <th style={{width: "20%"}} className="p-1 paragraph bg-celeste azul text-right" >SUB TOTAL</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item.renglones.map((renglon, index) => {
                            let precioUnitario = 0;
                            renglon.detalle.forEach((detalle) => {
                                let cantidad = parseFloat(detalle.cantidad) * parseFloat(renglon.cantidad);
                                if (detalle.es_herramienta) {
                                    cantidad = parseFloat(detalle.cantidad);
                                }
                                cantidad = cantidad + (cantidad * (parseFloat(detalle.porcentaje_imprevisto)/100));
                                if (detalle.material.unidad_minima === UNIDAD) {
                                    cantidad = Math.ceil(cantidad);
                                } else if (detalle.material.unidad_minima === MEDIA) {
                                    cantidad = Math.ceil(cantidad*2)/2;
                                }
                                precioUnitario += ((cantidad / renglon.cantidad) * parseFloat(detalle.costo))
                            });
                            try {
                                precioUnitario = parseFloat((Math.ceil(precioUnitario*100)/100).toFixed(2));
                                if (renglon.mano_obra)
                                    precioUnitario += parseFloat(renglon.mano_obra);
                            } catch (e) { }
                            if (!!(((precioUnitario * parseFloat(renglon.cantidad)) * 100) % 1))
                                while (parseFloat(((precioUnitario * parseFloat(renglon.cantidad)) * 100).toFixed(4)) % 1 !== 0) {
                                    precioUnitario += 0.01;
                                    precioUnitario = parseFloat(precioUnitario.toFixed(2));
                                }
                            subTotal += (precioUnitario * renglon.cantidad);
                            return (<tr key={index} className={`renglon-${index%2}`}>
                                <td className="p-1 paragraph gris">
                                    {renglon.nombre}
                                </td>
                                <td className="p-1 paragraph gris">
                                    {renglon.unidad}
                                </td>
                                <td className="p-1 paragraph gris text-right">
                                    <RenderNumber value={renglon.cantidad} decimalScale={4} />
                                </td>
                                <td className="p-1 paragraph gris text-right">
                                    <RenderCurrency value={precioUnitario} />
                                </td>
                                <td className="p-1 paragraph gris text-right">
                                    <RenderCurrency value={precioUnitario * renglon.cantidad} />
                                </td>
                            </tr>)
                        })}
                        <tr className={`renglon-${item.renglones.length%2}`}><td className="p-3" colSpan={5} /></tr>
                        <tr className={`renglon-${(item.renglones.length+1)%2}`}>
                            <td className="p-1 paragraph gris" colSpan={3} />
                            <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL DE RENGLONES</div></td>
                            <td className="p-1 pt-2 paragraph gris text-right">
                                <RenderCurrency value={subTotal} className={"azul"} />
                            </td>
                        </tr>
                        <tr className={`renglon-${(item.renglones.length)%2}`}>
                            <td className="p-1 paragraph gris" colSpan={3} />
                            <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">MANO DE OBRA</div></td>
                            <td className="p-1 pt-2 paragraph gris text-right">
                                <RenderCurrency value={item.mano_obra} className={"azul"} />
                            </td>
                        </tr>
                        <tr className={`renglon-${(item.renglones.length+1)%2}`}>
                            <td className="p-1 paragraph gris" colSpan={3} />
                            <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL COSTOS DEL PROYECTO</div></td>
                            <td className="p-1 pt-2 paragraph gris text-right">
                                <RenderCurrency value={parseFloat(item.mano_obra) + subTotal} className={"azul"} />
                            </td>
                        </tr>
                        <tr className={`renglon-${(item.renglones.length)%2}`}>
                            <td className="p-1 paragraph gris" colSpan={3} />
                            <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">MARGEN DE GANANCIA</div></td>
                            <td className="p-1 pt-2 paragraph gris text-right">
                                <RenderPercentage decimalScale={2} value={item.ganancia} className={"azul"} />
                            </td>
                        </tr>
                        <tr className={`renglon-${(item.renglones.length+1)%2}`}>
                            <td className="p-1 paragraph gris" colSpan={3} />
                            <td className="p-1 paragraph gris"><div className="paragraph-bold gris text-right">TOTAL DEL PROYECTO</div></td>
                            <td className="p-1 pt-2 paragraph gris text-right">
                                {
                                    (() => {
                                        return <RenderCurrency value={(parseFloat(item.mano_obra) + subTotal) * (1 + item.ganancia/100)} className={"azul"} />
                                    })()
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Integracion;
