import React, { Component } from 'react';
import { RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";
import { api } from "api";
import Resumen from "../../ResumenProyecto";
import { Link } from "react-router-dom";
import DetalleDesktop from "./desktop";
import DetalleResponsivo from "./responsivo";


export const proveedores = {};


class Costos extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, index: null };
    }

    render() {
        const { setProveedor, item, cambioValor, guardar, url } = this.props;
        let materiales, total_mano_obra;
        if (url.includes("proyectos")) {
            materiales = this.props.materialesProyecto;
            total_mano_obra = this.props.total_mano_obra_proyecto;
        } else {
            materiales = this.props.materiales;
            total_mano_obra = this.props.total_mano_obra;
        }
        return (
            <div id="renglones" className="d-flex flex-column">
                <div className="form-head small-font">COSTOS DE LOS MATERIALES</div>
                <Resumen {...this.props} />
                <DetalleDesktop
                    url={url}
                    materiales={materiales}
                    setProveedor={setProveedor}
                    item={item}
                    total_mano_obra={total_mano_obra}
                    cambioValor={cambioValor}
                />
                <DetalleResponsivo
                    url={url}
                    materiales={materiales}
                    setProveedor={setProveedor}
                    item={item}
                    total_mano_obra={total_mano_obra}
                    cambioValor={cambioValor}
                />
                <div className="d-flex justify-content-end flex-row mt-3 pr-3 pl-3 mb-3">
                    {!url.includes("proyectos") && (<button onClick={() => {
                        guardar("actualizar");
                    }} className="btn btn-default mr-auto paragraph">Actualizar costos</button>)}
                    <Link to={window.location.href.includes("presupuesto") ? "/presupuestos" : "/proyectos"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                    <button onClick={() => {
                        guardar();
                    }} className="btn btn-primary ml-1">Guardar</button>
                </div>
            </div>
        );
    }
}

export default Costos;
