import React, { Component } from 'react';
import _ from "lodash";
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import Buscador from "Utils/Buscador";
import { tableOptions } from "Utils/tableOptions";


class EditarProveedor extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = { search: '' };
    }

    searchChange = (search) => {
        this.setState({search});
    };

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    render() {
        const { loader, item } = this.props;
        const { search } = this.state;

        const materiales = [];

        item.costos_materiales.forEach((costo) => {
            if (costo.material.nombre.toLowerCase().includes(search.toLowerCase())) {
                materiales.push(costo);
            }
        });
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="d-flex flex-column flex-md-row justify-content-between mb-1 mb-md-4">
                    <div className="header-1 azul">{item.nombre}</div>
                    <div className="d-flex">
                        <Link to="/proveedores" className="btn btn-secondary m-1">REGRESAR</Link>
                        <Link to="editar" className="btn btn-primary m-1">EDITAR</Link>
                    </div>
                </div>
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <div className="d-flex flex-column flex-md-row justify-content-between mb-1">
                            <div className="d-flex flex-column flex-2">
                                <div className="d-flex flex-column flex-md-row">
                                    <div className="d-flex flex-1">
                                        <div className="paragraph-bold azul">
                                            DIRECCIÓN&nbsp;
                                        </div>
                                        <div className="paragraph gris">
                                            {item.direccion}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-1">
                                        <div className="paragraph-bold azul">
                                            TELÉFONO&nbsp;
                                        </div>
                                        <div className="paragraph gris">
                                            {item.telefono}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-md-row">
                                    <div className="d-flex flex-1">
                                        <div className="paragraph-bold azul">
                                            CORREO&nbsp;
                                        </div>
                                        <div className="paragraph gris">
                                            {item.correo}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-1">
                                        <div className="paragraph-bold azul">
                                            NIT&nbsp;
                                        </div>
                                        <div className="paragraph gris">
                                            {item.nit}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1">
                                <Buscador search={search} searchChange={this.searchChange} />
                            </div>
                        </div>
                        <BootstrapTable
                            data={materiales}
                            striped
                            bordered={false}
                            hover
                            options={options}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                dataFormat={(cell, row) => row.material.nombre}
                            >
                                Material
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="material"
                                dataSort
                                dataFormat={(cell) => cell.unidad}
                            >
                                Unidad de medida
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="costo"
                                dataSort
                                dataFormat={(cell) => <RenderMoneda monto={cell} />}
                            >
                                Precio
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default EditarProveedor;
