import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/materiales/barras';
import ListadoBarras from './ListadoBarras';


const ms2p = (state) => {
    return {
        ...state.barras,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoBarras);
