import React from 'react';
import moment from "moment/moment";
import { reduxForm } from 'redux-form';
import { validate, validators } from "validate-redux-form";
import { Form } from "../Form";


const CrearForm = reduxForm({
    // a unique name for the form
    form: 'CrearAlquilerForm',
    initialValues: {
        fecha: moment(new Date()).format("YYYY-MM-DD")
    },
    validate: (data) => {
        return validate(data, {
            cliente: validators.exists()("Campo requerido"),
        });
    },
})(Form);


export default CrearForm;
