import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/usuarios/usuarios';
import Menu from './Menu';


const ms2p = (state) => {
    const url = window.location.href;
    return {
        ...state.usuarios,
        url,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Menu);
