import _ from "lodash";
import { api } from "api";


export const ADMINISTRADOR = 1;
export const SA = "Administrador";
export const RESPONSABLE = 5;
export const RP = "Responsable de proyecto";
export const SECRETARIA = 10;
export const SE = "Secretaria";

export const BORRADOR = 0;
export const ENVIADO = 10;
export const ACEPTADO = 15;
export const EN_PROCESO = 20;
export const FINALIZADO = 30;

export const MATERIAL = 1;
export const GASTO = 10;

export const EFECTIVO = 1;
export const TARJETA = 2;
export const DEPOSITO = 3;
export const TRANSFERENCIA = 4;

export const HORA = 1;
export const DIA = 2;
export const SEMANA = 7;
export const MES = 30;

export const INGRESO = 1;
export const EGRESO = 2;

export const TIPO_MOVIMIENTO = [
    { label: "INGRESO", value: INGRESO},
    { label: "EGRESO", value: EGRESO},
];

export const UNIDAD_MAQUINA = [
    { label: "HORA", value: HORA},
    { label: "DIA", value: DIA},
    { label: "SEMANA", value: SEMANA},
    { label: "MES", value: MES},
];

export const FORMAS_PAGO = [
    { label: "EFECTIVO", value: EFECTIVO},
    { label: "TARJETA", value: TARJETA},
    { label: "DEPOSITO", value: DEPOSITO},
    { label: "TRANSFERENCIA", value: TRANSFERENCIA},
];

export const TIPO_DETALLE = [
    {value: MATERIAL, label: "MATERIAL"},
    {value: GASTO, label: "GASTO"},
];

export const ESTADOS_PRESUPUESTO = [
    { label: "Borrador", value: BORRADOR },
    { label: "Enviada", value: ENVIADO },
    { label: "Aceptada", value: ACEPTADO },
    { label: "En proceso", value: EN_PROCESO },
    { label: "Finalizado", value: FINALIZADO },
];

export const ROLES = [
    { label: "Administrador", value: ADMINISTRADOR },
    { label: "Responsable de proyecto", value: RESPONSABLE },
    { label: "Secretaria", value: SECRETARIA },
];

export const RESISTENCIAS = [
    { label: "2000psi", value: "2000psi" },
    { label: "2500psi", value: "2500psi" },
    { label: "3000psi", value: "3000psi" },
    { label: "4000psi", value: "4000psi" },
];

export const LONGITUDES = [
    { label: "6m", value: 6 },
    { label: "9m", value: 9 },
    { label: "12m", value: 12 },
    { label: "15m", value: 15 },
];

export const UNIDAD = 1;
export const MEDIA = 0.5;
export const LIBRE = 0;

export const UNIDAD_MINIMA = [
    { label: "Unidad", value: UNIDAD },
    { label: "Media unidad", value: MEDIA },
    { label: "Libre", value: LIBRE },
];

export const COLUMNA = 10;
export const VIGA = 20;
export const ZAPATA = 30;
export const CIMIENTO_CORRIDO = 40;

export const TIPOS_PLANTILLA = [
    { label: "Columna", value: COLUMNA },
    { label: "Viga", value: VIGA },
    { label: "Zapata", value: ZAPATA },
    { label: "Cimiento Corrido", value: CIMIENTO_CORRIDO },
];

export const materiales = [];

export const getMateriales = (search) => {
    return api.get("materiales", { search }).then((data) => {
        data.results.forEach((item) => {
            const obj = _.cloneDeep(item);
            if (!_.find(materiales, { id: item.id })) {
                materiales.push(obj);
            }
        });
        return {options: materiales};
    }).catch((e) => {
        console.log(e);
    });
};

export const usuarios = [];

export const getUsuarios = (search) => {
    return api.get("usuarios", { search }).then((data) => {
        data.results.forEach((item) => {
            const obj = _.cloneDeep(item);
            if (!_.find(usuarios, { id: item.id })) {
                usuarios.push({...obj, nombre: obj.perfil ? obj.perfil.nombre : obj.username});
            }
        });
        return {options: usuarios};
    }).catch((e) => {
        console.log(e);
    });
};

export const clientes = [];

export const getClientes = (search) => {
    return api.get("clientes", { search }).then((data) => {
        data.results.forEach((item) => {
            const obj = _.cloneDeep(item);
            if (!_.find(clientes, { id: item.id })) {
                clientes.push(obj);
            }
        });
        return {options: clientes};
    }).catch((e) => {
        console.log(e);
    });
};

export const resumenes = [];

export const getResumenes = (search, callback) => {
    callback(null, {options: resumenes});
};

export const plantillas = [];

export const getPlantillas = (search) => {
    return api.get("plantillas", { search }).then((data) => {
        data.results.forEach((item) => {
            const obj = _.cloneDeep(item);
            if (!_.find(plantillas, { id: item.id })) {
                plantillas.push(obj);
            }
        });
        return {options: plantillas};
    }).catch((e) => {
        console.log(e);
    });
};

export const concretos = [];

export const getConcretos = (search) => {
    return api.get("concretos", { search }).then((data) => {
        data.results.forEach((item) => {
            if (!_.find(concretos, {id: item.id})) {
                concretos.push(item);
            }
        });
        return { options: concretos };
    }).catch((e) => {
        console.log(e);
    });
};

export const barras = [];

export const getBarras = (search) => {
    return api.get("barras", { search, select: "true" }).then((data) => {
        data.results.forEach((item) => {
            if (!_.find(barras, {nombre: item.nombre})) {
                barras.push({...item, id: item.nombre});
            }
        });
        return { options: barras };
    }).catch((e) => {
        console.log(e);
    });
};


export const proveedores = [];

export const getProveedores = (search) => {
    return api.get("proveedores", { search, select: "true" }).then((data) => {
        data.results.forEach((item) => {
            if (!_.find(proveedores, {nombre: item.nombre})) {
                proveedores.push({...item});
            }
        });
        return { options: proveedores };
    }).catch((e) => {
        console.log(e);
    });
};


export const maquinaria = [];

export const getMaquinaria = (search) => {
    return api.get("maquinaria", { search, select: "true" }).then((data) => {
        data.results.forEach((item) => {
            if (!_.find(maquinaria, {nombre: item.nombre})) {
                maquinaria.push({...item});
            }
        });
        return { options: maquinaria };
    }).catch((e) => {
        console.log(e);
    });
};

export const acciones_bitacora = [
    // {descripcion: "Login", label: "Login"},
    {descripcion: "Crear Material", label: "Crear Material"},
    {descripcion: "Editar Material", label: "Editar Material"},
    {descripcion: "Eliminar Material", label: "Eliminar Material"},
    {descripcion: "Crear Barra", label: "Crear Barra"},
    {descripcion: "Editar Barra", label: "Editar Barra"},
    {descripcion: "Eliminar Barra", label: "Eliminar Barra"},
    {descripcion: "Crear Plantilla", label: "Crear Plantilla"},
    {descripcion: "Editar Plantilla", label: "Editar Plantilla"},
    {descripcion: "Eliminar Plantilla", label: "Eliminar Plantilla"},
    {descripcion: "Crear Maquinaria", label: "Crear Maquinaria"},
    {descripcion: "Editar Maquinaria", label: "Editar Maquinaria"},
    {descripcion: "Crear Vehiculo", label: "Crear Vehiculo"},
    {descripcion: "Editar Vehiculo", label: "Editar Vehiculo"},
    {descripcion: "Crear Usuario", label: "Crear Usuario"},
    {descripcion: "Editar Usuario", label: "Editar Usuario"},
    {descripcion: "Eliminar Usuario", label: "Eliminar Usuario"},
    {descripcion: "Crear Proveedor", label: "Crear Proveedor"},
    {descripcion: "Editar Proveedor", label: "Editar Proveedor"},
    {descripcion: "Eliminar Proveedor", label: "Eliminar Proveedor"},
    {descripcion: "Crear Cliente", label: "Crear Cliente"},
    {descripcion: "Editar Cliente", label: "Editar Cliente"},
    {descripcion: "Eliminar Cliente", label: "Eliminar Cliente"},
    {descripcion: "Crear Fase", label: "Crear Fase"},
    {descripcion: "Editar Fase", label: "Editar Fase"},
    {descripcion: "Eliminar Fase", label: "Eliminar Fase"},
    {descripcion: "Crear Cobro", label: "Crear Cobro"},
    {descripcion: "Crear Proyecto", label: "Crear Proyecto"},
    {descripcion: "Editar Proyecto", label: "Editar Proyecto"},
    {descripcion: "Eliminar Proyecto", label: "Eliminar Proyecto"},
    {descripcion: "Crear Orden de Compra", label: "Crear Orden de Compra"},
    {descripcion: "Editar Orden de Compra", label: "Editar Orden de Compra"},
    {descripcion: "Eliminar Orden de Compra", label: "Eliminar Orden de Compra"},
    {descripcion: "Crear Baja de Inventario", label: "Crear Baja de Inventario"},
    {descripcion: "Crear Pago", label: "Crear Pago"},
    {descripcion: "Editar Pago", label: "Editar Pago"},
    {descripcion: "Eliminar Pago", label: "Eliminar Pago"},
    {descripcion: "Crear Entrega", label: "Crear Entrega"},
    {descripcion: "Editar Entrega", label: "Editar Entrega"},
    {descripcion: "Eliminar Entrega", label: "Eliminar Entrega"},
];
