import React from 'react';
import moment from "moment/moment";
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from "validate-redux-form";
import { renderDatePicker, renderField, renderNumber, renderCurrency } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";


const renderMateriales = ({ fields, meta, meta: {error, submitFailed} }) => (
    <div className="d-flex flex-column justify-content-center form-content">
        <div className="d-block d-lg-none">
            {fields.map((material, index) => (
                <div className="d-flex" key={index}>
                    <div className="d-flex flex-column justify-content-around align-items-stretch">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${material}.nombre_material`}>Material</label>
                            <Field name={`${material}.nombre_material`} component={renderField} disabled />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="unidad_material">Unidad</label>
                            <Field name={`${material}.unidad_material`} disabled component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="baja">Existencia</label>
                            <Field name={`${material}.cantidad`} disabled component={renderNumber} decimalScale={4} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="baja">Cantidad</label>
                            <Field name={`${material}.baja`} component={renderNumber} decimalScale={4} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <table className="d-none d-lg-block">
            <tbody>
            <tr>
                <th className="pr-4 pl-4" >Material</th>
                <th className="pr-4 pl-4" >Unidad</th>
                <th className="pr-4 pl-4" >Existencia</th>
                <th className="pr-4 pl-4" >Cantidad</th>
            </tr>
            {fields.map((material, index) => (
                <tr key={index}>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.nombre_material`} component={renderField} disabled />
                    </td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.unidad_material`} component={renderField} disabled type="text" className="form-control" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.cantidad`} disabled component={renderNumber} decimalScale={4} type="text" className="form-control" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${material}.baja`} component={renderNumber} decimalScale={4} type="text" className="form-control" /></td>
                </tr>
            ))}
            </tbody>
        </table>
        {submitFailed && (
            <div className="invalid-feedback d-block pl-4">
                {error}
            </div>
        )}
    </div>
);


const Form = (props) => {
    const { handleSubmit, proyecto } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">Baja de Inventario</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="fecha">Fecha*</label>
                            <Field
                                name="fecha"
                                component={renderDatePicker}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="motivo">Motivo*</label>
                            <Field
                                name="motivo"
                                component={renderField}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-container">
                <FieldArray
                    name="inventario"
                    component={renderMateriales}
                />
            </div>
            <div className="d-flex justify-content-start flex-row-reverse mt-1 mb-3 pr-3">
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
                <Link to={`/proyectos/${proyecto}`} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
            </div>
        </form>
    );
};

const BajaInventarioForm = reduxForm({
    // a unique name for the form
    form: 'BajaInventarioForm',
    initialValues: {
        fecha: moment(new Date()).format("YYYY-MM-DD")
    },
    validate: (data) => {
        return validate(data, {
            motivo: validators.exists()("Campo requerido"),
            fecha: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default BajaInventarioForm;
