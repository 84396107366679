import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import { acciones_bitacora } from "utility/variables";
import { RenderDateTime } from "Utils/renderField/renderReadField";
import Anterior from './Anterior';
import Actual from './Actual';
import { Link } from "react-router-dom";


class DetalleBitacora extends Component {
    static propTypes = {
        bitacora: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getBitacora(this.props.match.params.id);
    }

    render() {
        const {loading, bitacora} = this.props;
        const descripcion = _.find(acciones_bitacora, {label: bitacora.accion}) ? _.find(acciones_bitacora, {label: bitacora.accion}) : {};

        return (
            <div className="row">
                <div className="col-12">
                    <div className="cuadro d-flex flex-column">
                        <Link to="/bitacora" className="btn btn-secondary my-2 ml-auto">Regresar</Link>
                        <div style={{flex: "1"}}>
                            <div className="form-head small-font">Detalle de Bitácora</div>
                        </div>
                        <LoadMask loading={loading} light blur>
                            <div className="d-flex flex-column flex-md-row mb-3 p-3 border">
                                <div className="d-flex flex-column flex-1">
                                    <div className="gris paragraph">Responsable</div>
                                    <div className="azul paragraph-bold">{bitacora.usuario.username}</div>
                                </div>
                                <div className="d-flex flex-column flex-2">
                                    <div className="gris paragraph">Descripción</div>
                                    <div className="azul paragraph-bold">{descripcion.descripcion}</div>
                                </div>
                                <div className="d-flex flex-column flex-1">
                                    <div className="gris paragraph">Fecha y hora</div>
                                    <div className="azul paragraph-bold"><RenderDateTime value={bitacora.creado} /></div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-sm-row border">
                                {!!bitacora.detalle_anterior && (
                                    <Anterior detalle={JSON.parse(bitacora.detalle_anterior)} />
                                )}
                                {!!bitacora.detalle && (
                                    <Actual detalle={JSON.parse(bitacora.detalle)} anterior={JSON.parse(bitacora.detalle_anterior)} />
                                )}
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleBitacora;
