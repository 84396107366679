import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { icons } from "fotos";
import { renderAsync, renderDatePicker } from 'Utils/renderField/renderField';
import { getUsuarios } from "utility/variables";


const Form = (props) => {
    const { handleSubmit, cancelar } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">Asignar responsable</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="username">Usuario Responsable</label>
                            <Field
                                name="username"
                                clearable={false}
                                component={renderAsync}
                                loadOptions={getUsuarios}
                                placeholder="Seleccionar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end flex-row mt-3 pr-3">
                <button type="button" onClick={cancelar} className="btn btn-secondary m-1 align-self-center">Cancelar</button>
                <button type="submit" className="btn btn-primary m-1 align-self-center">Aceptar</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'Responsable',
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
        });
    },
})(Form);
