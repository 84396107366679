import React from 'react';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";


export const maquinaria = (row) => {
    const options = _.cloneDeep(tableOptions);
    return (
        <div className="inner-table">
            <BootstrapTable
                data={row.detalle}
                remote
                striped
                bordered={false}
                hover
                options={options}
            >
                <TableHeaderColumn
                    dataField="nombre_maquinaria"
                    dataSort
                >
                    Maquinaria
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="unidad"
                    dataSort
                >
                    Unidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataFormat={(cell) => <RenderMoneda monto={cell} decimalScale={2} />}
                    dataSort
                    dataAlign="right"
                >
                    Precio Unitario
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="minimo"
                    dataFormat={(cell) => <RenderNumero value={cell} decimalScale={2} />}
                    dataSort
                    dataAlign="right"
                >
                    Mínimo
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    isKey
                    width={"0%"}
                />
            </BootstrapTable>
        </div>
    );
};
