import React from 'react';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { TIPO_MOVIMIENTO } from "utility/variables";
import { RenderDateTime, RenderMoneda } from "Utils/renderField/renderTableField";
import { RenderDate } from "Utils/renderField/renderReadField";


export const bajas = (row) => {
    const options = _.cloneDeep(tableOptions);
    return (
        <div className="inner-table">
            {!!(row.bajas.length) && (
                <React.Fragment>
                    <div className="header-2 azul py-1">Baja</div>
                    <BootstrapTable
                        data={row.bajas}
                        remote
                        striped
                        bordered={false}
                        hover
                        options={options}
                    >
                        <TableHeaderColumn
                            dataField="usuario"
                            dataSort
                        >
                            Usuario
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="motivo"
                            dataSort
                        >
                            Motivo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={(cell) => <RenderDateTime fecha={cell} />}
                            dataSort
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            width={"0%"}
                        />
                    </BootstrapTable>
                </React.Fragment>
            )}
        </div>
    );
};
