import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { MATERIAL } from "utility/variables";
import { tableOptions } from "Utils/tableOptions";
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { Chart } from "react-google-charts";
import { RenderDate } from "../../Utils/renderField/renderReadField";


class VersusProyecto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    render() {
        const { item } = this.props;
        const { fases } = item;
        const materiales = [];
        item.renglones.forEach((renglon) => {
            renglon.detalle.forEach((detalle) => {
                const material = _.find(materiales, {id: detalle.material.id});
                const cantidad = (parseFloat(detalle.cantidad) * (1 + detalle.porcentaje_imprevisto/100)) * renglon.cantidad;
                if (material) {
                    material.cantidad += cantidad;
                } else {
                    materiales.push({...detalle, cantidad, tipo: MATERIAL, id: detalle.material.id, comprado: 0, pagado: 0});
                }
            })
        });
        let cobrado = 0;
        item.cobros.forEach((cobro) => {
            cobrado += parseFloat(cobro.total);
        });

        fases.forEach((fase) => {
            fase.ordenes.forEach((orden) => {
                orden.detalle.forEach((detalle) => {
                    let pagado = 0;
                    if (orden.pagado)
                        pagado = parseFloat(detalle.total);
                    if (detalle.tipo === MATERIAL) {
                        const material =_.find(materiales, {id: detalle.material});
                        if (!material) {
                            materiales.push({...detalle, comprado: parseFloat(detalle.cantidad), pagado, cantidad: 0, id: detalle.material });
                        } else {
                            material.comprado = material.comprado + parseFloat(detalle.cantidad);
                            material.pagado = material.pagado += parseFloat(detalle.total);
                        }
                    } else {
                        const material = _.find(materiales, { nombre: detalle.nombre_material});
                        if (!material) {
                            materiales.push({...detalle, comprado: parseFloat(detalle.cantidad), pagado, cantidad: 0, id: detalle.material });
                        } else {
                            material.comprado = material.comprado + parseFloat(detalle.cantidad);
                            material.pagado = material.pagado += parseFloat(detalle.total);
                        }
                    }
                });
            });
        });

        materiales.forEach((material) => {
            material.presupuesto = material.costo * material.cantidad;
        });
        const options = _.cloneDeep(tableOptions);

        return (
            <div id="Versus" className="d-flex flex-column">
                <div className="border mb-3 fondo_fase m-3 py-2">
                    <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
                        <div className="flex-2 ml-3 mr-3 pl-3 pr-3 d-flex flex-column flex-md-row bg-white">
                            <div className="header-2 azul align-self-center">Ingresos VS Egresos</div>
                            <div className="flex-1" style={{minWidth: "100px"}}>
                                <Chart
                                    chartType="BarChart"
                                    height={'85px'}
                                    data={[
                                        ['','', {role: 'style'}],
                                        [
                                            '',
                                            cobrado,
                                            '#085F84',
                                        ],
                                        [
                                            '',
                                            item.ejecutado,
                                            cobrado < parseFloat(item.ejecutado) ? '#AB413A' : '#00658A',
                                        ],
                                    ]}
                                    options={{
                                        legend: { position: 'none' },
                                        bar: { groupWidth: '80%' },
                                        orientation: 'vertical',
                                        hAxis: {maxValue: 100, minValue: 0},
                                        vAxis: {maxValue: 100, minValue: 0},
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex-3 d-flex flex-column flex-md-row m-3 p-3 bg-white justify-content-around">
                            <div className="d-flex flex-column">
                                <div className="flex-2 header-2 gris text-center">TOTAL COBRADO</div>
                                <div className="flex-1 ml-2 header-1 azul text-right">
                                    <RenderCurrency value={cobrado}/>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="flex-2 header-2 gris ">TOTAL GASTADO</div>
                                <div className="flex-1 ml-2 header-1 celeste text-right">
                                    <RenderCurrency value={item.ejecutado ? item.ejecutado : 0}/>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="flex-2 header-2 gris ">BALANCE</div>
                                <div className={`flex-1 ml-2 header-1 ${cobrado - item.ejecutado > 0 ? "celeste" : "rojo"} text-right`}>
                                    <RenderCurrency value={item.ejecutado ? cobrado - item.ejecutado : 0}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BootstrapTable
                    data={materiales}
                    striped
                    bordered={false}
                    hover
                    options={options}
                >
                    <TableHeaderColumn
                        dataField="nombre_material"
                        dataSort
                        dataFormat={(cell, row) => {
                            if (!!row.gasto)
                                return row.gasto;
                            return `${row.nombre_material} ${row.unidad_material}`;
                        }}
                    >
                        Material
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                        dataSort
                        dataAlign="right"
                    >
                        Cantidad Presupuestada
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="comprado"
                        dataFormat={(cell, row) => <RenderNumero value={cell} className={((row.cantidad > cell) || cell === undefined) ? "azul" : "rojo"} decimalScale={4} />}
                        dataSort
                        dataAlign="right"
                    >
                        Cantidad Comprada
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="presupuesto"
                        dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                        dataSort
                        dataAlign="right"
                    >
                        Monto Presupuestado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pagado"
                        dataFormat={(cell, row) => <RenderMoneda monto={cell} className={((row.presupuesto > cell) || cell === undefined) ? "azul" : "rojo"} />}
                        dataSort
                        dataAlign="right"
                    >
                        Monto Pagado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        width={"0%"}
                    />
                </BootstrapTable>
            </div>
        );
    }
}

export default VersusProyecto;
