import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import NumberFormat from 'react-number-format';
import CrearRenglon from "../CrearRenglon";
import AgregarMaterial from "./AgregarMaterial";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { getResumenes, getMateriales } from "utility/variables";
import { AsyncCreatable } from "react-select";
import { UNIDAD, MEDIA } from "utility/variables";
import { icons } from "fotos";
import "./renglones.css";
import { Link } from "react-router-dom";
import Resumen from "../../ResumenProyecto";
import DetalleDesktop from "./desktop";
import DetalleResponsivo from "./responsivo";


class Renglones extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, index: null };
    }

    openModal = (tipo) => {
        return () => {
            const { inicializarNuevoRenglon } = this.props;
            inicializarNuevoRenglon();
            this.setState({ open: true, tipo });
        }
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    agregarRenglon = (data) => {
        const { agregarRenglon } = this.props;
        agregarRenglon(data);
        this.setState({ open: false });
    };

    toggleRenglon = (index) => {
        if (this.state.index === index) {
            this.setState({index: undefined});
        } else {
            this.setState({index});
        }
    };

    render() {
        const { item, cambioRenglon, cambioDetalle, quitarDetalle, agregarDetalle, guardar } = this.props;
        const { open, tipo } = this.state;

        let subTotal = 0;

        return (
            <div id="renglones" className="d-flex flex-column">
                <div className="form-head small-font">MATERIAL REQUERIDO</div>
                <Resumen {...this.props} />
                <div className="d-flex flex-column border-top">
                    {item.renglones.map((renglon, index) => {
                        let precioUnitario = 0;
                        renglon.detalle.forEach((detalle) => {
                            let cantidad = parseFloat(detalle.cantidad) * parseFloat(renglon.cantidad);
                            if (detalle.es_herramienta) {
                                cantidad = parseFloat(detalle.cantidad);
                            }
                            cantidad = cantidad + (cantidad * (parseFloat(detalle.porcentaje_imprevisto)/100));
                            if (detalle.material.unidad_minima === UNIDAD) {
                                cantidad = Math.ceil(cantidad);
                            } else if (detalle.material.unidad_minima === MEDIA) {
                                cantidad = Math.round(cantidad*2)/2;
                            }
                            precioUnitario += ((cantidad / renglon.cantidad) * parseFloat(detalle.costo))
                        });
                        try {
                            precioUnitario = parseFloat((Math.ceil(precioUnitario*100)/100).toFixed(2));
                            // if (renglon.mano_obra)
                            //     precioUnitario += parseFloat(renglon.mano_obra);
                        } catch (e) { }
                        if (!!(((precioUnitario * parseFloat(renglon.cantidad)) * 100) % 1))
                        while (parseFloat(((precioUnitario * parseFloat(renglon.cantidad)) * 100).toFixed(4)) % 1 !== 0) {
                            precioUnitario += 0.01;
                            precioUnitario = parseFloat(precioUnitario.toFixed(2));
                        }
                        if (renglon.cantidad) {
                            subTotal += (precioUnitario * renglon.cantidad);
                            if (renglon.mano_obra)
                                subTotal += (renglon.mano_obra * renglon.cantidad);
                        }
                        return (
                            <div key={renglon.index} className="d-flex flex-column border-bottom">
                                <div className={`d-flex justify-content-between pt-1 pb-1 pl-2 pr-2 renglon-${index%2} ${this.state.index === index ? "expanded" : "collapsed"}`}>
                                    <div className="d-flex align-items-center flex-1" onClick={() => this.toggleRenglon(index)}>
                                        <div className="paragraph gris">Renglón&nbsp;</div>
                                        <div className="paragraph-bold azul">{renglon.nombre}</div>
                                    </div>
                                    <AsyncCreatable
                                        clearable={false}
                                        disabled={item.estado !== "Borrador"}
                                        value={renglon.resumen}
                                        className="react-select-container flex-1 pl-3 pr-3"
                                        onChange={(e) => { cambioRenglon(index, 'resumen', e) }}
                                        cache={false}
                                        searchPromptText="Escribe para buscar"
                                        promptTextCreator={(label) => `Crear ${label}`}
                                        valueKey="id"
                                        labelKey="nombre"
                                        loadOptions={getResumenes}
                                    />
                                </div>
                                <div className={`${this.state.index === index ? "d-flex" : "d-none"} flex-column`}>
                                    <div className="d-flex flex-column flex-md-row justify-content-between pt-1 pb-1 pl-2 pr-2">
                                        <div className="d-flex align-items-center pl-1 pr-1 flex-2">
                                            <div className="paragraph gris flex-1">Cantidad&nbsp;</div>
                                            <NumberFormat
                                                className="form-control flex-1"
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={renglon.cantidad}
                                                thousandSeparator
                                                prefix=""
                                                onValueChange={(values) => { cambioRenglon(index, 'cantidad', values.value) }}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center pl-1 pr-1 flex-1">
                                            <div className="paragraph gris flex-1">Unidad&nbsp;</div>
                                            <div className="paragraph azul flex-1">{renglon.unidad}</div>
                                        </div>
                                        <div className="d-flex align-items-center pl-1 pr-1 flex-1">
                                            <div className="paragraph gris flex-1">Costo unitario&nbsp;</div>
                                            <RenderCurrency className={"paragraph azul flex-1"} value={precioUnitario} />
                                        </div>
                                        <div className="d-flex align-items-center pl-1 pr-1 flex-2">
                                            <div className="paragraph gris flex-1">Mano de obra&nbsp;</div>
                                            <NumberFormat
                                                className="form-control flex-1"
                                                disabled={item.estado !== "Borrador"}
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={renglon.mano_obra}
                                                thousandSeparator
                                                prefix="Q"
                                                onValueChange={(values) => { cambioRenglon(index, 'mano_obra', values.value) }}
                                            />
                                        </div>
                                    </div>
                                    <DetalleDesktop
                                        disabled={renglon.disabled}
                                        renglon={renglon}
                                        index={index}
                                        cambioDetalle={cambioDetalle}
                                        quitarDetalle={quitarDetalle}
                                        agregarDetalle={agregarDetalle}
                                        precioUnitario={precioUnitario}
                                    />
                                    <DetalleResponsivo
                                        disabled={renglon.disabled}
                                        renglon={renglon}
                                        index={index}
                                        cambioDetalle={cambioDetalle}
                                        quitarDetalle={quitarDetalle}
                                        agregarDetalle={agregarDetalle}
                                        precioUnitario={precioUnitario}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={`renglon-${(item.renglones.length)%2} pl-2 pt-1 pb-1`}>
                    <button className="btn btn-default paragraph mr-2" onClick={this.openModal("renglon")}>Agregar Renglón</button>
                </div>
                <div className={`renglon-${(item.renglones.length + 1)%2} p-3`} />
                <div className={`renglon-${(item.renglones.length)%2} p-3`} />
                <div className={`renglon-${(item.renglones.length + 1)%2} d-flex align-items-center`}>
                    <div className="paragraph gris ml-auto">COSTO TOTAL RENGLONES&nbsp;</div>
                    {/*<RenderCurrency value={item.total} className="azul mr-3" />*/}
                    <RenderCurrency value={subTotal} className="azul mr-3" />
                </div>
                <div className="d-flex justify-content-end flex-row mt-3 pr-3 mb-3">
                    <Link to={window.location.href.includes("presupuesto") ? "/presupuestos" : "/proyectos"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                    <button onClick={() => {
                        guardar();
                    }} className="btn btn-primary ml-1">Guardar</button>
                </div>
                <Modal open={open} onClose={this.closeModal} center showCloseIcon={false} classNames={{modal: "rr-modal"}}>
                    {(tipo === "renglon") ? (
                        <CrearRenglon onSubmit={this.agregarRenglon} regreso={this.closeModal} />
                    ) : (
                        <AgregarMaterial onSubmit={this.agregarRenglon} regreso={this.closeModal} />
                    )}
                </Modal>
            </div>
        );
    }
}

export default Renglones;
