import { connect } from 'react-redux';
import { actions, editarForm } from '../../../../redux/modules/proveedores/proveedores';
import EditarProveedor from './EditarProveedor';


const ms2p = (state) => {
    const {form} = state;
    try {
        const costos_materiales = form[editarForm].values.costos_materiales;
        costos_materiales.forEach((item) => {
            try {
                item.unidad = item.material_id.unidad;
            } catch (e) {}
        });
    } catch (e) {}
    return {
        ...state.proveedores,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EditarProveedor);
