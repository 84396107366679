import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from "validate-redux-form";
import { Link } from "react-router-dom";
import moment from "moment";
import { TIPO_MOVIMIENTO } from "utility/variables";
import { icons } from "fotos";
import {
    renderCurrency,
    renderDatePicker,
    renderSelectField,
    renderField,
} from "Utils/renderField/renderField";


export const renderMaquinaria = ({ fields, meta: {error, submitFailed}, loadOptions }) => (
    <div className="d-flex flex-column justify-content-center form-content">
        <div className="d-block d-lg-none">
            {fields.map((maquinaria, index) => (
                <div className="d-flex" key={index}>
                    <div className="d-flex flex-column justify-content-around align-items-stretch">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${maquinaria}.maquinaria`}>Maquinaria*</label>
                            <Field
                                name={`${maquinaria}.maquinaria`}
                                clearable={false}
                                component={renderSelectField}
                                options={loadOptions}
                                placeholder="Seleccionar"
                                labelKey="label"
                                valueKey="value"
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="tipo">Tipo*</label>
                            <Field
                                name={`${maquinaria}.tipo`}
                                component={renderSelectField}
                                options={TIPO_MOVIMIENTO}
                                labelKey="label"
                                valueKey="value"
                                clearable={false}
                                placeholder="Seleccionar"
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${maquinaria}.descripcion`}>Descripción*</label>
                            <Field name={`${maquinaria}.descripcion`} component={renderField} type="text" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor={`${maquinaria}.monto`}>Monto*</label>
                            <Field name={`${maquinaria}.monto`} component={renderCurrency} decimalScale={2} type="text" className="form-control" />
                        </div>
                    </div>
                    <img
                        src={icons.eliminar2}
                        alt=""
                        className="eliminar"
                        onClick={() => fields.remove(index)}
                    />
                </div>
            ))}
        </div>
        <table className="d-none d-lg-block">
            <tbody>
            <tr>
                <th className="pr-4 pl-4" style={{ width: "24%" }}>Maquinaria*</th>
                <th className="pr-4 pl-4" style={{ width: "23%" }}>Tipo*</th>
                <th className="pr-4 pl-4" style={{ width: "22%" }}>Descripción*</th>
                <th className="pr-4 pl-4" style={{ width: "23%" }}>Monto*</th>
                <th style={{ width: "8%" }} />
            </tr>
            {fields.map((maquinaria, index) => (
                <tr key={index}>
                    <td className="pr-4 pl-4 pt-2">
                        <Field
                            name={`${maquinaria}.maquinaria`}
                            clearable={false}
                            component={renderSelectField}
                            options={loadOptions}
                            placeholder="Seleccionar"
                            labelKey="label"
                            valueKey="value"
                        />
                    </td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field
                            name={`${maquinaria}.tipo`}
                            component={renderSelectField}
                            options={TIPO_MOVIMIENTO}
                            labelKey="label"
                            valueKey="value"
                            clearable={false}
                            placeholder="Seleccionar"
                        />
                    </td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${maquinaria}.descripcion`} component={renderField} type="text" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <Field name={`${maquinaria}.monto`} component={renderCurrency} decimalScale={2} type="text" className="form-control" /></td>
                    <td className="pr-4 pl-4 pt-2">
                        <img
                            src={icons.eliminar2}
                            alt=""
                            className="eliminar"
                            onClick={() => fields.remove(index)}
                        />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        {submitFailed && (
            <div className="invalid-feedback d-block pl-4">
                {error}
            </div>
        )}
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start flex-row mt-3 pl-4 pr-4">
            <button type="button" className="btn btn-default" onClick={() => fields.push()}>
                Agregar detalle
            </button>
        </div>
    </div>
);


export const Form = (props) => {
    const { handleSubmit, maquinarias } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column border">
            <div className="">
                <div className="form-head small-font">Alquiler de Maquinaria</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" />
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="fecha">Fecha*</label>
                            <Field
                                name="fecha"
                                component={renderDatePicker}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <FieldArray
                    name="detalle"
                    loadOptions={maquinarias}
                    component={renderMaquinaria}
                />
            </div>
            <div className="d-flex justify-content-end flex-row my-3 pr-3">
                <Link to={"/alquileres"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
            </div>
        </form>
    );
};


const GastoAlquilerForm = reduxForm({
    // a unique name for the form
    form: "GastoAlquilerForm",
    initialValues: {
        fecha: moment(new Date()).format("YYYY-MM-DD")
    },
    validate: (data) => {
        return validate(data, {
            cliente: validators.exists()("Campo requerido"),
        });
    },
})(Form);


export default GastoAlquilerForm;
