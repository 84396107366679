import React from 'react';
import { reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSelectField, renderTextArea } from "Utils/renderField/renderField";
import { Form } from "../Form";

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'CrearClientesForm',
    validate: (data) => {
        let email = validators.exists()("Campo requerido");
        if (data.email) {
          email = validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)('Debe ser un correo válido');
        }
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
            nit: validators.exists()("Campo requerido"),
            telefono: validators.exists()("Campo requerido"),
            email,
            correo: validators.exists()("Campo requerido"),
            direccion: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default CrearForm;
