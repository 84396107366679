import { handleActions } from 'redux-actions';
import uuidv1 from "uuid/v1";
import moment from "moment";
import _ from "lodash";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api, updateExpiracion } from "api";
import { NotificationManager } from "react-notifications";
import { resumenes, materiales, getMateriales } from "utility/variables";
import { proveedores } from "../../../common/components/Presupuestos/Editar/Costos/Costos";
import Swal from 'sweetalert2';
import { actions as proyectoActions } from "../proyectos/proyectos";

// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'PRESUPUESTOS_LOADER';
const DATA = 'PRESUPUESTOS_DATA';
const ITEM = 'PRESUPUESTOS_ITEM';
const PAGE = 'PRESUPUESTOS_PAGE';
const ORDERING = 'PRESUPUESTOS_ORDERING';
const SEARCH = 'PRESUPUESTOS_SEARCH';
const UUID = 'PRESUPUESTOS_UUID';
const TAB = 'PRESUPUESTOS_TAB';

export const constants = {
};

const endpoint = "presupuestos";
const crearForm = "CrearPresupuestoForm";
const editarForm = "EditarPresupuestoForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const tabChange = tab => ({
    type: TAB,
    tab,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { presupuestos } = getStore();
    const params = { page };
    params.ordering = presupuestos.ordering;
    params.search = presupuestos.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().presupuestos.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const parseItem = (response) => (dispatch) => {
    const renglones = [];
        response.resumenes.forEach((resumen) => {
            if (!_.find(resumenes, {id: resumen.nombre})) {
                resumenes.push({id: resumen.nombre, nombre: resumen.nombre});
            }
            resumen.renglones.forEach((renglon) => {
                renglon.detalle.forEach((material) => {
                    if (material.costo) {
                        const proveedor = {
                            nombre: `${material.nombre_proveedor}/${material.direccion_proveedor}/${material.costo_material}`,
                            id: material.costo_material,
                            costo: material.costo,
                            proveedor: {id: material.proveedor, nombre: material.nombre_proveedor, direccion: material.direccion_proveedor}
                        };
                        material.material.proveedor = proveedor;
                        proveedores[material.material.id] = [
                            {...proveedor}
                        ];
                    }
                    if (!_.find(materiales, { id: material.material.id })) {
                        getMateriales(material.nombre_material);
                        // materiales.push({...material.material, nombre: material.nombre_material});
                    }
                });
                renglones.push({
                    ...renglon,
                    resumen,
                    index: renglones.length,
                })
            });
        });
        dispatch(setItem({...response, renglones}));
        dispatch(initializeForm(editarForm, response));
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(parseItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    const form = _.cloneDeep(data);
    form.cliente = form.cliente.id;
    api.post(endpoint, form).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(tabChange("2"));
        dispatch(push(`/presupuestos/${response.id}/editar`));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editar = (id, actualizar) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const { presupuestos: { item }, form: {[editarForm]: data} } = getStore();
    const { nombre, cliente, ubicacion } = data.values;
    api.put(`${endpoint}/${id}`, { ...item, nombre, cliente, ubicacion }, {actualizar}).then((response) => {
        dispatch(parseItem(response));
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crearCliente = (data, formulario) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post("clientes", data).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        const form = getStore().form[formulario].values;
        if (form) {
            const presupuesto = _.cloneDeep(form);
            presupuesto.cliente = response;
            dispatch(initializeForm(formulario, presupuesto));
        } else {
            dispatch(initializeForm(formulario, {cliente: response}));
        }
    }).catch((e) => {
        console.log(e);
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const descargar = id => (dispatch) => {
    window.location.replace(`/api/${endpoint}/${id}/descargar?auth_token=${sessionStorage.getItem("token")}`);
};

export const inicializarNuevoRenglon = () => (dispatch) => {
    if (resumenes.length > 0) {
        dispatch(initializeForm("CrearRenglonForm", {resumen: resumenes[0]}));
        dispatch(initializeForm("AgregarMaterialForm", {resumen: resumenes[0]}));
    }
};

export const activar = (id, data) => (dispatch) => {
    Swal.fire({
        title: '¿Pasar a proyecto activo?',
        text: '¡Ya no podrás cambiar los precios asignados!',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: '¡Sí, activar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/activar`, {...data, responsable: data.username.id}).then(() => {
                NotificationManager.success('Proyecto activado', 'Éxito', 3000);
                dispatch(push(`/proyectos/${id}`))
            }).catch(() => {
                NotificationManager.error('Error, verifica que todos los precios han sido asignados', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            })
        }
    });
};

export const agregarRenglon = renglon => (dispatch, getStore) => {
    const item = _.cloneDeep(getStore().presupuestos.item);
    if (!renglon.material) {
        if (!_.find(resumenes, {id: renglon.resumen.id})) {
            resumenes.push(renglon.resumen);
        }
        renglon.renglon.detalle.forEach((detail) => {
            let proveedor;
            item.renglones.forEach((objeto) => {
                objeto.detalle.forEach((detalle) => {
                    if(detalle.material.id === detail.material.id)
                        if (!!detalle.material.proveedor)
                            proveedor = detalle.material.proveedor;
                });
            });
            if (!!proveedor)
                detail.material.proveedor = proveedor;
        });
        item.renglones.push({...renglon.renglon, resumen: renglon.resumen, index: item.renglones.length, cantidad: renglon.cantidad});
    } else {
        let adicionales = _.find(item.renglones, {nombre: "Adicionales"});
        let proveedor;
        item.renglones.forEach((objeto) => {
            objeto.detalle.forEach((detalle) => {
                if(detalle.material.id === renglon.material.id)
                    if (!!detalle.material.proveedor)
                        proveedor = detalle.material.proveedor;
            });
        });
        if (!!proveedor) {
            renglon.material.proveedor = proveedor;
            renglon.material.costo = proveedor.costo;
        }
        if (adicionales) {
            adicionales.detalle.push({...renglon.material, material:renglon.material, cantidad: renglon.cantidad});
        } else {
            adicionales = {
                detalle: [{...renglon.material, material:renglon.material, cantidad: renglon.cantidad}],
                nombre: "Adicionales",
                unidad: "Unidad",
                cantidad: 1,
            };
            item.renglones.push({...adicionales, resumen: renglon.resumen, index: item.renglones.length});
        }
    }
    dispatch(setItem(item));
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().presupuestos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const cambioValor = (campo, valor) => (dispatch, getStore) => {
    const { presupuestos } = getStore();
    const item = _.cloneDeep(presupuestos.item);
    item[campo] = valor;
    dispatch(setItem(item));
};

export const cambioRenglon = (index, campo, valor) => (dispatch, getStore) => {
    const { presupuestos } = getStore();
    const item = _.cloneDeep(presupuestos.item);
    const renglones = item.renglones;
    renglones[index][campo] = valor;
    dispatch(setItem(item));
};

export const cambioDetalle = (index, subIndex, campo, valor) => (dispatch, getStore) => {
    const { presupuestos } = getStore();
    const item = _.cloneDeep(presupuestos.item);
    const renglones = item.renglones;
    renglones[index].detalle[subIndex][campo] = valor;
    if (campo === 'material') {
        renglones[index].detalle[subIndex].porcentaje_imprevisto = 0;
        renglones[index].detalle[subIndex].cantidad = 0;
    }
    dispatch(setItem(item));
};

export const quitarDetalle = (index, subIndex) => (dispatch, getStore) => {
    const { presupuestos } = getStore();
    const item = _.cloneDeep(presupuestos.item);
    const renglones = item.renglones;
    renglones[index].detalle.splice(subIndex, 1);
    dispatch(setItem(item));
};

export const agregarDetalle = (index, valor, es_herramienta=false) => (dispatch, getStore) => {
    const { presupuestos } = getStore();
    const item = _.cloneDeep(presupuestos.item);
    const renglones = item.renglones;
    renglones[index].detalle.push({...valor, material: valor, es_herramienta});
    dispatch(setItem(item));
};

export const setProveedor = (material, valor, proyecto) => (dispatch, getStore) => {
    if (proyecto) {
        const { proyectos } = getStore();
        const item = _.cloneDeep(proyectos.item);
        const renglones = item.renglones;
        renglones.forEach((renglon) => {
            _.filter(renglon.detalle, function (o) {
                return o.material.id === material.id;
            }).forEach((item) => {
                    item.material.proveedor = valor;
                });
        });
        dispatch(proyectoActions.setItem(item));
    } else {
        const { presupuestos } = getStore();
        const item = _.cloneDeep(presupuestos.item);
        const renglones = item.renglones;
        renglones.forEach((renglon) => {
            _.filter(renglon.detalle, function (o) {
                return o.material.id === material.id;
            }).forEach((item) => {
                    item.material.proveedor = valor;
                });
        });
        dispatch(setItem(item));
    }
};

export const inicialResponsable = () => (dispatch) => {
    dispatch(initializeForm("Responsable", {fecha_inicio: moment(new Date()).format("YYYY-MM-DD")}));
};


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    descargar,
    searchChange,
    agregarRenglon,
    cambioRenglon,
    cambioDetalle,
    quitarDetalle,
    agregarDetalle,
    tabChange,
    sortChange,
    setProveedor,
    cambioValor,
    activar,
    inicialResponsable,
    inicializarNuevoRenglon,
    crearCliente,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
    [TAB]: (state, { tab }) => {
        updateExpiracion();
        return {
            ...state,
            tab,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {
        nombre: "",
        responsable: {},
        renglones: [
            {detalle: []},
        ],
        cliente: {
            nombre: ""
        },
        mano_obra: "",
    },
    page: 1,
    tab: "1",
    ordering: '',
    search: '',
    uuid: '',
};

export default handleActions(reducers, initialState);
