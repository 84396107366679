import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { icons } from "fotos";
import LoginForm from './LoginForm';
import './login.css';


class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { extend: false, show: false };
    }

    componentDidMount() {
        setTimeout(this.cambioCollapsed, 1000);
    }

    getClase = () => {
        const { extend, show } = this.state;
        if (show) {
            return "d-flex flex-column justify-content-center align-items-center flex-2 extend show";
        }
        if (extend) {
            return "d-flex flex-column justify-content-center align-items-center flex-2 extend";
        }
        return "d-flex flex-column justify-content-center align-items-center flex-2";
    };

    cambioHidden = () => {
        this.setState({ show: true });
    };

    cambioCollapsed = () => {
        this.setState({ extend: true });
        setTimeout(this.cambioHidden, 700);
    };

    render() {
        const { onSubmit } = this.props;
        // if (localStorage.getItem('token')) {
        //     return (<Redirect to="/dashboard" />);
        // }
        return (
            <div id="login" className="d-flex flex-column-reverse flex-lg-row justify-content-around align-items-center w-100">
                <div className={this.getClase()}>
                    <LoginForm onSubmit={onSubmit} />
                </div>
                <div className="flex-1 d-flex align-items-center">
                    <img src={icons.logoBlanco} alt="" />
                </div>
            </div>
        );
    }
}

export default Login;
