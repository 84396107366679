/**
 * Funcion para obtener un listado de barras que se deben usar para cubrir la longitud requerida
 * de una serie de No de barras, esta información se obtiene a partir de las longitudes de las
 * barras disponibles, la longitud a cubrir y un arreglo de empalmes (de este se asume los diferentes
 * números de barras que se están usando)
 * @param longitudes: array: arreglo de longitudes disponibles
 * @param longitud: string number: longitud en formato string que se debe llegar a cubrir
 * @param empalmes: array: arreglo de longitudes de empalmes según los diferentes no. de barra usados
 * @return dict: objeto con cada llave siendo el empalme al que le corresponde la cantidad de barras
 * necesarias de cada longitud disponible, cada empalme corresponde a un no, de barra, ahí se hace el match
 * */
export const getLongitudesBarras = (longitudes, longitud, empalmes) => {
    longitudes = longitudes.sort((a, b) => (a - b));
    const resultado = {};
    empalmes.forEach((empalme) => {
        empalme = parseFloat(empalme);
        const totales = {};
        let _longitud = longitud;
        let cont = 0;
        if (!!_longitud && !!longitudes.length) {
            while (_longitud > 0 && cont < 100) {
                cont += 1;
                let agregado = false;
                longitudes.forEach((item) => {
                    if (item >= _longitud && !agregado) {
                        agregado = true;
                        _longitud = 0;
                        if (totales[item]) {
                            totales[item] += 1;
                        } else {
                            totales[item] = 1;
                        }
                    }
                });
                if (!agregado) {
                    _longitud = (_longitud - longitudes[longitudes.length - 1]) + empalme;
                    if (totales[longitudes[longitudes.length - 1]]) {
                        totales[longitudes[longitudes.length - 1]] += 1;
                    } else {
                        totales[longitudes[longitudes.length - 1]] = 1;
                    }
                }
            }
        }
        resultado[empalme.toFixed(2)] = totales;
    });
    return resultado;
};

/**
 * Funcion para obtener un listado de barras que se deben usar para cubrir la longitud requerida
 * de una serie de No de barras, esta información se obtiene a partir de las longitudes de las
 * barras disponibles, la longitud a cubrir y la cantidad de barras de esa longitud que se tienen que hacer
 * ** se reutilizó esta funcion para calcular para los bastones y para calcular las barras de las camas
 * @param longitudes: array: arreglo de longitudes disponibles
 * @param longitud_barra: string number: longitud en formato string que se debe llegar a cubrir
 * @param cantidad: la cantidad de barras que se deben cubrir
 * @return dict: objeto con cada cantidad de barras necesarias de cada longitud disponible,
 * */
export const getLongitudesEstribos = (longitudes, longitud_barra, cantidad) => {
    longitudes = longitudes.sort((a, b) => (a - b));
    const totales = {};
    let longitud = longitud_barra * cantidad;
    let cont = 0;
    if (!!longitud && !!longitudes.length) {
        while (longitud > 0 && cont < 100) {
            cont += 1;
            let agregado = false;
            longitudes.forEach((item) => {
                if (item >= longitud && !agregado) {
                    agregado = true;
                    longitud = 0;
                    if (totales[item]) {
                        totales[item] += 1;
                    } else {
                        totales[item] = 1;
                    }
                }
            });
            if (!agregado) {
                let disponible = Math.floor(longitudes[longitudes.length - 1] / longitud_barra);
                let usadas = 1;
                let cont2 = 0;
                while (disponible === 0 && cont2 < 100) {
                    usadas += 1;
                    disponible = Math.floor((longitudes[longitudes.length - 1] * usadas) / longitud_barra);
                    cont2 += 1;
                }
                longitud -= disponible * longitud_barra;

                if (totales[longitudes[longitudes.length - 1]]) {
                    totales[longitudes[longitudes.length - 1]] += usadas;
                } else {
                    totales[longitudes[longitudes.length - 1]] = usadas;
                }
            }
        }
    }
    return totales;
};
