import React, { Component } from 'react';


class Resumen extends Component {

    render() {
        const { item } = this.props;

        return (
            <div className="d-flex flex-column flex-md-row mb-2 align-items-end">
                <div className="header-1 azul text-uppercase">{item.nombre}&nbsp;&nbsp;<span className="gris paragraph">{item.estado}</span></div>
                <div className="paragraph-bold gris mb-1 ml-2 ml-md-4">CLIENTE&nbsp;<span className="paragraph azul text-uppercase">{item.cliente.nombre}</span></div>
                <div className="paragraph-bold gris mb-1 ml-2 ml-md-4">UBICACIÓN&nbsp;<span className="paragraph azul text-uppercase">{item.ubicacion}</span></div>
            </div>
        );
    }
}

export default Resumen;
