import _ from "lodash";

const validate = values => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = 'Campo requerido';
    }
    if (!values.unidad) {
        errors.unidad = 'Campo requerido';
    }
    if (!values.unidad_minima) {
        errors.unidad_minima = 'Campo requerido';
    }
    if (!values.detalle || !values.detalle.length) {
        errors.detalle = { _error: 'Debe ingresar al menos un material' }
    } else {
        const detalleArrayErrors = [];
        values.detalle.forEach((material, materialIndex) => {
            const materialErrors = {};
            if (!material || !material.material_id) {
                materialErrors.material_id = 'Campo requerido';
                detalleArrayErrors[materialIndex] = materialErrors;
            }
            if (!material || !material.cantidad) {
                materialErrors.cantidad = 'Campo requerido';
                detalleArrayErrors[materialIndex] = materialErrors;
            }
            if (!material || (!material.porcentaje_imprevisto && material.porcentaje_imprevisto !== 0)) {
                materialErrors.porcentaje_imprevisto = 'Campo requerido';
                detalleArrayErrors[materialIndex] = materialErrors;
            }
            if (material && _.filter(values.detalle, {material_id: material.material_id}).length > 1) {
                materialErrors.material = 'Material duplicado';
                detalleArrayErrors[materialIndex] = materialErrors;
            }
        });
        if (detalleArrayErrors.length) {
            errors.detalle = detalleArrayErrors
        }
    }
    return errors
};

export default validate
