import { COLUMNA, VIGA, ZAPATA, CIMIENTO_CORRIDO } from "../../../../utility/variables";

const validate = values => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = 'Campo requerido';
    }
    if (!values.tipo_especial) {
        errors.tipo_especial = 'Campo requerido';
    }
    if (!values.ancho) {
        errors.ancho = 'Campo requerido';
    }
    if (!values.largo) {
        errors.largo = 'Campo requerido';
    }
    if (!values.alto) {
        errors.alto = 'Campo requerido';
    }
    if (!values.concreto) {
        errors.concreto = 'Campo requerido';
    }
    // VALIDACION SEGUN EL TIPO DE PLANTILLA (COLUMNA, VIGA, ZAPATA, CIMIENTO CORRIDO)
    if (!!values.tipo_especial) {
        if (values.tipo_especial === COLUMNA) {
            if (!values.recubrimiento) {
                errors.recubrimiento = 'Campo requerido';
            }
            if (!values.longitudes) {
                errors.longitudes = 'Campo requerido';
            }
            if (!values.no_barras) {
                errors.no_barras = 'Campo requerido';
            }
            if (!values.cant_barras) {
                errors.cant_barras = 'Campo requerido';
            }
            if (!values.long_gancho) {
                errors.long_gancho = 'Campo requerido';
            }
            if (!values.sep_conf) {
                errors.sep_conf= 'Campo requerido';
            }
            if (!values.sep_nconf) {
                errors.sep_nconf= 'Campo requerido';
            }
            if (!values.no_barras_estribos) {
                errors.no_barras_estribos= 'Campo requerido';
            }
        } else if (values.tipo_especial === VIGA ) {
            if (!values.recubrimiento) {
                errors.recubrimiento = 'Campo requerido';
            }
            if (!values.longitudes) {
                errors.longitudes = 'Campo requerido';
            }
            if (!values.no_barras) {
                errors.no_barras = 'Campo requerido';
            }
            if (!values.cant_barras) {
                errors.cant_barras = 'Campo requerido';
            }
            if (!values.long_union) {
                errors.long_union = 'Campo requerido';
            }
            if (!values.barras_union) {
                errors.barras_union= 'Campo requerido';
            }
            if (!values.no_barras_union) {
                errors.no_barras_union = 'Campo requerido';
            }
            if (!values.long_gancho) {
                errors.long_gancho = 'Campo requerido';
            }
            if (!values.sep_conf) {
                errors.sep_conf= 'Campo requerido';
            }
            if (!values.sep_nconf) {
                errors.sep_nconf= 'Campo requerido';
            }
            if (!values.no_barras_estribos) {
                errors.no_barras_estribos= 'Campo requerido';
            }
        } else if (values.tipo_especial === ZAPATA) {
            if (!values.recubrimiento) {
                errors.recubrimiento = 'Campo requerido';
            }
            if (!values.camas) {
                errors.camas = 'Campo requerido';
            }
            if (!values.longitudes) {
                errors.longitudes = 'Campo requerido';
            }
            if (!values.separacion_a) {
                errors.separacion_a = 'Campo requerido';
            }
            if (!values.separacion_b) {
                errors.separacion_b = 'Campo requerido';
            }
            if (!values.barras_a) {
                errors.barras_a = 'Campo requerido';
            }
            if (!values.barras_b) {
                errors.barras_b = 'Campo requerido';
            }
        } else if (values.tipo_especial === CIMIENTO_CORRIDO) {
            if (!values.recubrimiento) {
                errors.recubrimiento = 'Campo requerido';
            }
            if (!values.longitudes) {
                errors.longitudes = 'Campo requerido';
            }
            if (!values.no_barras) {
                errors.no_barras = 'Campo requerido';
            }
            if (!values.cant_barras) {
                errors.cant_barras = 'Campo requerido';
            }
            if (!values.long_gancho) {
                errors.long_gancho = 'Campo requerido';
            }
            if (!values.sep_conf) {
                errors.sep_conf= 'Campo requerido';
            }
            if (!values.sep_nconf) {
                errors.sep_nconf= 'Campo requerido';
            }
            if (!values.no_barras_estribos) {
                errors.no_barras_estribos= 'Campo requerido';
            }
        }
    }
    return errors
};

export default validate;
