import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import {
    renderNumber,
    renderAsync,
    renderMultiAsync,
    renderMultiSelectField,
} from "Utils/renderField/renderField";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { getBarras, LONGITUDES } from "utility/variables";
import { getLongitudesEstribos, getLongitudesBarras } from "./funciones";
import { renderBarras } from "./Columna";


class CimientoCorrido extends Component {
    render() {
        const {
            sep_conf,
            sep_nconf,
            alto,
            ancho,
            largo,
            long_gancho,
            no_barras,
            no_barras_estribos,
            recubrimiento,
            longitudes,
            cant_barras,
            espacio_conf,
        } = this.props;
        const confinado = parseFloat(espacio_conf);
        const no_confinado = (!!largo && !!espacio_conf) ? largo - confinado : 0;
        let est_conf = !!parseFloat(sep_conf) ? confinado / sep_conf : 0;
        let est_nconf = !!parseFloat(sep_nconf) ? no_confinado / sep_nconf : 0;
        est_conf = Math.ceil(est_conf);
        est_nconf = Math.ceil(est_nconf);
        const estribos = est_conf + est_nconf;
        const long_estribos = (alto - recubrimiento) * 2 + (largo - recubrimiento) * 2 + long_gancho * 2;
        const barras_estribos = getLongitudesEstribos(longitudes.map((item) => item.value), long_estribos, estribos);
        const empalmes = [];
        if (cant_barras)
        cant_barras.forEach((_cant_barras) => {
            empalmes.push(_cant_barras.empalme);
        });
        const barras = getLongitudesBarras(longitudes.map((item) => item.value), alto, empalmes);
        return (
            <div className="d-flex flex-column">
                {/*BARRAS*/}
                <div className="form-container" style={{
                    marginTop: "0",
                    marginBottom: "0",
                }}>
                    <div className="d-flex flex-column justify-content-center form-content">
                        <div className="d-flex flex-column flex-2">
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="recubrimiento">Recubrimiento*</label>
                                    <Field name="recubrimiento" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4" />
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="longitudes">Longitudes Disponibles*</label>
                                    <Field
                                        name="longitudes"
                                        options={LONGITUDES}
                                        component={renderMultiSelectField}
                                        labelKey={"label"}
                                        valueKey={"value"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4">
                                    <label htmlFor="no_barras">No. Barras*</label>
                                    <Field
                                        name="no_barras"
                                        loadOptions={getBarras}
                                        component={renderMultiAsync}
                                        labelKey={"nombre"}
                                        valueKey={"id"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                            </div>
                            <FieldArray component={renderBarras} no_barras={no_barras} cant_barras={cant_barras} name="cant_barras" barras={barras} />
                        </div>
                    </div>
                </div>
                {/*ESTRIBOS*/}
                <div className="form-container" style={{
                    marginTop: "0",
                    marginBottom: "0",
                }}>
                    <div className="d-flex flex-column justify-content-center form-content">
                        <div className="d-flex flex-column flex-2 justify-content-end">
                            <div className="small-font azul pl-2 pr-2 pl-md-4 pr-md-4">ESTRIBOS</div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="long_gancho">Longitud de ganchos*</label>
                                    <Field name="long_gancho" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4">
                                    <label htmlFor="espacio_conf">Espacio Confinado*</label>
                                    <Field name="espacio_conf" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="sep_conf">Separación Confinado*</label>
                                    <Field name="sep_conf" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4">
                                    <label htmlFor="sep_nconf">Separación no Confinado*</label>
                                    <Field name="sep_nconf" component={renderNumber} decimalScale={3} suffix="m"
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="est_conf">Estribos en Confinado*</label>
                                    <RenderNumber value={est_conf} className="gris"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-3">
                                    <label htmlFor="est_nconf">Estribos en no Confinado*</label>
                                    <RenderNumber value={est_nconf} className="gris"/>
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-3">
                                    <label htmlFor="estribos">Total de estribos*</label>
                                    <RenderNumber value={est_nconf + est_conf} className="gris"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-2 justify-content-end">
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="no_barras_estribos">No. Barras*</label>
                                    <Field
                                        name="no_barras_estribos"
                                        loadOptions={getBarras}
                                        component={renderAsync}
                                        labelKey={"nombre"}
                                        valueKey={"id"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-3" />
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-4" />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-2 justify-content-end">
                            <div className="small-font azul pl-2 pr-2 pl-md-4 pr-md-4">BARRAS PARA ESTRIBOS</div>
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-end">
                                {Object.keys(barras_estribos).map((key) => {
                                    return <div key={key} className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                        <label>Barras de {key}m</label>
                                        <RenderNumber value={barras_estribos[key]} suffix={` barras ${no_barras_estribos.nombre ? no_barras_estribos.nombre : ""}`} className="gris paragraph"/>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CimientoCorrido;
