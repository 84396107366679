import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import EditarForm from "./Form";
import { tableOptions } from "Utils/tableOptions";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import { TIPO_MOVIMIENTO } from "utility/variables";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, { TabPane } from "rc-tabs";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderDate } from "Utils/renderField/renderReadField";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import DayPicker from "Utils/DatePicker";
import { RenderCurrency } from "../../Utils/renderField/renderReadField";


class DetalleVehiculo extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { tab: "1" };
    }

    tabChange = tab => {
        this.setState({tab});
    };

    componentWillMount() {
        const { leer, match, listarGastos } = this.props;
        leer(match.params.id);
        listarGastos(1, match.params.id);
    }

    editar = (data) => {
        const { editar, match } = this.props;
        editar(match.params.id, data);
        this.setState({tab: "1"})
    };

    render() {
        const { loader, item, gastos, listarGastos, sortChangeGastos, loaderGastos, pageGastos, saldo } = this.props;
        const { fechaInicial, fechaFinal, setDate } = this.props;
        const { tab } = this.state;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loaderGastos ? 'Cargando...' : options.noDataText;
        options.page = pageGastos;
        options.onPageChange = listarGastos;
        options.onSortChange = sortChangeGastos;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <div className="form-head small-font">Detalle del Vehículo</div>
                    <LoadMask loading={loader} light>
                        <div className="d-flex flex-column flex-md-row justify-content-around">
                            <div className="d-flex flex-row form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="nombre">VEHICULO</label>
                                <div className="azul paragraph-bold">{item.nombre}</div>
                            </div>
                            <div className="d-flex flex-row form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="placa">PLACA</label>
                                <div className="azul paragraph-bold">{item.placa}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-around">
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="descripcion">DESCRIPCIÓN</label>
                                <div className="azul paragraph-bold">{item.descripcion}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-lg-row my-4">
                            <div className="fondo_fase flex-3 d-flex justify-content-around mx-1 mx-lg-4">
                                <div className="d-flex text-right flex-column flex-sm-row bg-white border align-self-center">
                                    <div className="header-2 gris py-1 px-3 ml-auto">
                                        Ingresos&nbsp;&nbsp;
                                        <RenderCurrency className="header-1 azul" value={saldo.ingresos} />
                                    </div>
                                    <div className="header-2 gris py-1 px-3 ml-auto">
                                        Egresos&nbsp;&nbsp;
                                            <RenderCurrency className="header-1 azul" value={saldo.egresos} />
                                    </div>
                                    <div className="header-2 gris py-1 px-3 ml-auto">
                                        Saldo&nbsp;&nbsp;
                                            <RenderCurrency className="header-1 azul" value={saldo.ingresos - saldo.egresos} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <div className="align-self-center border">
                                    <img src={item.foto} alt="" className="" style={{maxHeight: "75px"}}/>
                                </div>
                            </div>
                        </div>
                        <Tabs
                            activeKey={tab}
                            onChange={this.tabChange}
                            renderTabBar={()=><ScrollableInkTabBar />}
                            renderTabContent={()=><TabContent />}
                        >
                            <TabPane tab="Movimiento de efectivo" key="1">
                                <div style={{minHeight: "300px"}}>
                                    <div className="d-flex flex-column flex-sm-row">
                                        <div className="d-flex flex-column p-2">
                                            <div className="paragraph-bold gris">Fecha Inicial</div>
                                            <DayPicker value={fechaInicial} onChange={(value) => setDate(value, "inicial")} />
                                        </div>
                                        <div className="d-flex flex-column p-2">
                                            <div className="paragraph-bold gris">Fecha Final</div>
                                            <DayPicker value={fechaFinal} onChange={(value) => setDate(value, "final")} />
                                        </div>
                                    </div>
                                    <BootstrapTable
                                        data={gastos.results}
                                        remote
                                        striped
                                        bordered={false}
                                        hover
                                        fetchInfo={{ dataTotalSize: gastos.count }}
                                        options={options}
                                        pagination
                                    >
                                        <TableHeaderColumn
                                            dataField="fecha"
                                            dataSort
                                            dataFormat={(cell) => <RenderDate value={cell} />}
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="tipo"
                                            dataSort
                                            dataFormat={(cell) => {
                                                return _.find(TIPO_MOVIMIENTO, {value: cell}).label;
                                            }}
                                        >
                                            Tipo
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="monto"
                                            dataFormat={(cell) => <RenderMoneda monto={cell} decimalScale={2} />}
                                            dataSort
                                            dataAlign="right"
                                        >
                                            Monto
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="descripcion"
                                            dataSort
                                            dataAlign="right"
                                        >
                                            Descripción
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataSort
                                            isKey
                                            width={"0%"}
                                        />
                                    </BootstrapTable>
                                </div>
                            </TabPane>
                            <TabPane tab="AGREGAR MOVIMIENTO" key="2">
                                <EditarForm onSubmit={this.editar} item={item} />
                            </TabPane>
                        </Tabs>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default DetalleVehiculo;
