import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderImage } from "Utils/renderField/renderTableField";
import { StandardActions } from "Utils/TableActions";
import LoadMask from "Utils/LoadMask";
import Buscador from "Utils/Buscador";
import { bajas } from "./Bajas";
import { icons } from "../../../../utility/fotos";


class ListadoVehiculos extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { loader, data, page, listar, sortChange, search, searchChange, eliminar } = this.props;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        options.onSortChange = sortChange;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="d-flex align-self-end align-items-center mr-2 mr-md-5 mb-3">
                    <div className="azul paragraph-2 mr-4">
                        Seleccione para agregar!
                    </div>
                    <Link className="btn btn-primary" to="vehiculo/crear">Nuevo Vehículo</Link>
                </div>
                <LoadMask loading={loader} light>
                    <div className="cuadro">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="header-1 azul">VEHÍCULO</div>
                            <Buscador search={search} searchChange={searchChange} />
                        </div>
                        <BootstrapTable
                            data={data.results}
                            remote
                            striped
                            expandableRow={() => bajas.length > 0}
                            expandComponent={bajas}
                            bordered={false}
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            pagination
                        >
                            <TableHeaderColumn
                                dataField="foto"
                                dataAlign="center"
                                dataFormat={(cell) => <RenderImage src={cell} />}
                            >
                                Foto
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                            >
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="placa"
                                dataSort
                            >
                                Placa
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estado"
                                dataFormat={(cell) => (cell ? "Sí" : "No")}
                                dataSort
                            >
                                Disponible
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                            >
                                Descripción
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                dataAlign="center"
                                dataFormat={(cell) => {
                                    return (<div className="row acciones">
                                        <div className="col-12">
                                            <Link to={`vehiculo/${cell}/`} ><img className="action-img" title="Detalle" src={icons.detalle} alt="Detalle"/></Link>
                                            <Link to={`vehiculo/${cell}/editar`} ><img className="action-img" title="Eliminar" src={icons.eliminar} alt="Eliminar"/></Link>
                                        </div>
                                    </div>)
                                }}
                            />
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default ListadoVehiculos;
