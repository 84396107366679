import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/proveedores/proveedores';
import ListadoProveedores from './ListadoProveedores';


const ms2p = (state) => {
    return {
        ...state.proveedores,
        me: state.login.me
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoProveedores);
