import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import alquiler from './modules/maquinaria_vehiculos/alquiler';
import bitacora from './modules/bitacora/bitacora';
import barras from './modules/materiales/barras';
import clientes from './modules/clientes/clientes';
import concretos from './modules/plantillas/concretos';
import login from './modules/login';
import materiales from './modules/materiales/materiales';
import maquinaria from './modules/maquinaria_vehiculos/maquinaria';
import vehiculo from './modules/maquinaria_vehiculos/vehiculo';
import plantillas from './modules/plantillas/plantillas';
import presupuestos from './modules/presupuestos/presupuestos';
import proveedores from './modules/proveedores/proveedores';
import proyectos from './modules/proyectos/proyectos';
import usuarios from './modules/usuarios/usuarios';


export default combineReducers({
    form: formReducer,
    login,
    alquiler,
    barras,
    bitacora,
    clientes,
    concretos,
    materiales,
    maquinaria,
    vehiculo,
    plantillas,
    presupuestos,
    proveedores,
    proyectos,
    usuarios,
    routing,
});
