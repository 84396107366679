import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero } from "Utils/renderField/renderTableField";
import { Link } from "react-router-dom";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, { TabPane } from "rc-tabs";
import Bajas from "./Baja/ListadoBajas";
import { SE } from "utility/variables";


class Inventario extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        item: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {nuevoPago: false, tab: "1"};
    }

    tabChange = (tab) => {
        this.setState({tab});
    };

    cancelar = () => {
        this.setState({nuevoPago: false});
    };

    registrarPago = (data) => {
        const { registrarPago } = this.props;
        registrarPago(data);
        this.setState({nuevoPago: false});
    };

    render() {
        const { item, match, me } = this.props;
        const { tab } = this.state;

        const options = _.cloneDeep(tableOptions);

        return (
            <div className="p-2 p-md-4 d-flex flex-column">
                { me.perfil.rol !== SE && (<Link className="btn btn-default ml-auto" to={`/proyectos/${match.params.id}/baja`}>Reportar Baja</Link>)}
                <Tabs
                    activeKey={tab}
                    onChange={this.tabChange}
                    renderTabBar={()=><ScrollableInkTabBar />}
                    renderTabContent={()=><TabContent />}
                >
                    <TabPane tab="INVENTARIO" key="1" >
                        <BootstrapTable
                        data={item.inventario}
                        striped
                        bordered={false}
                        hover
                        options={options}
                    >
                        <TableHeaderColumn
                            dataField="nombre_material"
                            dataSort
                        >
                            Material
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="unidad_material"
                        >
                            Unidad
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataAlign="right"
                            dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                            dataSort
                        >
                            Existencia
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            width={"0%"}
                        />
                    </BootstrapTable>
                    </TabPane>
                    <TabPane tab="BAJAS" key="2" >
                        <Bajas {...this.props} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default Inventario;
