import React from 'react';
import { reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { Form } from "../Form";


const CrearForm = reduxForm({
    // a unique name for the form
    form: 'CrearPresupuestoForm',
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
            cliente: validators.exists()("Campo requerido"),
            ubicacion: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default CrearForm;
