import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSelectField } from "Utils/renderField/renderField";
import { ROLES } from "utility/variables";
import { Link } from "react-router-dom";


const Form = (props) => {
    const { handleSubmit, verPass, cambiarPass } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">Mi Perfil</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="username">Nombre Usuario*</label>
                            <Field name="username" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="email">Correo*</label>
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="telefono">Teléfono*</label>
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        {verPass ? (
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="password">Contraseña*</label>
                                <Field name="password" component={renderField} type="text" className="form-control" />
                            </div>
                        ) : (
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4 align-items-end justify-content-end">
                                <button type="button" onClick={cambiarPass} className="btn btn-default paragraph">Cambiar Contraseña</button>
                            </div>
                        )}
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center flex-row mt-3">
                <button type="submit" className="btn btn-primary m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};

const PerfilForm = reduxForm({
    // a unique name for the form
    form: 'PerfilForm',
    validate: (data) => {
        let email = validators.exists()("Campo requerido");
        if (data.email) {
          email = validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)('Debe ser un correo válido');
        }
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
            username: validators.exists()("Campo requerido"),
            email,
            rol: validators.exists()("Campo requerido"),
            telefono: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default PerfilForm;
