import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/plantillas/concretos';
import ListadoConcreto from './ListadoConcreto';


const ms2p = (state) => {
    return {
        ...state.concretos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoConcreto);
