import React from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {renderField, renderNumber, renderDatePicker} from "Utils/renderField/renderField";
import {validate} from "validate-redux-form";


const renderDetalle = ({ fields, meta, meta: {error, submitFailed} }) => (
    <div className="d-flex flex-column justify-content-center">
        <div className="d-block d-lg-none">
            {fields.map((material, index) => {
                const field = fields.get(index);
                return (
                    <div key={index} className={`d-flex renglon-${index%2}`}>
                        <div className="d-flex flex-column justify-content-around align-items-stretch">
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor={`${material}.material_id`}>Gasto/Material</label>
                                {field.tipo === 1 ? (
                                    <Field name={`${material}.nombre_material`} disabled component={renderField} type="text" className="form-control" />
                                ) : (
                                    <Field name={`${material}.gasto`} disabled component={renderField} type="text" className="form-control" />
                                )}
                            </div>
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="unidad_material">Unidad</label>
                                <Field name={`${material}.unidad_material`} component={renderField} disabled type="text" className="form-control" />
                            </div>
                            {/*<div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="entregado">Cantidad ya entregada</label>
                                <Field name={`${material}.entregado`} component={renderField} disabled type="text" className="form-control" />
                            </div>*/}
                            <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                                <label htmlFor="cantidad">Cantidad*</label>
                                <Field name={`${material}.cantidad`} component={renderNumber} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                )})}
        </div>
        <div className="react-bs-table">
            <table className="d-none d-lg-block">
                <thead>
                <tr>
                    <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "25%" }}>Gasto/Material</th>
                    <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "25%" }}>Unidad</th>
                    {/*<th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "25%" }}>Ya entregado</th>*/}
                    <th className="pr-2 pl-2 pt-1 pb-1" style={{ width: "25%" }}>Cantidad*</th>
                </tr>
                </thead>
                <tbody>
                {fields.map((material, index) => {
                    const field = fields.get(index);
                    return (
                    <tr key={index} className={`renglon-${index%2}`}>
                        <td className="p-2">
                            {field.tipo === 1 ? (
                                <Field name={`${material}.nombre_material`} disabled component={renderField} type="text" className="form-control" />
                            ) : (
                                <Field name={`${material}.gasto`} disabled component={renderField} type="text" className="form-control" />
                            )}
                        </td>
                        <td className="p-2">
                            <Field name={`${material}.unidad_material`} component={renderField} disabled type="text" className="form-control" /></td>
                        {/*<td className="p-2">
                            <Field name={`${material}.entregado`} component={renderField} disabled type="text" className="form-control" /></td>*/}
                        <td className="p-2">
                            <Field name={`${material}.cantidad`} component={renderNumber} type="text" className="form-control" /></td>
                    </tr>
                )})}
                </tbody>
            </table>
        </div>
        {submitFailed && (
            <div className="invalid-feedback d-block pl-4">
                {error}
            </div>
        )}
    </div>
);


export const Form = (props) => {
    const { handleSubmit, cancelar } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row ">
                        <div className="d-flex flex-column form-group m-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="fecha">Fecha*</label>
                            <Field
                                name="fecha"
                                component={renderDatePicker}
                            />
                        </div>
                        <div className="d-flex flex-column form-group m-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="observaciones">Observaciones</label>
                            <Field
                                name="observaciones"
                                component={renderField}
                            />
                        </div>
                    </div>
                </div>
                <FieldArray
                    name="detalle"
                    component={renderDetalle}
                />
            </div>
            <div className="d-flex justify-content-start flex-row-reverse mt-1 mb-3 pr-3">
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
                <button onClick={cancelar} className="btn btn-secondary ml-1 mr-1">Cancelar</button>
            </div>
        </form>
    );
};

const NuevaEntregaForm = reduxForm({
    // a unique name for the form
    form: 'NuevaEntregaForm',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default NuevaEntregaForm;
