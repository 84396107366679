import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderImagePicker, renderTextArea, renderNumber } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";
import { crearForm } from "../../../../redux/modules/maquinaria_vehiculos/maquinaria";


const Form = (props) => {
    const { handleSubmit, titulo, foto, setFile } = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container">
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="placa">Placa*</label>
                            <Field name="placa" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                component={renderTextArea}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="foto">Foto*</label>
                            <Field
                                name="foto"
                                setFile={setFile}
                                photo={foto}
                                component={renderImagePicker}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-start flex-row-reverse mt-3 pr-3">
                <button type="submit" className="btn btn-primary ml-1">Guardar</button>
                <Link to={"/maquinaria"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
            </div>
        </form>
    );
};


const CrearForm = reduxForm({
    // a unique name for the form
    form: crearForm,
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Campo requerido"),
            placa: validators.exists()("Campo requerido"),
            descripcion: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default CrearForm;
