import React from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import Login from './common/components/Login/LoginContainer';

import ProtectedRoute from './ProtectedRoute';

import '../assets/fonts/fonts.css';
import Home from "./common/components/Home/Home";
import { NotificationContainer } from 'react-notifications';


import { ListadoBitacora, DetalleBitacora } from "./common/components/Bitacora";
import { CrearAlquiler, ListadoAlquileres, EditarAlquiler } from "./common/components/Maquinaria/Alquiler";
import { CrearUsuario, EditarUsuario, ListadoUsuarios, Perfil } from "./common/components/Usuarios";
import { CrearMaterial, EditarMaterial, ListadoMateriales } from "./common/components/Materiales";
import { CrearMaquinaria, EditarMaquinaria, ListadoMaquinaria, DetalleMaquinaria } from "./common/components/Maquinaria";
import { CrearVehiculo, EditarVehiculo, ListadoVehiculos, DetalleVehiculo } from "./common/components/Vehiculos";
import { CrearBarra, EditarBarra, ListadoBarras } from "./common/components/Barras";
import { CrearPlantilla, CrearPlantillaEspecial, EditarPlantilla, ListadoPlantillas, EditarPlantillaEspecial } from "./common/components/Plantillas";
import { CrearConcreto, EditarConcreto, ListadoConcreto } from "./common/components/Concretos";
import { CrearCliente, EditarCliente, ListadoClientes } from "./common/components/Clientes";
import { CrearProveedor, EditarProveedor, ListadoProveedores, DetalleProveedor } from "./common/components/Proveedores";
import { CrearPresupuesto, EditarPresupuesto, ListadoPresupuestos, DetallePresupuesto } from "./common/components/Presupuestos";
import {
    ListadoProyectos,
    DetalleProyecto,
    EditarProyecto,
    Fases,
    OrdenCompra,
    BajaInventario,
    DetalleOrden,
    CrearFase,
    VersusFase,
    EditarFase,
    DetalleFase
} from "./common/components/Proyectos";

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                {/* ALQUILER */}
                <ProtectedRoute exact path="/alquileres" component={ListadoAlquileres} />
                <ProtectedRoute exact path="/alquileres/crear" component={CrearAlquiler} />
                <ProtectedRoute exact path="/alquileres/:id/editar" component={EditarAlquiler} />
                {/* BITACORA*/}
                <ProtectedRoute exact path="/bitacora" component={ListadoBitacora} />
                <ProtectedRoute exact path="/bitacora/:id" component={DetalleBitacora} />
                {/* CLIENTES */}
                <ProtectedRoute exact path="/clientes" component={ListadoClientes} />
                <ProtectedRoute exact path="/clientes/crear" component={CrearCliente} />
                <ProtectedRoute exact path="/clientes/:id/editar" component={EditarCliente} />
                {/* PRESUPUESTOS */}
                <ProtectedRoute exact path="/presupuestos" component={ListadoPresupuestos} />
                <ProtectedRoute exact path="/presupuestos/crear" component={CrearPresupuesto} />
                <ProtectedRoute exact path="/presupuestos/:id" component={DetallePresupuesto} />
                <ProtectedRoute exact path="/presupuestos/:id/editar" component={EditarPresupuesto} />
                {/* PROYECTOS */}
                <ProtectedRoute exact path="/proyectos" component={ListadoProyectos} />
                <ProtectedRoute exact path="/proyectos/:id" component={Fases} />
                <ProtectedRoute exact path="/proyectos/:id/baja" component={BajaInventario} />
                <ProtectedRoute exact path="/proyectos/:id/fases/crear" component={CrearFase} />
                <ProtectedRoute exact path="/proyectos/:id/fases/:fase" component={DetalleFase} />
                <ProtectedRoute exact path="/proyectos/:id/fases/:fase/editar" component={EditarFase} />
                <ProtectedRoute exact path="/proyectos/:id/fases/:fase/versus" component={VersusFase} />
                <ProtectedRoute exact path="/proyectos/:id/fases/:fase/orden" component={OrdenCompra} />
                <ProtectedRoute exact path="/proyectos/:id/fases/:fase/orden/:orden" component={DetalleOrden} />
                <ProtectedRoute exact path="/proyectos/:id/editar" component={EditarProyecto} />
                <ProtectedRoute exact path="/proyectos/:id/detallar" component={DetalleProyecto} />
                {/* USUARIOS */}
                <ProtectedRoute exact path="/perfil" component={Perfil} />
                <ProtectedRoute exact path="/usuarios" component={ListadoUsuarios} />
                <ProtectedRoute exact path="/usuarios/crear" component={CrearUsuario} />
                <ProtectedRoute exact path="/usuarios/:id/editar" component={EditarUsuario} />
                {/* MATERIALES */}
                <ProtectedRoute exact path="/materiales" component={ListadoMateriales} />
                <ProtectedRoute exact path="/materiales/crear" component={CrearMaterial} />
                <ProtectedRoute exact path="/materiales/:id/editar" component={EditarMaterial} />
                {/* MAQUINARIA */}
                <ProtectedRoute exact path="/maquinaria" component={ListadoMaquinaria} />
                <ProtectedRoute exact path="/maquinaria/crear" component={CrearMaquinaria} />
                <ProtectedRoute exact path="/maquinaria/:id" component={DetalleMaquinaria} />
                <ProtectedRoute exact path="/maquinaria/:id/editar" component={EditarMaquinaria} />
                {/* BARRAS */}
                <ProtectedRoute exact path="/barras" component={ListadoBarras} />
                <ProtectedRoute exact path="/barras/crear" component={CrearBarra} />
                <ProtectedRoute exact path="/barras/:id/editar" component={EditarBarra} />
                {/* PLANTILLAS */}
                <ProtectedRoute exact path="/plantillas" component={ListadoPlantillas} />
                <ProtectedRoute exact path="/plantillas/crear" component={CrearPlantilla} />
                <ProtectedRoute exact path="/plantillas/crear_especial" component={CrearPlantillaEspecial} />
                <ProtectedRoute exact path="/plantillas/:id/editar" component={EditarPlantilla} />
                <ProtectedRoute exact path="/plantillas/especial/:id/editar" component={EditarPlantillaEspecial} />
                {/* CONCRETOS */}
                <ProtectedRoute exact path="/concretos" component={ListadoConcreto} />
                <ProtectedRoute exact path="/concretos/crear" component={CrearConcreto} />
                <ProtectedRoute exact path="/concretos/:id/editar" component={EditarConcreto} />
                {/* PROVEEDORES */}
                <ProtectedRoute exact path="/proveedores" component={ListadoProveedores} />
                <ProtectedRoute exact path="/proveedores/crear" component={CrearProveedor} />
                <ProtectedRoute exact path="/proveedores/:id" component={DetalleProveedor} />
                <ProtectedRoute exact path="/proveedores/:id/editar" component={EditarProveedor} />
                {/* MAQUINARIA */}
                <ProtectedRoute exact path="/vehiculo" component={ListadoVehiculos} />
                <ProtectedRoute exact path="/vehiculo/crear" component={CrearVehiculo} />
                <ProtectedRoute exact path="/vehiculo/:id" component={DetalleVehiculo} />
                <ProtectedRoute exact path="/vehiculo/:id/editar" component={EditarVehiculo} />
                {/* DASHBOARD */}
                <ProtectedRoute path="/dashboard" component={Home} />
                <Route path="*" component={Login} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
