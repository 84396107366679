import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { icons } from "fotos";
import { renderField } from '../Utils/renderField';

const LoginForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form
            id="login-form"
            name="loginForm"
            className="d-flex flex-column flex-md-row justify-content-between form-validate mb-lg position-relative"
            onSubmit={handleSubmit}
        >
            <img src={icons.formaLogin} alt="" id="forma" style={{zIndex: 0}} />
            <div className="d-flex flex-column" style={{zIndex: 1}}>
                <div className="form-group has-feedback">
                    <label htmlFor="username">Usuario</label>
                    <Field name="username" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback">
                    <label htmlFor="password">Constraseña</label>
                    <Field name="password" component={renderField} type="password" className="form-control" />
                </div>
            </div>
            <button type="submit" className="btn btn-secondary m-1 align-self-center">Ingresar</button>
        </form>
    );
};

export default reduxForm({
    form: 'login',
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
