import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/proyectos/proyectos';
import VerFase from './VerFase';


const ms2p = (state) => {
    return {
        ...state.proyectos,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VerFase);
export { default as EditarFase } from "./Editar";
