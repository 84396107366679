import { connect } from 'react-redux';
import { actions, editarForm } from '../../../../redux/modules/proveedores/proveedores';
import DetalleProveedor from './DetalleProveedor';


const ms2p = (state) => {
    return {
        ...state.proveedores,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleProveedor);
