import { createAction, handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api, updateExpiracion, NEXT_PATH } from "../../utility/api";

const INITIAL = 'INITIAL';
const LOGIN_LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------

export const setMe = me => ({
    type: ME,
    me,
});

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch({ type: LOGIN_LOADER, loader: true });
    updateExpiracion(true);
    api.post('token', data).then((response) => {
        sessionStorage.setItem('token', response.token);
        if (NEXT_PATH) {
            dispatch(push(NEXT_PATH));
        } else {
            dispatch(push("dashboard"));
        }
    }).catch(() => {
        NotificationManager.error('Credenciales incorrectas, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch({ type: LOGIN_LOADER, loader: false });
    });
};

export const getMe = () => (dispatch) => {
    api.get('usuarios/me').then((response) => {
        dispatch(setMe(response));
    }).catch(() => {
    }).finally(() => {});
};

export const logOut = () => () => {
    sessionStorage.removeItem('token');
};

export const initialLoad = createAction(INITIAL);


export const actions = {
    onSubmit,
    initialLoad,
    logOut,
    getMe,
};

export const reducers = {
    [INITIAL]: (state) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            return {
                ...state,
                redirect: true,
            };
        }
        return {
            ...state,
            redirect: false,
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {
        perfil: {},
    },
};

export default handleActions(reducers, initialState);
