import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda, RenderDateTime } from "Utils/renderField/renderTableField";
import { RenderDate } from "Utils/renderField/renderReadField";
import { detalleOrdenCompra } from "./DetalleOrdenCompra";
import { StandardActions } from "Utils/TableActions";
import { Link } from "react-router-dom";
import { SE } from "utility/variables";


class Ordenes extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    render() {
        const { item, match, eliminar, me } = this.props;
        const esSe = me.perfil.rol === SE;
        const { fases } = item;
        const fase = _.find(fases, { id: parseInt(match.params.fase) });
        let ordenes = [];
        if (!!fase)
            ordenes = fase.ordenes;
        const options = _.cloneDeep(tableOptions);

        return (
            <div className="d-flex flex-column">
                {!esSe && (<div className="d-flex align-self-end align-items-center mr-2 mb-1 mt-1">
                    <div className="azul paragraph-2 mr-4">
                        Seleccione para agregar!
                    </div>
                    <Link className="btn btn-default paragraph" to={`/proyectos/${match.params.id}/fases/${match.params.fase}/orden`}>Nueva Orden</Link>
                </div>)}
                {/* TODO cambiar a listado de ordenes de compra */}
                <BootstrapTable
                    data={ordenes}
                    remote
                    striped
                    bordered={false}
                    hover
                    expandableRow={() => true}
                    expandComponent={detalleOrdenCompra(fase)}
                    options={options}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        dataAlign="right"
                    >
                        No. Orden
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat={(cell) => {
                            return <RenderDate value={cell} />
                        }}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataFormat={(cell) => {
                            return <RenderMoneda monto={cell} />
                        }}
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pagado"
                        dataFormat={(cell) => cell ? "Sí" : "No"}
                        dataSort
                        dataAlign="right"
                    >
                        PAGADA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="entregado"
                        dataFormat={(cell) => cell ? "Sí" : "No"}
                        dataSort
                        dataAlign="right"
                    >
                        ENTREGADA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        isKey
                        dataAlign="center"
                        dataFormat={(cell, row) => {
                            return StandardActions({ ver: `${match.params.fase}/orden`, eliminar })(cell,row);
                        }}
                    />
                </BootstrapTable>
            </div>
        );
    }
}

export default Ordenes;
