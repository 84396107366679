const logoBlanco = require("assets/img/logo login.png");
const logo = require("assets/img/logo.png");
const dashboard = require("assets/img/dashboard.png");
const backLogin = require("assets/img/back login.png");
const formaLogin = require("assets/img/Forma login.png");
const logoMenu = require("assets/img/logo menu.png");
const zapata = require("assets/img/zapata.png");
const viga = require("assets/img/viga.png");
const columna = require("assets/img/columna.png");
const cimiento_corrido = require("assets/img/cimiento_corrido.png");
const menuActivo = require("assets/img/iconos/Activo menu.png");
const menuExpanded = require("assets/img/iconos/Activo menu open.png");
const menuInactivo = require("assets/img/iconos/Inactivo menu.png");
const submenuActivo = require("assets/img/iconos/Activo submenu.png");
const submenuInactivo = require("assets/img/iconos/Inactivo submenu.png");
const agregar = require("assets/img/iconos/agregar activo.png");
const buscar = require("assets/img/iconos/buscar.png");
const detalle = require("assets/img/iconos/acciones/detalle.png");
const editar = require("assets/img/iconos/acciones/editar.png");
const eliminar = require("assets/img/iconos/acciones/eliminar.png");
const eliminar2 = require("assets/img/iconos/eliminar.png");


export const icons = {
    logoBlanco,
    logo,
    dashboard,
    backLogin,
    formaLogin,
    logoMenu,
    menuActivo,
    menuExpanded,
    menuInactivo,
    submenuActivo,
    submenuInactivo,
    agregar,
    buscar,
    detalle,
    editar,
    eliminar,
    eliminar2,
    zapata,
    viga,
    columna,
    cimiento_corrido,
};
