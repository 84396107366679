import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Integracion from "./Integracion";
import Ganancia from "./Ganancia";
import { Link } from "react-router-dom";
import { SE } from "utility/variables";


class DetallePresupuesto extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {tab: "1"};
    }

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    editar = () => {
        const { editar } = this.props;
        const { match } = this.props;
        editar(match.params.id);
    };

    tabChange = (tab) => {
        this.setState({ tab });
    };

    render() {
        const { loader, item, match, descargar, me } = this.props;
        const esSe = me.perfil.rol === SE;
        const { tab } = this.state;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="d-flex flex-column flex-sm-row">
                    <Link to="/presupuestos" className="btn btn-secondary paragraph ml-0 ml-md-auto mb-2" >Regresar</Link>
                    <button onClick={() => descargar(match.params.id)} className="btn btn-default paragraph ml-0 ml-md-3 mb-2">Descargar Excel</button>
                </div>
                <div className="cuadro">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                        <div className="header-1 azul mb-2 mr-2">PRESUPUESTO</div>
                        <div className="paragraph gris mb-2">{item.nombre}</div>
                    </div>
                    <Tabs
                        activeKey={tab}
                        onChange={this.tabChange}
                        renderTabBar={()=><ScrollableInkTabBar />}
                        renderTabContent={()=><TabContent />}
                    >
                        <TabPane tab="DETALLADO" key="1" >
                            <LoadMask loading={loader} light>
                                {esSe ? (<Ganancia {...this.props} />) : (<Integracion {...this.props} />)}
                            </LoadMask>
                        </TabPane>
                        {!esSe && (<TabPane tab="INTEGRACIÓN" key="2">
                            <LoadMask loading={loader} light>
                                <Ganancia {...this.props} />
                            </LoadMask>
                        </TabPane>)}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DetallePresupuesto;
