import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import { RenderDateTime } from "Utils/renderField/renderTableField";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { StandardActions } from "Utils/TableActions";
import Select, { Async } from 'react-select';
import { acciones_bitacora } from "utility/variables";
import { getUsuarios } from "../../../../utility/variables";
import moment from "moment";
import DatePicker from "Utils/DatePicker";


class Bitacora extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        accion: PropTypes.object,
        fecha_inicial: PropTypes.string.isRequired,
        fecha_final: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getBitacoras();
    }

    render() {
        const { loading, data, fecha_inicial, fecha_final } = this.props;
        const options = _.cloneDeep(tableOptions);

        options.noDataText = loading ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.getBitacoras;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-3">
                            <div style={{flex: "1"}}>
                                <h3><b>Bitácora</b></h3>
                            </div>
                            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start" style={{flex: "3"}}>
                                <div style={{flex: 1}}>
                                    <label>Fecha Inicio</label>
                                    <DatePicker
                                        value={fecha_inicial}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            this.props.setFecha('Inicial', date);
                                        }}
                                    />
                                </div>
                                &nbsp;
                                &nbsp;
                                <div style={{flex: 1}}>
                                    <label>Fecha Fin</label>
                                    <DatePicker
                                        value={fecha_final}
                                        minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                                        onChange={(date) => {
                                            this.props.setFecha('Final', date);
                                        }}
                                    />
                                </div>
                                &nbsp;
                                &nbsp;
                                <div style={{flex: 1}}>
                                    <label>Usuario</label>
                                    <Async
                                        className="react-select-container"
                                        labelKey="nombre" valueKey="id"
                                        searchPromptText="Escriba para buscar"
                                        loadOptions={getUsuarios}
                                        placeholder={"Usuario"}
                                        onChange={(e) => { this.props.setUsuario(e); }}
                                        value={this.props.usuario}
                                    />
                                </div>
                                &nbsp;
                                &nbsp;
                                <div style={{flex: 1}}>
                                    <label>Accion</label>
                                    <Select
                                        className="react-select-container"
                                        placeholder="Seleccionar"
                                        isClearable
                                        options={acciones_bitacora}
                                        valueKey="label"
                                        labelKey="label"
                                        value={this.props.accion}
                                        onChange={(e) => { this.props.setAccion(e); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoadMask loading={loading} light>
                            <BootstrapTable
                                data={loading ? [] : data.results}
                                remote
                                bordered={false}
                                pagination
                                hover
                                striped
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="creado"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell) => {
                                        return (<RenderDateTime fecha={cell} />)
                                    }}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="usuario"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell) => {
                                        return cell.username;
                                    }}
                                >
                                    Usuario
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="accion"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                >
                                    Acción
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='id'
                                    isKey
                                    dataAlign="center"
                                    dataFormat={
                                        (cell, row) => {
                                            return StandardActions({ ver: '/bitacora' })(cell, row)
                                        }
                                    }
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bitacora;
