import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from "Utils/LoadMask";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Resumen from "../../../../Proyectos/EncabezadoProyecto";
import {MATERIAL} from "utility/variables";
import { tableOptions } from "Utils/tableOptions";
import { RenderNumero, RenderMoneda } from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { Pagos } from "../Pagos";
import { Entregas }from "../Entregas";


class DetalleOrden extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {elegidos: [], tab: "1"};
    }


    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    registrarPago = (data) => {
        const { registrarPago, match } = this.props;
        registrarPago(data, match.params.orden, match.params.id);
    };

    registrarEntrega = (data) => {
        const { registrarEntrega, match } = this.props;
        registrarEntrega(data, match.params.orden, match.params.id);
    };

    render() {
        const { tab } = this.state;
        const { loader, item, match, iniciarEntrega, me } = this.props;
        const { fases } = item;
        const fase = _.find(fases, { id: parseInt(match.params.fase) }) ? _.find(fases, { id: parseInt(match.params.fase) }) : {ordenes: []};
        const orden = _.find(fase.ordenes, {id: parseInt(match.params.orden)}) ? _.find(fase.ordenes, {id: parseInt(match.params.orden)}) : {detalle: []};
        orden.detalle.forEach((detalle) => {
            detalle.cantidad = parseFloat(detalle.cantidad);
            detalle.costo = parseFloat(detalle.costo);
            detalle.total = parseFloat(detalle.total);
        });
        const numero = fases.indexOf(fase);

        const options = _.cloneDeep(tableOptions);

        return (
            <div id="DetalleOrden" className="p-2 p-md-4 d-flex flex-column">
                <LoadMask loading={loader} light>
                    <div className="header-1 azul">Fase {numero + 1} <span className="paragraph gris">DETALLE</span></div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <Resumen item={item} />
                        <div className="d-flex justify-content-end flex-row pr-3 pl-3 mb-3">
                            <Link to={`/proyectos/${match.params.id}/fases/${match.params.fase}`} className="btn btn-primary ml-1 mr-1">Regresar</Link>
                        </div>
                    </div>
                    <div className="cuadro">
                        <div className="form-head small-font">ORDEN DE COMPRA {orden.id}</div>
                        <div className="d-flex flex-column flex-md-row mt-2 justify-content-between">
                             <div className="paragraph gris">
                                 {orden.descripcion}
                             </div>
                            <div className="d-flex small-font gris ml-3 justify-content-end">TOTAL&nbsp;<RenderCurrency className={"small-font azul"} value={orden.total} /></div>
                        </div>
                        <Tabs
                            activeKey={tab}
                            onChange={(tab) => this.setState({tab})}
                            renderTabBar={()=><ScrollableInkTabBar />}
                            renderTabContent={()=><TabContent />}
                        >
                            <TabPane tab="Detalle" key="1" >
                                <BootstrapTable
                                    data={orden.detalle}
                                    striped
                                    bordered={false}
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="tipo"
                                        dataSort
                                        dataFormat={(cell, row) => {
                                            if (cell === MATERIAL)
                                                return `${row.nombre_material} ${row.unidad_material}`;
                                            return row.gasto;
                                        }}
                                    >
                                        Material
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="unidad_material"
                                        dataAlign="right"
                                    >
                                        Unidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cantidad"
                                        dataFormat={(cell) => <RenderNumero value={cell} decimalScale={4} />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Cantidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="costo"
                                        dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Costo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total"
                                        dataFormat={(cell) => <RenderMoneda monto={cell}  />}
                                        dataSort
                                        dataAlign="right"
                                    >
                                        Sub Total
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataSort
                                        isKey
                                        width={"0%"}
                                    />
                                </BootstrapTable>
                            </TabPane>
                            <TabPane tab="Pagos" key="2">
                                <Pagos me={me} orden={orden} loader={loader} registrarPago={this.registrarPago} />
                            </TabPane>
                            <TabPane tab="Entregas" key="3">
                                <Entregas me={me} orden={orden} loader={loader} registrarEntrega={this.registrarEntrega} iniciarEntrega={iniciarEntrega} />
                            </TabPane>
                        </Tabs>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default DetalleOrden;
