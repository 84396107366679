import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import LoadMask from "Utils/LoadMask";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import { Link } from "react-router-dom";
import Encabezado from "../../EncabezadoProyecto";
import { RenderCurrency, RenderDate } from "../../../Utils/renderField/renderReadField";
import { Versus } from "../Versus";
import Renglones from "./Renglones";
import Ordenes from "./Ordenes";
import { Chart } from "react-google-charts";
import { SE } from "utility/variables";


class DetalleFase extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {elegidos: [], tab: "1"};
    }


    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    render() {
        const { tab } = this.state;
        const { loader, item, match, finalizarFase, me } = this.props;
        const { fases } = item;
        const esSe = me.perfil.rol === SE;
        const fase = _.find(fases, { id: parseInt(match.params.fase) }) ? _.find(fases, { id: parseInt(match.params.fase) }) : {};
        let cantRenglones = 0;
        if (!!fase.detalle)
            fase.detalle.forEach((detalle) => cantRenglones += parseFloat(detalle.cantidad));
        const numero = fases.indexOf(fase);

        return (
            <div id="DetalleFase" className="p-2 p-md-4 d-flex flex-column">
                <LoadMask loading={loader} light>
                    <div className="d-flex flex-column flex-md-row align-items-center">
                        <Encabezado item={item} />
                        <Link to={`/proyectos/${match.params.id}`} className="btn btn-secondary mr-0 ml-auto my-2 mt-md-0">Regresar</Link>
                    </div>
                    <div className="cuadro">
                        <div className="d-flex flex-row align-items-center">
                            <div className="header-1 azul">Fase {numero + 1} <span className="paragraph gris">DETALLE</span></div>
                            {!esSe && (<div className="btn btn-secondary ml-auto paragraph-2" onClick={() => {finalizarFase(match.params.id, match.params.fase)}}>Marcar finalización de fase</div>)}
                        </div>
                        <div className="border mb-3 fondo_fase">
                            <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
                                <div className="flex-2 ml-3 mr-3 pl-3 pr-3">
                                    <div className="flex-1" style={{minWidth: "200px"}}>
                                        <Chart
                                            chartType="BarChart"
                                            height={'120px'}
                                            data={[
                                                ['','', { role: 'tooltip'}, {role: 'style'}],
                                                [
                                                    '',
                                                    100,
                                                    '100%',
                                                    '#0092B4',
                                                ],
                                                [
                                                    '',
                                                    (parseFloat(fase.total) / parseFloat(fase.total_ganancia)) * 100,
                                                    `${Math.round((parseFloat(fase.total) / parseFloat(fase.total_ganancia)) * 100)}%`,
                                                    '#085F84',
                                                ],
                                                [
                                                    '',
                                                    (parseFloat(fase.ejecutado) / parseFloat(fase.total_ganancia)) * 100,
                                                    `${Math.round((parseFloat(fase.ejecutado) / parseFloat(fase.total_ganancia)) * 100)}%`,
                                                    parseFloat(fase.total) < parseFloat(fase.ejecutado) ? '#AB413A' : '#00658A',
                                                ],
                                            ]}
                                            options={{
                                                legend: { position: 'none' },
                                                bar: { groupWidth: '80%' },
                                                orientation: 'vertical',
                                                hAxis: {maxValue: 100, minValue: 0},
                                                vAxis: {maxValue: 100, minValue: 0},
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex-3 d-flex flex-column m-3 p-3 bg-white">
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 m-1">
                                            <div className="azul">{cantRenglones === 1 ? `${cantRenglones} Renglón` : `${cantRenglones} Renglones`}</div>
                                            <div className="paragraph-2 gris">INICIO <span className="azul"><RenderDate value={fase.fecha_inicio} /></span></div>
                                        </div>
                                        <div className="d-flex flex-column flex-1 m-1">
                                            <div className="paragraph gris text-center">TOTAL PRESUPUESTO</div>
                                            <div className="paragraph-bold azul text-right">
                                                <RenderCurrency value={fase.total}/>
                                            </div>
                                        </div>
                                        <div className="flex-1" />
                                    </div>
                                    <div className="d-flex flex-column flex-lg-row">
                                        <div className="d-flex flex-row flex-2">
                                            <div className="d-flex flex-column flex-1 m-1">
                                                <div className="paragraph gris ">TOTAL CON GANANACIA</div>
                                                <div className="paragraph-bold azul text-right">
                                                    <RenderCurrency value={fase.total_ganancia}/>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-1 m-1">
                                                <div className="paragraph gris text-center">TOTAL EJECUTADO</div>
                                                <div className="paragraph-bold celeste text-right">
                                                    <RenderCurrency value={fase.ejecutado ? fase.ejecutado : 0}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column flex-1 m-1">
                                            <div className="paragraph gris text-center">BALANCE</div>
                                            <div className="paragraph-bold celeste text-right">
                                                <RenderCurrency value={fase.total - (fase.ejecutado ? fase.ejecutado : 0)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Tabs
                            activeKey={tab}
                            onChange={(tab) => this.setState({tab})}
                            renderTabBar={()=><ScrollableInkTabBar />}
                            renderTabContent={()=><TabContent />}
                        >
                            <TabPane tab="Renglones" key="1" >
                                <div className="d-flex flex-column">
                                    {!esSe && (<Link className="btn btn-primary paragraph ml-auto my-2 mr-2" to={`/proyectos/${match.params.id}/fases/${match.params.fase}/editar`}>Editar</Link>)}
                                    <Renglones {...this.props} />
                                </div>
                            </TabPane>
                            <TabPane tab="Ordenes de compra" key="2">
                                <Ordenes {...this.props} />
                            </TabPane>
                            <TabPane tab="Balance de Fase" key="3">
                                <Versus {...this.props} />
                            </TabPane>
                        </Tabs>

                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default DetalleFase;
