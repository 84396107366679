import React from 'react';
import Select, { AsyncCreatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DatePicker from "Utils/DatePicker";
import FileUploader from '../FileUploader/FileUploader';


export const renderField = ({ input, disabled, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                disabled={disabled}
                placeholder={label}
                type={type}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderDatePicker = ({
                              input, minDate, maxDate, disabled, label, type, meta: { touched, error }, className,
                            }) => {
  const invalid = touched && error;
  return (
    <div>
      <DatePicker
        value={input.value}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => {
          input.onChange(date);
        }}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderTextArea = ({ input, disabled, label, rows, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                disabled={disabled}
                placeholder={label}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSelectField = ({ valueKey, clearable, searchable, placeholder, labelKey, input, disabled, options, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Select
                clearable={clearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                searchable={searchable}
                options={options}
                labelKey={labelKey}
                valueKey={valueKey}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderMultiSelectField = ({ valueKey, clearable, searchable, placeholder, labelKey, input, disabled, options, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Select
                clearable={clearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                searchable={searchable}
                multi
                options={options}
                labelKey={labelKey}
                valueKey={valueKey}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderAsync = ({ input, disabled, clearable, cache, valueKey, labelKey, placeholder, loadOptions, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                clearable={clearable}
                placeholder="Seleccionar"
                disabled={disabled}
                value={input.value}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                onChange={(e) => { input.onChange(e); }}
                cache={false}
                searchPromptText="Escribe para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderMultiAsync = ({ input, disabled, clearable, cache, valueKey, labelKey, placeholder, loadOptions, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                clearable={clearable}
                placeholder="Seleccionar"
                disabled={disabled}
                value={input.value}
                multi
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                onChange={(e) => { input.onChange(e); }}
                cache={false}
                searchPromptText="Escribe para buscar"
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderAsyncCreatable = ({ input, disabled, clearable, cache, valueKey, labelKey, placeholder, loadOptions, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <AsyncCreatable
                placeholder="Seleccionar"
                clearable={clearable}
                disabled={disabled}
                value={input.value}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                onChange={(e) => { input.onChange(e); }}
                cache={false}
                searchPromptText="Escribe para buscar"
                promptTextCreator={(label) => `Crear ${label}`}
                valueKey={valueKey}
                labelKey={labelKey}
                loadOptions={loadOptions}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderNumber = ({ input, suffix, disabled, decimalScale, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix=""
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderPercentege = ({ input, decimalScale, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix=""
                suffix="%"
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({ input, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                allowNegative={false}
                disabled={disabled}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix="Q "
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({ input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Switch
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                id="normal-switch"
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const renderImagePicker = ({photo, setFile, className, placeholder, disabled, input, label, meta: { touched, error } }) => {
  const invalid = touched && error;
  return (
    <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
      <FileUploader
        disabled={disabled}
        img= {!!photo ? photo : null}
        onFileChange={(e, file) => {
          file = file || e.target.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            input.onChange(reader.result);
            if (!!setFile) {
              setFile(file);
            }
          };

          reader.readAsDataURL(file);
        }} />
      {invalid && <div className="invalid-feedback">
        {error}
      </div>}
    </div>
  )
};

export const renderCheckBox = ({ input, disabled, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        placeholder={label}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const RenderField = {
    renderField,
    renderTextArea,
    renderPercentege,
    renderSelectField,
    renderNumber,
    renderCurrency,
    renderSwitch,
};
