import React from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderNumber, renderCurrency, renderAsync, renderSelectField } from "Utils/renderField/renderField";
import { RenderNumero } from "../../Utils/renderField/renderTableField";
import { tableOptions } from "Utils/tableOptions";
import { getConcretos, TIPOS_PLANTILLA, CIMIENTO_CORRIDO, COLUMNA, VIGA, ZAPATA } from "utility/variables";
import validate from "./validacion";
import Columna from "./Columna";
import Viga from "./Viga";
import Zapata from "./Zapata";
import CimientoCorrido from "./CimientoCorrido";
import { renderMateriales } from "../Form";
import { icons } from "utility/fotos";


export const Form = (props) => {
    const {
        handleSubmit,
        titulo,
        concreto,
        volumen,
        tipo_especial,
        crearMaterial,
        toggleAgregar,
        vistaAgregar
    } = props;

    const options = _.cloneDeep(tableOptions);
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="form-container" style={{marginBottom: "0"}}>
                <div className="form-head small-font">{titulo}</div>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="nombre">Nombre*</label>
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="tipo_especial">Tipo*</label>
                            <Field
                                name="tipo_especial"
                                component={renderSelectField}
                                options={TIPOS_PLANTILLA}
                                labelKey="label"
                                valueKey="value"
                                clearable={false}
                                placeholder="Seleccionar"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-around">
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4">
                            <label htmlFor="mano_obra">Mano de obra por unidad</label>
                            <Field name="mano_obra" component={renderCurrency} className="form-control" />
                        </div>
                        <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-4" />
                    </div>
                </div>
            </div>
            <div className="form-container" style={{marginTop: "0", marginBottom: "0"}}>
                <div className="d-flex flex-column justify-content-center form-content">
                    <div className="d-flex flex-column-reverse flex-lg-row justify-content-around">
                        <div className="d-flex flex-column flex-2 justify-content-end">
                            <div className="small-font azul pl-2 pr-2 pl-md-4 pr-md-4">DIMENSIÓN</div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="ancho">Ancho*</label>
                                    <Field name="ancho" component={renderNumber} suffix="m" decimalScale={3} className="form-control" />
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-3 pr-md-3">
                                    <label htmlFor="largo">Largo*</label>
                                    <Field name="largo" component={renderNumber} suffix="m" decimalScale={3} className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="alto">Alto*</label>
                                    <Field name="alto" component={renderNumber} suffix="m" decimalScale={3} className="form-control" />
                                </div>
                                <div className="d-flex flex-column form-group m-1 flex-1 pl-2 pr-2 pl-md-4 pr-md-3">
                                    <label htmlFor="concreto">Resistencia del concreto*</label>
                                    <Field
                                        name="concreto"
                                        loadOptions={getConcretos}
                                        component={renderAsync}
                                        labelKey={"nombre"}
                                        valueKey={"id"}
                                        clearable={false}
                                        placeholder="Seleccionar"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 align-self-center">
                            {tipo_especial === COLUMNA && (<img src={ icons.columna } alt=""/>)}
                            {tipo_especial === VIGA && (<img src={ icons.viga } alt=""/>)}
                            {tipo_especial === CIMIENTO_CORRIDO && (<img src={ icons.cimiento_corrido } alt=""/>)}
                        </div>
                    </div>
                    <div className="small-font azul py-3 pl-2 pr-2 pl-md-4 pr-md-4 mb-1">Volumen&nbsp;<span className="gris">{volumen} m<sup>3</sup></span></div>
                </div>
                {(!!concreto) && (
                    <React.Fragment>

                        <div className="small-font azul pl-2 pr-2 pl-md-4 pr-md-4">MATERIAL PARA EL CONCRETO</div>
                        <div className="pl-2 pr-2 pl-md-4 pr-md-4">
                            <BootstrapTable
                                data={concreto.detalle}
                                striped
                                bordered={false}
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="material"
                                    isKey
                                    dataSort
                                    dataFormat={(cell) => {
                                        return (cell.nombre)
                                    }}
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return <RenderNumero value={cell * volumen } decimalScale={4} />
                                    }}
                                >
                                    Cantidad
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="material"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return cell.unidad;
                                    }}
                                >
                                    Unidad de medida
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <div className="form-container">
                <FieldArray
                    name="detalle"
                    component={renderMateriales}
                    crearMaterial={crearMaterial}
                    toggleAgregar={toggleAgregar}
                    vistaAgregar={vistaAgregar}
                />
            </div>
            {(tipo_especial === COLUMNA) && (<Columna {...props} />)}
            {(tipo_especial === VIGA) && (<Viga {...props} />)}
            {(tipo_especial === ZAPATA) && (<Zapata {...props} />)}
            {(tipo_especial === CIMIENTO_CORRIDO) && (<CimientoCorrido {...props} />)}
            <div className="d-flex justify-content-start flex-row-reverse mt-3 pr-3 pl-3">
                <button type="submit" className="btn btn-primary ml-1 align-self-center">Guardar</button>
                <Link to="/plantillas" className="btn btn-secondary ml-1 mr-1 align-self-center">Cancelar</Link>
            </div>
        </form>
    );
};


let EditarForm = reduxForm({
    // a unique name for the form
    form: 'EditarPlantillaEspecialForm',
    validate,
})(Form);

const selector = formValueSelector('EditarPlantillaEspecialForm');

EditarForm = connect(
    state => {
        const concreto = selector(state, "concreto");
        const ancho = selector(state, "ancho");
        const alto = selector(state, "alto");
        const largo = selector(state, "largo");
        const long_gancho = selector(state, "long_gancho");
        const recubrimiento = selector(state, "recubrimiento");
        const tipo_especial = selector(state, "tipo_especial");
        const sep_conf = selector(state, "sep_conf");
        const sep_nconf = selector(state, "sep_nconf");
        const barras_a = selector(state, "barras_a");
        const barras_b = selector(state, "barras_b");
        const camas = selector(state, "camas");
        const separacion_a = selector(state, "separacion_a");
        const separacion_b = selector(state, "separacion_b");
        const cant_barras = selector(state, "cant_barras");
        // bastones
        const bastones_sup = selector(state, "bastones_sup");
        const long_baston_sup = selector(state, "long_baston_sup");
        const no_barras_bast_sup = selector(state, "no_barras_bast_sup");
        const bastones_inf = selector(state, "bastones_inf");
        const long_baston_inf = selector(state, "long_baston_inf");
        const no_barras_bast_inf = selector(state, "no_barras_bast_inf");
        const no_barras = selector(state, "no_barras");
        const no_barras_estribos = selector(state, "no_barras_estribos");
        const espacio_conf = selector(state, "espacio_conf");
        const longitudes = selector(state, "longitudes");
        let volumen = 0;
        try {
            volumen = ancho * alto * largo;
            if (volumen !== volumen)
                volumen = 0;
            volumen = parseFloat(volumen.toFixed(2));
        } catch (e) { }
        return {
            concreto,
            volumen,
            tipo_especial,
            alto,
            largo,
            ancho,
            longitudes: longitudes ? longitudes : [],
            no_barras: no_barras ? no_barras : [],
            no_barras_estribos: no_barras_estribos ? no_barras_estribos : {},
            // bastones
            bastones_sup,
            long_baston_sup,
            no_barras_bast_sup: no_barras_bast_sup ? no_barras_bast_sup : {},
            bastones_inf,
            long_baston_inf,
            no_barras_bast_inf: no_barras_bast_inf ? no_barras_bast_inf : {},
            long_gancho,
            recubrimiento,
            barras_a,
            barras_b,
            camas: camas ? camas : 0,
            separacion_a,
            separacion_b,
            cant_barras,
            espacio_conf,
            sep_conf,
            sep_nconf,
        };
    }
)(EditarForm);

export default EditarForm;
