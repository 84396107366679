import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import BajaForm from "./BajaForm";
import EncabezadoProyecto from "../../../EncabezadoProyecto";


class CrearPlantilla extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { leerInventario, match } = this.props;
        leerInventario(match.params.id);
    }

    crear = (data) => {
        const {baja, match} = this.props;
        baja(match.params.id, data);
    };

    render() {
        const { loader, match, item } = this.props;

        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <EncabezadoProyecto item={item} />
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <BajaForm onSubmit={this.crear} proyecto={match.params.id} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CrearPlantilla;
