import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask";
import EditarForm from "./EditarForm";


class EditarUsuario extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = ({verPass: false});
    }

    cambiarPass = () => {
        this.setState({verPass: true});
    };


    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    editar = (data) => {
        const { editar } = this.props;
        const { match } = this.props;
        editar(match.params.id, data);
    };

    render() {
        const { loader} = this.props;
        const { verPass } = this.state;
        return (
            <div id="" className="p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <EditarForm onSubmit={this.editar} cambiarPass={this.cambiarPass} verPass={verPass} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default EditarUsuario;
