import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/proyectos/proyectos';
import { inicializarNuevoRenglon } from "../../../../redux/modules/presupuestos/presupuestos";
import EditarPresupuesto from '../../Presupuestos/Editar/EditarPresupuesto';


const ms2p = (state) => {
    return {
        ...state.proyectos,
    };
};

const md2p = { ...actions, inicializarNuevoRenglon };

export default connect(ms2p, md2p)(EditarPresupuesto);
