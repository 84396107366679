import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import { RenderCurrency, RenderDate } from "../../Utils/renderField/renderReadField";
import "./fases.css";
import { SE } from "utility/variables";


class Fases extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        const { leer, match } = this.props;
        leer(match.params.id);
    }

    render() {
        const { item, match, me } = this.props;
        const { fases } = item;
        let fase;
        if (fases.length)
            fase = fases[fases.length - 1];
        if (fase) {
            if (!fase.ejecutado) {
                fase.ejecutado = 0;
            }
        }

        return (
            <React.Fragment>
                <div className="cuadro">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column flex-md-row justify-content-around mt-3 flex-wrap">
                            <div className="d-flex flex-column p-0 p-md-2">
                                <div className="form-head-solid">PRESUPUESTO</div>
                                <div className="d-flex flex-row border">
                                    <div className="flex-1" style={{minWidth: "200px"}}>
                                        <Chart
                                            chartType="BarChart"
                                            height={'120px'}
                                            data={[
                                                ['','', { role: 'tooltip'}, {role: 'style'}],
                                                [
                                                    '',
                                                    100,
                                                    '100%',
                                                    '#0092B4',
                                                ],
                                                [
                                                    '',
                                                    (parseFloat(item.total) / parseFloat(item.total_ganancia)) * 100,
                                                    `${Math.round((parseFloat(item.total) / parseFloat(item.total_ganancia)) * 100)}%`,
                                                    '#085F84',
                                                ],
                                                [
                                                    '',
                                                    (parseFloat(item.ejecutado) / parseFloat(item.total_ganancia)) * 100,
                                                    `${Math.round((parseFloat(item.ejecutado) / parseFloat(item.total_ganancia)) * 100)}%`,
                                                    parseFloat(item.total) < parseFloat(item.ejecutado) ? '#AB413A' : '#00658A',
                                                ],
                                            ]}
                                            options={{
                                                legend: { position: 'none' },
                                                bar: { groupWidth: '80%' },
                                                hAxis: {maxValue: 100},
                                            }}
                                        />
                                    </div>
                                    <div className="flex-1 d-flex flex-column p-3">
                                        <div className="paragraph gris text-right">SOBRE GANANCIA</div>
                                        <div className="paragraph-bold azul text-right">
                                            <RenderCurrency value={item.total_ganancia}/>
                                        </div>
                                        <div className="paragraph gris text-right mt-1">TOTAL PRESUPUESTO</div>
                                        <div className="paragraph-bold azul text-right">
                                            <RenderCurrency value={item.total}/>
                                        </div>
                                        <div className="paragraph gris text-right mt-1">TOTAL EJECUTADO</div>
                                        <div className={`paragraph-bold ${parseFloat(item.total) < parseFloat(item.ejecutado) ? "rojo" : "azul"} text-right`}>
                                            <RenderCurrency value={item.ejecutado}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!!fase && (<div className="d-flex flex-column p-0 p-md-2">
                                <div className="form-head d-flex flex-row align-items-center">
                                    FASE {fases.length}
                                    <span className="ml-auto paragraph-2">{fase.finalizado ? "FINALIZADO" : "EN EJECUCIÓN"}</span>
                                    <Link to={`/proyectos/${match.params.id}/fases/${fase.id}`} className="btn btn-light ml-3 paragraph-2 azul">Ver</Link>
                                </div>
                                <div className="d-flex flex-row border">
                                    <div className="flex-1" style={{minWidth: "200px"}}>
                                        <Chart
                                            chartType="ColumnChart"
                                            height={'120px'}
                                            data={[
                                                ['','', { role: 'tooltip'}, {role: 'style'}],
                                                [
                                                    '',
                                                    100,
                                                    '100%',
                                                    '#0092B4',
                                                ],
                                                [
                                                    '',
                                                    (parseFloat(fase.total) / parseFloat(fase.total_ganancia)) * 100,
                                                    `${Math.round((parseFloat(fase.total) / parseFloat(fase.total_ganancia)) * 100)}%`,
                                                    '#085F84',
                                                ],
                                                [
                                                    '',
                                                    (parseFloat(fase.ejecutado) / parseFloat(fase.total_ganancia)) * 100,
                                                    `${Math.round((parseFloat(fase.ejecutado) / parseFloat(fase.total_ganancia)) * 100)}%`,
                                                    parseFloat(fase.total) < parseFloat(fase.ejecutado) ? '#AB413A' : '#00658A',
                                                ],
                                            ]}
                                            options={{
                                                legend: { position: 'none' },
                                                hAxis: {maxValue: 100},
                                                vAxis: {maxValue: 100},
                                            }}
                                        />
                                    </div>
                                    <div className="flex-1 d-flex flex-column p-3">
                                        <div className="paragraph gris text-right">SOBRE GANANCIA</div>
                                        <div className="paragraph-bold azul text-right">
                                            <RenderCurrency value={fase.total_ganancia}/>
                                        </div>
                                        <div className="paragraph gris text-right mt-1">TOTAL PRESUPUESTO</div>
                                        <div className="paragraph-bold azul text-right">
                                            <RenderCurrency value={fase.total}/>
                                        </div>
                                        <div className="paragraph gris text-right mt-1">TOTAL EJECUTADO</div>
                                        <div className={`paragraph-bold ${parseFloat(fase.total) < parseFloat(fase.ejecutado) ? "rojo" : "celeste"} text-right`}>
                                            <RenderCurrency value={fase.ejecutado ? fase.ejecutado : 0}/>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className="fondo-fases">
                    <div className="d-flex flex-column p-2 p-md-4">
                        {me.perfil.rol !== SE && (<div className="d-flex flex-row justify-content-end align-items-center w-100">
                            <div className="azul paragraph-2 mr-4">
                                Seleccione para agregar!
                            </div>
                            <Link className="btn btn-primary" to={`/proyectos/${match.params.id}/fases/crear`}>Nueva Fase</Link>
                        </div>)}
                        <div className="d-flex flex-wrap flex-column flex-md-row justify-content-around">
                            {item.fases.map((fase, index) => {
                                let cantRenglones = 0;
                                fase.detalle.forEach((detalle) => cantRenglones += parseFloat(detalle.cantidad));
                                if (!fase.ejecutado) {
                                    fase.ejecutado = 0;
                                }
                                return (
                                    <div className="d-flex flex-column m-2" key={fase.id}>
                                        <div className="form-head-solid d-flex align-items-center border">
                                            FASE {index + 1}&nbsp;&nbsp;
                                            <span className="ml-auto paragraph-2">{fase.finalizado ? "Finalizado" : "En ejecución"}</span>
                                        </div>
                                        <div className="border bg-white">
                                            <div className="d-flex align-items-end p-2">
                                                <div className="d-flex flex-column flex-2 align-items-center">
                                                    <div className="azul">{cantRenglones === 1 ? `${cantRenglones} Renglón` : `${cantRenglones} Renglones`}</div>
                                                    <div className="paragraph-2 gris">INICIO <span className="azul"><RenderDate value={fase.fecha_inicio} /></span></div>
                                                </div>
                                                <div className="d-flex flex-1 align-items-end justify-content-center">
                                                    <Link to={`/proyectos/${match.params.id}/fases/${fase.id}`} className="btn btn-primary">Ver</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-1" style={{minWidth: "165px"}}>
                                                    <Chart
                                                        chartType="ColumnChart"
                                                        height={'120px'}
                                                        data={[
                                                            ['','', { role: 'tooltip'}, {role: 'style'}],
                                                            [
                                                                '',
                                                                100,
                                                                '100%',
                                                                '#0092B4',
                                                            ],
                                                            [
                                                                '',
                                                                (parseFloat(fase.total) / parseFloat(fase.total_ganancia)) * 100,
                                                                `${Math.round((parseFloat(fase.total) / parseFloat(fase.total_ganancia)) * 100)}%`,
                                                                '#085F84',
                                                            ],
                                                            [
                                                                '',
                                                                (parseFloat(fase.ejecutado) / parseFloat(fase.total_ganancia)) * 100,
                                                                `${Math.round((parseFloat(fase.ejecutado) / parseFloat(fase.total_ganancia)) * 100)}%`,
                                                                parseFloat(fase.total) < parseFloat(fase.ejecutado) ? '#AB413A' : '#00658A',
                                                            ],
                                                        ]}
                                                        orientation='vertical'
                                                        options={{
                                                            legend: { position: 'none' },
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex-1 d-flex flex-column p-3">
                                                    <div className="paragraph gris text-right">SOBRE GANANCIA</div>
                                                    <div className="paragraph-bold azul text-right">
                                                        <RenderCurrency value={fase.total_ganancia}/>
                                                    </div>
                                                    <div className="paragraph gris text-right mt-1">TOTAL PRESUPUESTO</div>
                                                    <div className="paragraph-bold azul text-right">
                                                        <RenderCurrency value={fase.total}/>
                                                    </div>
                                                    <div className="paragraph gris text-right mt-1">TOTAL EJECUTADO</div>
                                                    <div className={`paragraph-bold ${parseFloat(fase.total) < parseFloat(fase.ejecutado) ? "rojo" : "celeste"} text-right`}>
                                                        <RenderCurrency value={fase.ejecutado ? fase.ejecutado : 0}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Fases;
