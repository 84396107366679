import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { getResumenes, getMateriales } from "utility/variables";
import { Async } from "react-select";
import { icons } from "fotos";
import "./renglones.css";


class Renglones extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { index, disabled, renglon, cambioDetalle, quitarDetalle, agregarDetalle, precioUnitario } = this.props;
        let total = precioUnitario;
        if (renglon.mano_obra)
            total += parseFloat(renglon.mano_obra);

        return (

            <table className="d-none d-lg-block">
                <tbody>
                <tr>
                    <th className="pl-4 pt-1 pb-1 paragraph bg-celeste azul" style={{width: "24%"}}>MATERIAL</th>
                    <th className="pl-4 pt-1 pb-1 paragraph bg-celeste azul" style={{width: "16%"}}>CANTIDAD</th>
                    <th className="pl-4 pt-1 pb-1 paragraph bg-celeste azul" style={{width: "16%"}}>% IMPREVISTO</th>
                    <th className="pt-1 pb-1 paragraph bg-celeste azul text-center" style={{width: "16%"}}>UNIDAD</th>
                    <th className="pl-4 pt-1 pb-1 paragraph bg-celeste azul text-right" style={{width: "16%"}}>PRECIO UNITARIO</th>
                    <th className="pl-4 pt-1 pb-1 paragraph bg-celeste azul" style={{width: "12%"}} />
                </tr>
                {renglon.detalle.map((detalle, sub_index) => {
                    return (<tr key={detalle.id} className={`renglon-${sub_index%2} ${detalle.es_herramienta ? "herramienta" : ""}`}>
                        <td className="pl-1 pr-1 pb-1">
                            <Async
                                clearable={false}
                                disabled={disabled}
                                value={detalle.material}
                                className={`react-select-container ${detalle.es_herramienta ? "herramienta" : ""}`}
                                onChange={(e) => { cambioDetalle(index, sub_index, 'material', e) }}
                                cache={false}
                                searchPromptText="Escribe para buscar"
                                valueKey="id"
                                labelKey="nombre"
                                loadOptions={getMateriales}
                            />
                        </td>
                        <td className="pl-1 pr-1 ">
                            <NumberFormat
                                disabled={disabled}
                                className={`form-control ${detalle.es_herramienta ? "herramienta" : ""}`}
                                decimalScale={4}
                                fixedDecimalScale
                                value={detalle.cantidad}
                                thousandSeparator
                                prefix=""
                                onValueChange={(values) => { cambioDetalle(index, sub_index, 'cantidad', values.value) }}
                            />
                        </td>
                        <td className="pl-1 pr-1 ">
                            <NumberFormat
                                disabled={disabled}
                                className={`form-control ${detalle.es_herramienta ? "herramienta" : ""}`}
                                decimalScale={2}
                                fixedDecimalScale
                                value={detalle.porcentaje_imprevisto}
                                thousandSeparator
                                prefix="%"
                                onValueChange={(values) => { cambioDetalle(index, sub_index, 'porcentaje_imprevisto', values.value) }}
                            />
                        </td>
                        <td className="pl-1 pr-1 paragraph text-center">
                            {detalle.material.unidad}
                        </td>
                        <td className="pl-1 pr-1 paragraph text-right">
                            <RenderCurrency value={detalle.costo} />
                        </td>
                        <td className="paragraph text-center">
                            <img onClick={() => {quitarDetalle(index, sub_index)}} className="eliminar" src={icons.eliminar2} alt=""/>
                        </td>
                    </tr>)
                })}
                <tr className={`renglon-${(renglon.detalle.length)%2}`}>
                    <td className="pl-1 pr-1 pb-1">
                        {!disabled && (<Async
                            clearable={false}
                            className="react-select-container"
                            onChange={(e) => { agregarDetalle(index, e) }}
                            cache={false}
                            placeholder="Agregar Material"
                            searchPromptText="Escribe para buscar"
                            valueKey="id"
                            labelKey="nombre"
                            loadOptions={getMateriales}
                        />)}
                    </td>
                    <td colSpan={6} />
                </tr>
                <tr className={`renglon-${(renglon.detalle.length + 1)%2}`}>
                    <td className="pl-1 pr-1 pb-1">
                        {!disabled && (<Async
                            clearable={false}
                            className="react-select-container"
                            onChange={(e) => { agregarDetalle(index, e, true) }}
                            cache={false}
                            placeholder="Herramienta/Vehiculo"
                            searchPromptText="Escribe para buscar"
                            valueKey="id"
                            labelKey="nombre"
                            loadOptions={getMateriales}
                        />)}
                    </td>
                    <td colSpan={6} />
                </tr>
                <tr className={`renglon-${(renglon.detalle.length)%2}`}>
                    <td colSpan={7} className="p-2" />
                </tr>
                <tr className={`renglon-${(renglon.detalle.length + 1)%2}`}>
                    <td colSpan={4} />
                    <td>
                        <div className="paragraph-bold gris text-right">TOTAL MATERIALES</div>
                    </td>
                    <td>
                        <RenderCurrency value={precioUnitario ? precioUnitario : 0} className={"azul"} />
                        {/*<RenderCurrency value={renglon.costo_subtotal} className={"azul"} />*/}
                    </td>
                    <td />
                </tr>
                <tr className={`renglon-${(renglon.detalle.length)%2}`}>
                    <td colSpan={4} />
                    <td>
                        <div className="paragraph-bold gris text-right">TOTAL COSTO RENGLÓN</div>
                    </td>
                    <td>
                        <RenderCurrency value={total ? total : 0} className={"azul"} />
                        {/*<RenderCurrency value={renglon.costo_total} className={"azul"} />*/}
                    </td>
                    <td />
                </tr>
                </tbody>
            </table>
        );
    }
}

export default Renglones;
