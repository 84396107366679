import { handleActions } from 'redux-actions';
import uuidv1 from "uuid/v1";
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import _ from "lodash";
import { api, updateExpiracion } from "api";
import { NotificationManager } from "react-notifications";
import { UNIDAD_MINIMA } from "../../../utility/variables";


// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'BARRAS_LOADER';
const DATA = 'BARRAS_DATA';
const ITEM = 'BARRAS_ITEM';
const PAGE = 'BARRAS_PAGE';
const ORDERING = 'BARRAS_ORDERING';
const SEARCH = 'BARRAS_SEARCH';
const UUID = 'BARRAS_UUID';

export const constants = {
};

const endpoint = "barras";
const crearForm = "CrearBarraForm";
const editarForm = "EditarBarraForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});

export const setUuid = uuid => ({
    type: UUID,
    uuid,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    const { barras } = getStore();
    const params = { page };
    params.ordering = barras.ordering;
    params.search = barras.search;
    dispatch(setLoader(true));
    const uuid = uuidv1();
    dispatch(setUuid(uuid));
    api.get(endpoint, params).then((response) => {
        const realUuid = getStore().barras.uuid;
        if (uuid === realUuid) {
            dispatch(setData(response));
            dispatch(setPage(page));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        const body = _.cloneDeep(response);
        body.unidad_minima = _.find(UNIDAD_MINIMA, { label: body.unidad_minima }).value;
        dispatch(setItem(body));
        dispatch(initializeForm(editarForm, body));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push("/barras"));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push("/barras"));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().barras.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    sortChange,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        updateExpiracion();
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        updateExpiracion();
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        updateExpiracion();
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        updateExpiracion();
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        updateExpiracion();
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        updateExpiracion();
        return {
            ...state,
            search,
        };
    },
    [UUID]: (state, { uuid }) => {
        updateExpiracion();
        return {
            ...state,
            uuid,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    uuid: '',
};

export default handleActions(reducers, initialState);
